@import "../vars/colors";
@import "../vars/assets";

.fr-invitation {
  position: relative;
  left: 0;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  width: 101vw;
}

.fr-invitation-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: calc(100vh - 80px);
  border-radius: 3px;
  padding-bottom: 5px;
  background: center;
  background-size: cover;
  @media screen and (max-width: 690px) {
    height: calc(100vh - 50px);
    > :nth-child(1) {
      align-self: flex-end;
    }
    > :nth-child(3) {
      min-height: calc(100% / 3)
    }
  }
  &__item {
    flex: 1 1 320px;
    margin: 10px 15px 10px 15px;
    box-sizing: border-box;
    padding: 5px;
    text-align: center;
    font-size: 18px;
    align-self: flex-start;
    color: $frz-white-color;
    &-title {
      margin-top: 15px;
      font-size: 18px;
      font-weight: 200;
      .note {
        padding-top: 5px;
      }
      &.right {
        float: right;
        padding-right: 30px;
        @media screen and (max-width: 690px) {
          padding-right: 0;
          float: none;
        }
      }
      &.left {
        float: left;
        padding-left: 30px;
        @media screen and (max-width: 690px) {
          padding-left: 0;
          float: none;
        }
      }
      @media screen and (max-width: 690px) {
        margin-top: 15px;
        font-size: 18px;
        font-weight: 200;
      }
    }
  }
  &__item-wide {
    flex: 1 1 calc(100% - 60px);
    box-sizing: border-box;
    min-width: 260px;
    padding-top: 5px;
    text-align: center;
    font-size: 18px;
    align-self: flex-end;
    color: $frz-white-color;
    &-title {
      margin-bottom: 40px;
      font-size: 48px;
      font-weight: 200;
      @media screen and (max-width: 690px) {
        margin-bottom: 15px;
        //font-size: 28px;
      }
    }
  }
}
