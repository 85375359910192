@import "vars/_colors";
@import "vars/_assets";
@import "vars/_mixins";

// ##PLACEHOLDERS
// ##GENERAL STYLES

// ##FORM ELEMENTS
// ##CARDS
// ##MAIN PAGE TABS
// ##PROFILE TABS
// ##PROMO
//--
// ##HEADER
// ##BANNER
// ##NAVIGATION TABS

//--
// ##SERVICES
// ##OVERRIDE
// ##MISC
// ##FILTER
//##ERROR MESSAGE

// offsets
$offset: 15px; //default offset

// ##PLACEHOLDERS
%clr {
  clear: both;
  content: '';
  display: block;
}

// ##GENERAL STYLES
html {
  height: 100%;
  overflow-x: hidden;
  @media screen and (min-width: 992px) {
    margin-left: calc(99vw - 100%);
    margin-right: 0;
  }
}

body {
  #react-app {
    min-height: calc(100% - 280px);
  }
  background: $frz-white-color;
  color: $basic-gray-color;
  font-family: $font-main;
  font-size: $main-font-size;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 24px;
}

.container {
  position: relative;
}

form label {
  font-weight: normal;
  font-size: 30px;
  @media screen and (max-width: 641px) {
    font-size: 18px;
  }
}

*:not(input) {
  -ms-user-select: none;
}

.overflow-hide {
  overflow: hidden;
  max-height: 100%;
}

.frizo-app {
  overflow-x: hidden;
}

.fr-filter-panel {
  .col-md-12 {
    min-height: 0;
  }
  .btn-group {
    input {
      &:checked {
        + label {
          background: $frz-semi-light-gray-color;
          color: $frz-white-color;
          font-weight: bold;
        }
      }
    }
    > div {
      padding: 0;
      overflow: hidden;
      label {
        border-radius: 0;
        border: 1px solid $basic-gray-color;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        border-left-width: 0;
      }
      &:first-of-type {
        label {
          border-left-width: 1px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
      &:last-of-type {
        label {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
    input {
      + label {
        display: block;
        padding: 6px 12px;
        margin: 0;
        background: $frz-white-color;
        text-align: center;
        font-weight: normal;
        &:before {
          display: none !important;
          font-size: 0;
        }
      }
    }
  }
  label {
    vertical-align: middle;
  }
  .col-md-12.services {
    > div {
      width: auto;
      transition: all 0.5s;
      max-height: 60px;
      &.hidden, &[data-attr-visible="hide"] {
        overflow: hidden;
        width: 0;
        max-height: 0;
        transition: all 0.5s;
        display: block !important;
        label {
          height: 0;
        }
      }
    }
  }
}

input[type=checkbox], input[type=radio] {
  margin-right: 4px;
  + label {
    margin-right: 10px;
  }
}

input::-ms-clear {
  display: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

[placeholder]:focus::-webkit-input-placeholder {
  transition: text-indent 0.5s 0.5s ease;
  text-indent: -100%;
}

[placeholder]:focus::-moz-placeholder {
  transition: text-indent 0.5s 0.5s ease;
  text-indent: -100%;
}

[placeholder]:focus:-ms-input-placeholder {
  transition: text-indent 0.5s 0.5s ease;
  text-indent: -100%;
}

.fr-header {
  padding-top: $offset;
  padding-right: 0;
  &:after {
    @extend %clr;
  }
  &-logo {
    background: $fp-inverse-logo no-repeat top left;
    background-size: 188px 50px;
    float: left;
    height: 50px;
    width: 188px;
    margin-right: 45px;
  }
  &-wrap {
    background-color: $frz-white-color;
    //border-bottom: 4px solid $frz-blue-color; /* todo: create border class in sass!! */
  }
  .navbar {
    margin-bottom: 15px;
  }
  .nav .open > a {
    background-color: $frz-white-color;
  }
}

@media only screen and (max-width: 991px) {
  .fr-header {
    padding-right: $offset;
  }
  .clients-menu {
    animation: fadeInOpacity 0.5s linear 1;
    -webkit-animation: fadeInOpacity 0.5s linear 1;
    -moz-animation: fadeInOpacity 0.5s linear 1;
  }
}

.glyphicon {
  &.glyphicon-user {
    margin-right: 5px;
  }
  &.glyphicon-option-horizontal {
    top: 4px;
  }
  &.glyphicon-check {
    color: $frz-green-color;
  }
}

.fr-login {
  background: $frz-light-gray-color;
  display: inline;
  float: left;
  width: 300px;
}

#footer {
  clear: both;
  position: relative;
  bottom: 0;
  width: 100%;
}

.fr-footer {
  background-color: $basic-gray-color;
  border-top: 4px solid $frz-blue-color; /* todo: create border class in sass!! */
  padding: 20px;
  text-align: center;
  font-size: 18px;
  &:after {
    @extend %clr;
  }
  &-wrap {
    background-color: $basic-gray-color;
    border-bottom: 4px solid $frz-blue-color; /* todo: create border class in sass!! */
  }
  &-copyright {
    background-color: $frz-white-color;
    padding: 22px 15px 0 15px;
  }
  &-version {
    background-color: $frz-white-color;
    padding: 22px 15px 15px 15px;
    color: $frz-semi-light-gray-color;
    text-align: right;
  }
  &-text {
    display: block;
    padding: 10px 0;
  }
  &-faq {
    display: block;
    padding: 10px 0;
    float: right;
  }
  &-contact-us {
    padding: 8px 0 8px 0;
    text-align: center;
  }
  .fr-lang-flag {
    float: left;
    height: 27px;
    width: 40px;
    margin-top: 7px;
  }
  .fr-lang-en {
    background: $flag-en no-repeat left top;
    background-size: 51px 27px;
  }
  .fr-lang-es {
    background: $flag-es no-repeat left top;
    background-size: 51px 27px;
  }
  .fr-footer-store {
    padding-right: 0;
    text-align: right;
  }

  @media only screen and (max-width: 991px) {
    .fr-social {
      display: inline-block;
    }
    .fr-footer-store {
      text-align: center;
    }
    .fr-footer-faq {
      float: none;
    }
    .fr-footer-center-flag {
      display: inline-block;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 321px) {
    .fr-footer-store {
      padding-left: 0;
    }
    .fr-iosicon, .fr-google-icon {
      margin: 0;
    }
  }
  a {
    color: $frz-white-color;
    //margin: auto 0;
    outline: none;
    &:hover {
      cursor: pointer;
    }
  }
  ul.fr-social {
    margin: 0;
    min-width: 200px;
    padding: 0;
    /* For IE, the outcast */
    zoom: 1;
    *display: inline;
    li {
      display: inline;
      a {
        float: left;
        margin-right: 10px;
        @include size(40px);
      }
      &.fr-fb a {
        background: transparent $fb-inactive-icon no-repeat;
        background-size: 40px 40px;
        &:hover {
          background: transparent $fb-icon no-repeat;
          background-size: 40px 40px;
        }
      }
      &.fr-ig a {
        background: transparent $instagram-inactive-icon no-repeat;
        background-size: 40px 40px;
        &:hover {
          background: transparent $instagram-icon no-repeat;
          background-size: 40px 40px;
        }
      }
      &.fr-pt a {
        background: transparent $pinterest-inactive-icon no-repeat;
        background-size: 40px 40px;
        &:hover {
          background: transparent $pinterest-icon no-repeat;
          background-size: 40px 40px;
        }
      }
      &.fr-yt a {
        background: transparent $youtube-inactive-icon no-repeat;
        background-size: 40px 40px;
        &:hover {
          background: transparent $youtube-icon no-repeat;
          background-size: 40px 40px;
        }
      }
    }
  }
  &-array {
    color: $frz-lightest-gray-color;
    cursor: none;
    text-decoration: none;
    &:hover, &:focus, &:active {
      color: $frz-lightest-gray-color;
      cursor: none;
      text-decoration: none;
    }
  }
}

.fr-iosicon {
  display: inline-block;
  float: none;
  background: $appstore no-repeat top left;
  background-size: 120px 40px;
  height: 40px;
  width: 120px;
  &.large {
    margin-right: 15px;
    background-size: 180px 60px;
    height: 60px;
    width: 180px;
    @media screen and (max-width: 480px) {
      margin-right: 0;
    }
  }
}

.fr-google-icon {
  display: inline-block;
  float: none;
  margin-left: 7px;
  margin-right: 10px;
  background: $google-play-icon no-repeat top left;
  background-size: 135px 40px;
  height: 40px;
  width: 135px;
  &.large {
    margin-left: 15px;
    background-size: 203px 60px;
    height: 60px;
    width: 203px;
    @media screen and (max-width: 769px) {
      margin-left: 0;
      margin-right: 4px;
    }
  }
}

@media only screen and (max-width: 321px) {
  .fr-iosicon, .fr-google-icon {
    margin: 0;
  }
}

@media (max-width: 991px) {
  .fr-footer-copyright, .fr-footer-version, .fr-footer-array {
    text-align: center;
  }
}

// ##HEADER

.fr-header {
  font-size: 18px;
  .dropdown-menu > li > a {
    padding: 0;
    margin: 3px 20px;
  }
  .nav {
    li {
      > a {
        padding-right: 0;
        &:focus, &:hover {
          color: $basic-gray-color;
        }
      }
    }
  }
  .fr-navbar {
    background: none;
    border: none;
    text-transform: uppercase;
    a {
      color: $semi-dark-color;
    }
    .navbar-nav {
      vertical-align: middle;
    }
  }
  // override for colors
  .navbar-nav .dropdown-menu > li > a,
  .navbar-inverse .navbar-nav > li > a,
  .navbar-inverse .navbar-text {
    //color: $frz-light-gray-color;
  }
  .navbar-inverse .navbar-toggle,
  .navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
    border-color: transparent;
  }
  .navbar-inverse .navbar-collapse {
    box-shadow: none;
  }

  // override for dropdown menu in navbar
  @media only screen and (max-width: 991px) {
    .dropdown-menu {
      display: block;
      background-color: transparent;
      border: 0 none;
      box-shadow: none;
      margin-top: 0;
      position: static;
      width: 100%;
    }
    .navbar-nav .dropdown-menu .dropdown-header {
      padding: 10px 15px 10px 15px;
    }

    .navbar-nav .dropdown-menu > li {
      margin: 15px 15px 15px 0;
      height: 25px;
      padding: 0;
    }
    .navbar-nav .dropdown-menu > li > a {
      margin-left: 0;
    }
    .navbar-default .navbar-nav .dropdown-menu > li > a {
      color: $logo-primary-color;
    }
  }
  .dropdown-menu,
  .navbar-nav .dropdown-menu > li > a,
  .navbar-inverse .navbar-nav > .open > a,
  .navbar-inverse .navbar-nav > .open > a:focus {
    background-color: $frz-white-color;
    font-size: 18px;
  }
  .navbar-nav .dropdown-menu > li > a:hover {
    color: $basic-gray-color;
  }
  .fr-navbar-dropdown-toggle {
    padding-top: 0;
  }
  .fr-li-hidden {
    visibility: hidden;
    margin: 0;
    height: 0;
  }
  .fr-li-visible {
    visibility: visible;
    margin: 15px 0 15px 15px;
  }
  .nav li {
    margin: 15px 0 0 15px;
    @media (min-width: 991px) {
      float: left;
    }
    > a {
      padding: 0;
      text-decoration: none;
    }
    > a:hover {
      text-decoration: underline;
    }
    a:focus, a:hover {
      outline: none;
      background-color: $frz-white-color;
      border: none;
    }
    &.dropdown-beauty {
      margin-top: 12px;
    }
  }
  .fr-discount {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 500;
    color: $frz-green-color;
    text-decoration: none;
    &:hover {
      color: $frz-green-over-color;
    }
  }
}

.navbar-right {
  margin-right: 0;
  .caret {
    margin-left: 5px;
  }
  .badge {
    margin-left: 5px;
  }
  .header-menu__dropdown {
    margin-left: -19px;
    left: 0;
    right: auto;
    > li {
      margin: 7px 0 0 7px;
      > a:hover, a:focus {
        text-decoration: underline;
      }
    }
    > li:first-child {
      margin-top: 7px;
    }
    > li:last-child {
      margin-bottom: 7px;
    }
  }
}

@media screen and (max-width: 991px) {
  .navbar-right {
    .header-menu__dropdown {
      margin: -15px 0 15px 0 !important;
      > li > a {
        color: $semi-dark-color;
      }
    }
  }
}

// - HEADER end

// ##BANNER
.fr-banner-main, .fr-banner-main--header, .fr-banner-main--landing {
  text-align: center;
  padding-bottom: 9px;
  color: $basic-gray-color;
  h1 {
    font-size: 48px;
    font-weight: 200;
    @media screen and (max-width: 1024px) {
      font-size: 40px;
    }
    @media screen and (max-width: 769px) {
      font-size: 32px;
      margin: 10px 0 0 0;
    }
  }
  &.pricing {
    margin-bottom: 10px;
  }
}

.fr-banner-main--header {
  color: $frz-white-color;
}

.fr-banner-main--landing {
  margin-top: 0;
  h1 {
    font-weight: 200;
  }
  a {
    font-size: 24px;
  }
  @media screen and (max-width: 769px) {
    margin-top: -5px;
  }
}

.fr-banner-top {
  background-color: $frz-white-color;
  color: $basic-gray-color;
  padding: 10px 0 10px 0;
  h1, .fr-link-h1 {
    display: inline-block;
    font-size: 48px;
    font-weight: 200;
    margin: 0;
    @media screen and (max-width: 641px) {
      margin-top: 2px;
      font-size: 28px;
      span {
        margin-bottom: 4px;
      }
    }
  }
  h2 {
    display: inline;
    margin: 0;
    text-transform: uppercase;
  }
  .fr-link-h1 {
    margin-top: 0;
    color: $blue-emphasis-color;
    text-decoration: none;
    @media screen and (max-width: 769px) {
      margin-top: -4px;
    }
    &:focus, &:hover {
      cursor: pointer;
    }
  }
  .fr-link-h1[data-hover='long'] {
    &:focus, &:hover {
      position: relative;
      cursor: pointer;
      &:after {
        position: absolute;
        content: '';
        left: 0;
        bottom: 11px;
        height: 2px;
        background: $blue-emphasis-color;
        right: 35px;
      }
    }
    @media screen and (max-width: 769px) {
      &:focus, &:hover {
        position: relative;
        cursor: pointer;
        &:after {
          position: absolute;
          content: '';
          left: 0;
          bottom: 6px;
          height: 2px;
          background: $blue-emphasis-color;
          right: 25px;
        }
      }
    }
  }
  .fr-link-h1[data-hover='short'] {
    &:focus, &:hover {
      position: relative;
      cursor: pointer;
      &:after {
        position: absolute;
        content: '';
        left: 0;
        bottom: 11px;
        height: 2px;
        background: $blue-emphasis-color;
        right: 10px;
      }
    }
    @media screen and (max-width: 769px) {
      &:focus, &:hover {
        position: relative;
        cursor: pointer;
        &:after {
          position: absolute;
          content: '';
          left: 0;
          bottom: 6px;
          height: 2px;
          background: $blue-emphasis-color;
          right: 5px;
        }
      }
    }
  }

  .fr-text-h1 {
    margin-top: 9px;
    margin-bottom: 17px;
    padding-right: 0;
    padding-left: 3px;
    @media screen and (max-width: 769px) {
      margin-top: 0;
      margin-bottom: 0;
      padding-bottom: 10px;
      padding-left: 16px;
    }
  }

  .fr-text-h1__big {
    margin-top: 9px;
    margin-bottom: 17px;
    padding-right: 0;
    padding-left: 4px;
    @media screen and (max-width: 769px) {
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 16px;
    }
    @media screen and (max-width: 480px) {
      padding-left: 11px;
    }
  }

  .fr-profile-complete {
    background-color: $frz-green-color;
    text-align: center;
  }

  .fr-banner__button {
    margin: 0;
    padding: 0 12px;
    border-radius: 4px;
    background-color: $frz-white-color;
    color: $frz-blue-color;
    font-size: 50px;
    font-weight: normal;
    @media screen and (max-width: 641px) {
      margin-top: 6px;
      font-size: 28px;
    }
  }

  &__text {
    margin-top: 0;
    margin-bottom: 17px;
  }

  &__text--clients, &__text--apt {
    margin-top: 10px;
    margin-bottom: 19px;
    padding-left: 0;
    h1 {
      margin-top: 0;
    }
    @media screen and (max-width: 769px) {
      padding-left: 14px;
      padding-right: 27px;
    }
    @media screen and (max-width: 641px) {
      margin-bottom: 10px;
      margin-left: 5px;
    }
  }

  &__text--content {
    margin-top: 10px;
    margin-bottom: 19px;
    padding-right: 17px;
    @media screen and (max-width: 769px) {
      margin-top: -5px;
      margin-bottom: 8px;
      padding-right: 0;
    }
    h1 {
      font-size: 48px;
      font-weight: 200;
      @media screen and (max-width: 769px) {
        font-size: 32px;
      }
    }
  }

  &__text--legal {
    margin-top: 0;
    margin-bottom: 0;
    @media screen and (max-width: 769px) {
      font-size: 32px;
    }
  }

  &__text--salonName, &__text--left {
    margin: 10px 0 19px 0;
    padding-left: 3px;
    @media screen and (max-width: 991px) {
      margin-top: -5px;
      margin-bottom: 8px;
      padding-left: 16px;
    }
  }

  &__margin--mobile {
    @media screen and (max-width: 769px) {
      margin-top: 5px;
    }
  }
  &__artistName {
    margin: 0 0 0px -10px;
    @media screen and (max-width: 769px) {
      margin-top: 6px;
      margin-bottom: 0;
      padding-left: 15px;
      font-size: 18px;
      font-weight: 400;
    }
    &--title {
      display: inline-block;
      margin: 0;
      font-size: 48px;
      font-weight: 200;
      color: inherit; //$frz-white-color;
      @media screen and (max-width: 769px) {
        font-size: 24px;
        font-weight: 400;
        color: inherit; //$frz-black-color;
      }
      &.xs-left {
        @media screen and (max-width: 769px) {
          margin-left: -10px;
        }
      }
    }
  }
}

.fr-profile {
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
  font-size: 18px;
  color: $frz-white-color;
  &__incomplete {
    background-color: $frz-orange-color;
  }
  &__fully-incomplete {
    background-color: $frz-red-color;
  }
  &__text {
    font-size: 16px;
    font-weight: 500;
    margin: 5px 0;
    @media screen and (max-width: 769px) {
      padding: 5px 5px 0 5px;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 769px) {
    padding-top: 0;
  }
}

.fr-full-width {
  width: 101vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin: -5px -50vw 15px -50vw;
  padding-bottom: 0;
  color: $frz-white-color;
  &__book {
    padding-bottom: 2px;
  }
  @media screen and (max-width: 769px) {
    margin-top: 0;
  }
  &.artist-promo {
    margin-top: 0;
    margin-bottom: 0;
    padding: 8px 0 9px 0;
    color: inherit;
    @media screen and (max-width: 769px) {
      padding: 4px 0 3px 0;
    }
  }
}

.fr-container-width {
  width: 1170px;
  margin: auto;
  @media screen and (max-width: 1170px) {
    width: 98%;
    margin: auto;
  }
}

.fr-banner-bottom {
  visibility: hidden;
  clear: both;
  position: fixed;
  z-index: 122;
  bottom: 0;
  left: 1px;
  width: calc(100% - 2px);
  height: 70px;
  padding: 15px;
  text-align: center;
  background-color: $basic-gray-color;
  animation: fadeInUp 1s linear 1;
  -webkit-animation: fadeInUp 1s linear 1;
  -moz-animation: fadeInUp 1s linear 1;
  .fr-iosicon {
    margin-right: 7px;
  }
  .fr-google-icon {
    margin-right: 0;
  }
  &__text {
    padding: 10px;
    font-size: 18px;
    font-weight: 400;
    color: $frz-white-color;
  }
  &__close {
    position: absolute;
    top: 5px;
    right: 10px;
    background-color: transparent;
    cursor: pointer;
    display: inline-block;
    .btn {
      color: $frz-semi-light-gray-color;
      text-shadow: none;
      opacity: 1;
      &:hover, &:focus, &:active {
        background-color: $basic-gray-color;
        color: $frz-gray-color;
        border: none;
      }
    }
  }
  @media screen and (max-width: 768px) {
    visibility: visible;
    height: 102px;
    padding: 5px;
    font-size: 16px;
    &__close {

    }
  }
}

.fr-informer-bottom {
  position: fixed;
  bottom: 15px;
  left: 0;
  z-index: 1982;
  height: 50px;
  padding: 15px;
  overflow: visible;
  text-align: center;
  color: $frz-white-color;
  background-color: $frz-blue-color;
  animation: fadeInUp 1s linear 1;
  -webkit-animation: fadeInUp 1s linear 1;
  -moz-animation: fadeInUp 1s linear 1;
}

.informer-title {
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 0;
  margin-bottom: 5px;
}

@media only screen and (max-width: 991px) {
  .fr-mt-xs {
    margin-top: $offset;
  }
  .fr-mb-xs {
    margin-bottom: $offset;
  }
  .fr-fr-xs {
    float: right;
  }
  .fr-mb4-xs {
    margin-bottom: 4 * $offset;
  }
  .fr-xs-center {
    float: none;
    text-align: center;
  }
}

@media screen and (min-width: 768px) {
  .fr-xs-break {
    display: none;
  }
}

// ##NAVIGATION TABS
.nav-tabs {
  > li {
    margin-bottom: 2px;
    > a {
      color: $basic-gray-color;
      border-color: $frz-semi-light-gray-color;
      border-radius: 0;
      //background-color: $frz-semi-light-gray-color + 5;
      margin-top: 2px;
      padding-top: 8px;
      padding-bottom: 7px;
      font-family: $font-main;
      font-size: 18px;
      font-weight: 500;
      &:hover {
        border-color: transparent;
        //background-color: $frz-blue-color;
        border-bottom: 4px solid $frz-semi-light-gray-color;
        background-color: $frz-white-color;
        padding-bottom: 7px;
        outline: none;
        > .fr-span-fully-incomplete {
          margin-top: -1px;
          margin-left: 7px;
          float: right;
          height: 25px;
          width: 25px;
          background: $badge-red no-repeat top left;
          background-size: 25px 25px;
        }
        > .fr-span-incomplete {
          margin-top: -1px;
          margin-left: 7px;
          float: right;
          height: 25px;
          width: 25px;
          background: $badge-orange no-repeat top left;
          background-size: 25px 25px;
        }
      }
      &:active {
        border-color: transparent;
        background-color: $frz-light-gray-color;
        color: $frz-blue-color;
      }
      @media screen and (max-width: 768px) {
        margin-top: 0;
      }
      @media screen and (max-width: 1024px) {
        font-family: $font-tabs;
        font-weight: bold;
      }
    }
  }
}

ul.nav-tabs {
  &[role="tablist"] {
    margin-bottom: 0;
    border-bottom: 0;
  }
}

.fr-navbar-tabs > li {
  margin-bottom: 0; //2px;
  > a {
    padding-bottom: 7px;
    padding-top: 8px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 0;
  }
}

.fr-navbar-tabs {
  margin-bottom: $offset;
  margin-right: 0;
  margin-left: 0;
  border-bottom-width: 1px;
  border-color: $frz-semi-light-gray-color;
}

#nav-tabs-wrapper .nav-tabs > ul {
  margin-bottom: $offset;
  margin-right: -$offset;
  border-bottom-width: 0;
  border-color: $frz-semi-light-gray-color;
}

#nav-tabs-wrapper li:first-child {
  margin-left: 0;
}

.fr-complete {
  background-color: $frz-green-color;
  color: $frz-white-color;
  margin: 0 0 0 4px;
  padding: 2px 5px;
  text-transform: lowercase;
  font-size: 18px;
  border: 1px solid $frz-white-color;
  @include rounded();
}

.fr-incomplete {
  @extend .fr-complete;
  background-color: $frz-red-color;
}

.fr-tab-complete {
  background-color: $frz-green-color;
  color: $frz-white-color;
  //margin: 5px 2px -2px 0;
  margin: -8px -15px 7px -15px;
  font-size: 15px;
  text-align: center;
  border-radius: 4px 4px 0 0;
}

.fr-tab-fully-incomplete {
  @extend .fr-tab-complete;
  background-color: $frz-red-color;
}

.fr-tab-incomplete {
  @extend .fr-tab-complete;
  background-color: $frz-orange-color;
}

.fr-tab-empty {
  margin-top: 22px !important;
  @media screen and (max-width: 769px) {
    margin-top: 0 !important;
  }
}

.fr-span-complete {
  display: none;

  @media only screen and (max-width: 769px) {
    //display: none;
    display: inline-block;
    margin-top: -1px;
    margin-left: 7px;
    float: right;
    height: 25px;
    width: 25px;
  }
}

.fr-span-fully-incomplete {
  margin-top: -1px;
  margin-left: 7px;
  float: right;
  height: 25px;
  width: 25px;
  background: $badge-red no-repeat top left;
  background-size: 25px 25px;
}

.fr-span-incomplete {
  margin-top: -1px;
  margin-left: 7px;
  float: right;
  height: 25px;
  width: 25px;
  background: $badge-orange no-repeat top left;
  background-size: 25px 25px;
}

@media screen and (max-width: 769px) {
  .fr-span-incomplete, .fr-span-fully-incomplete, .fr-span-complete {
    margin-right: 15px;
  }

}

.li-cap {
  @media only screen and (max-width: 769px) {
    position: fixed;
    margin-left: calc(100% - 138px);
  }
}

.faq-component {
  .fr-routed-tab {
    margin-top: 10px;
  }
  .fr-navbar-tabs {
    margin-bottom: 0;
  }
  .fr-tab-content {
    margin-top: 0;
  }
  .fr-faq-contact {
    margin-bottom: 10px;

    .btn-contact {
      padding: 0;
      margin-bottom: 2px;
      color: $frz-blue-color;
    }
  }
}

.panel-title > a {
  display: block;
  &:hover, &:focus {
    cursor: pointer;
  }
}

@media only screen and (max-width: 769px) {
  #nav-tabs-wrapper li:first-child {
    margin-left: 0;
  }
  .fr-tab-complete {
    display: none;
  }
  ul.nav-tabs {
    text-align: left;
  }
}

// - NAVIGATION TABS end

.fr-book__title {
  display: inline-block;
  margin-bottom: 5px;
  font-size: 32px;
  font-weight: normal;
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
  @media screen and (max-width: 480px) {
    font-size: 20px;
  }
}

.fr-book__journal-title {
  display: inline-block;
  margin: 2px 0 10px 0;
  padding-left: 4px;
  font-size: 48px;
  font-weight: 200;
  @media screen and (max-width: 769px) {
    padding-left: 14px;
  }
  @media screen and (max-width: 480px) {
    margin-left: 11px;
    font-size: 28px;
    padding-left: 0;
  }
}

.fr-book__style {
  @media screen and (max-width: 991px) {
    margin-top: 30px;
  }
}

.fr-apt-modal .modal-body {
  max-height: 200px;
}

.fr-book-appointment-notification {
  background-color: $frz-green-color;
  font-size: 18px;
  padding: $offset;
}

.fr-book-appointment-header {
  background-color: $frz-light-gray-color;
  font-size: 18px;
  padding: $offset;
}

.fr-apt-header-cancel {
  background-color: $danger-color;
  font-size: 18px;
  padding: $offset;
}

.fr-apt-table-container {
  float: left;
  width: 100%;
  margin-top: -45px;
}

.fr-apt-pay-label {
  display: inline-block;
  padding-top: 10px;
  vertical-align: middle;
}

// ##SERVICES
.fr-serv-row {
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 769px) {
    margin-bottom: 0;
  }
}

.fr-serv-duration, .fr-serv-processing, .fr-serv-price {
  text-align: right;
  padding-right: 30px;
}

.fr-serv-processing, .fr-serv-price {
  @media screen and (max-width: 769px) {
    padding-right: 10px;
  }
}

.fr-serv-duration {
  @media screen and (max-width: 769px) {
    //padding-top: 5px;
    padding-right: 15px;
    text-align: right;
  }
}

.fr-serv-price__artist {
  text-align: right;
  padding-right: 60px;
}

.fr-serv-price-edit {
  text-align: right;
  padding-right: 30px;
  ::placeholder {
    text-align: left;
    padding-left: 5px;
  }
  @media screen and (max-width: 769px) {
    padding-right: 15px;
  }
}

.fr-serv-action {
  float: right;
  @media screen and (max-width: 769px) {
    padding-right: 0;
  }
}

.fr-serv-edit-control {
  height: 30px;
  float: right;
  padding: 6px 4px;
  margin-top: 0;
  text-align: right;
}

.fr-serv-price, .fr-serv-price__artist {
  &:before {
    content: "$\00a0";
  }
}

.fr-serv-price-edit__artist {
  margin-top: 0;
  padding-right: 60px;
}

.fr-serv-btn__edit {
  margin: 0 5px;
}

.fr-serv-price-title {
  text-align: right;
  padding-right: 30px;
  /* @media screen and (max-width: 992px) {
     padding-right: 30px;
     text-align: right;
   }*/
  @media screen and (max-width: 769px) {
    padding-right: 10px;
    text-align: right;
  }
}

.fr-serv-duration-title {
  text-align: center;
  @media screen and (max-width: 769px) {
    text-align: left;
  }
}

.fr-serv-action-title {
  @media screen and (max-width: 769px) {
    padding-left: 30px;
  }
}

.fr-serv-action__more {
  float: right;
  margin-top: 10px;
}

.fr-serv-badge {
  margin-left: 7px;
}

// Services table
.fr-serv-edit, .fr-client-delete, .fr-btn-table, .fr-apt-cancel {
  margin-bottom: 2px;
}

.fr-list-header {
  background-color: $frz-light-gray-color;
  padding: 8px 2px;
  margin-bottom: 2px;
  &.bordered {
    padding: 0;
    opacity: 0.95;
    border-bottom: 3px $frz-blue-color solid;
    > div {
      padding: 8px 15px;
      border-right: 1px solid $frz-white-color;
      &:last-child {
        border-right: 1px solid $frz-light-gray-color;
      }
    }
  }
}

.row.fr-table-header {
  background-color: $frz-lightest-gray-color;
  padding: 4px 2px 4px $offset;
  text-transform: capitalize;
  margin-bottom: 2px;
}

.fr-table-content {
  background-color: $frz-white-color;
  //margin-bottom: 7px;
  word-wrap: break-word;
}

.fr-table-section {
  background-color: $frz-white-color;
  padding-top: 4px;
}

.fr-table-icon {
  font-size: 1.2em;
  padding-right: $offset;
}

.fr-serv-select {
  margin-top: 15px;
  &.form-control {
    padding-left: 0;
    padding-right: 0;
  }
}

.fr-serv-select > option {
  padding: 4px 7px;
  text-overflow: ellipsis;
  overflow: hidden;
  &:nth-child(even) {
    background-color: $frz-lightest-gray-color;
  }
}

.fr-add-serv-form {
  margin-top: 15px;
  margin-bottom: 15px;
}

.fr-add-serv-label {
  width: 40%;
  font-size: $main-font-size;
  padding-right: 15px;
}

.fr-add-serv-item {
  width: 60% !important;
}

@media (max-width: 769px) {
  .fr-add-serv-item {
    float: right;
  }
  .fr-add-serv-label {
    margin-top: 10px;
    margin-bottom: 0;
  }
}

// ##MISC
.m0 {
  margin: 0;
}

.p0 {
  padding: 0;
}

.rwd-line {
  display: inline;
  @media screen and (max-width: 769px) {
    display: block;
  }
}

.no-decoration {
  text-decoration: none !important;
}

// ##FORM ELEMENTS

// working hours table
.fr-wh-address-container {
  margin-top: 15px;
}

.fr-wh-adr-select {
  width: 100%;
}

.form-control {
  position: relative;
  z-index: 2;
  font-size: $main-font-size;
  @media screen and (max-width: 769px) {
    font-size: 16px;
  }
}

.fr-wh-time-container {
  margin: 15px 0 30px 0;
}

.fr-wh-time-container-readOnly {
  margin: 15px 0 15px 0;
}

.tooltip-inner .tooltip-arrow {
  display: none !important;
}

.fr-wh-artist__select {
  margin: 0 0 15px 15px;
  padding-right: 30px;
}

.fr-wh-span {
  padding-right: 10px;
  span {
    font-weight: normal !important;
    font-size: $main-font-size !important;
  }
}

.slider {
  width: 100% !important;
}

.tooltip {
  display: none !important;
}

.fr-wh-time-container .fr-wh-time {
  display: inline;
  width: auto;
  margin-left: $offset;
}

.fr-wh-time-container .fr-wh-day {
  display: inline;
}

.fr-wh-day {
  font-size: 20px;
  font-weight: normal;
  margin-left: -$offset;
}

.fr-wh-dayoff {
  font-size: 20px;
  font-weight: normal;
  margin-left: -$offset;
  margin-bottom: 0;
}

.fr-week-day {
  margin-bottom: $offset * 2;
}

.fr-wh-slider {
  span.ui-slider-handle.ui-corner-all.ui-state-default {
    height: 28px;
    width: 20px;
    line-height: 70px;
    text-align: center;
    margin: -5px;
    background: $frz-white-color;
    border-radius: 6px;
    box-shadow: 0 0 5px $semi-dark-color;
    outline: none;
  }
}

.fr-wh-btn-container {
  position: relative !important;
  margin: $offset;
}

textarea.form-control.fr-textarea-as-input {
  height: 140px;
  resize: vertical;
  &.affiliate {
    height: 60px;
  }
}

.hide-only-if-collapsed {
  display: block;
}

.show-only-if-collapsed {
  display: none;
}

// ##CARDS
.fr-card-container {
  margin-top: $offset;
}

.fr-card {
  margin-bottom: $offset;
  margin-left: -15px;
  margin-right: -15px;
}

.fr-card-addartist {
  text-align: center;
  padding-top: 100px;
  font-size: 40px;
}

.fr-card-info {
  position: absolute;
  bottom: $offset;
  left: 0;
  width: 100%;
  text-align: center;
}

.fr-card-info-detail {
  bottom: 5px;
  @extend .fr-card-info;
}

.fr-card-title {
  color: $frz-white-color;
  text-shadow: 0 0 4px $semi-dark-color;
  // font-weight: bold;
  padding: 0 5px $offset 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fr-card-title-main {
  @extend .fr-card-title;
  @media (max-width: 769px) {
    padding-bottom: 0;
  }
}

.fr-card-artist-title {
  color: $frz-white-color;
  text-shadow: 0 0 4px $semi-dark-color;
  font-weight: bold;
  padding: 0 5px 5px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fr-card-artist-subtitle {
  color: $frz-white-color;
  font-weight: normal;
  text-shadow: 0 0 4px $semi-dark-color;
  padding-bottom: $offset;

}

.fr-card-select {
  width: 100%;
  > option {
    padding: 4px 7px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.fr-price-range {
  position: absolute;
  top: 25px;
  left: 22px;
  font-size: 25px;
  text-shadow: 0 0 4px $semi-dark-color;
  color: $frz-white-color;
}

.fr-bold-dollars {
  font-weight: bold;
}

.fr-card-salontitle {
  color: $frz-white-color;
  text-shadow: 0 0 4px $semi-dark-color;
  font-weight: bold;
  font-size: larger;
  padding-bottom: $offset;
}

.fr-card-btn-bottom {
  margin: 0 5px $offset 5px;
}

.fr-card-btn__item {
  margin: 0 5px 0 5px;
}

.fr-card-btn__promo {
  margin-right: 0;
  margin-bottom: 10px;
}

.fr-double-photo {
  position: absolute;
  top: 7px;
  left: 7px;
  height: 34px;
  width: 40px;
  background: $double-photo no-repeat top left;
  background-size: 40px 34px;
  z-index: 100;
}

// ##PROFILE TABS
// ##-Name&Location
.fr-profile__title {
  display: inline-block;
  margin-top: 19px;
  font-size: 30px;
  margin-bottom: 5px;
  @media screen and (max-width: 641px) {
    font-size: 18px;
  }
  &-photo, &-photo-static {
    margin-top: 19px;
    margin-bottom: 5px;
    font-size: 30px;
    text-align: center;
    @media screen and (max-width: 769px) {
      text-align: left;
      margin-top: 10px;
      font-size: 18px;
    }
  }
  &-photo-static {
    text-align: left;
  }
}

.fr-tab-content {
  margin-top: 30px;
  padding-left: 0;
  padding-right: 30px;
  width: auto;
  @media screen and (max-width: 768px) {
    padding-right: 0;
  }
}

.fr-address-edit-form {
  margin-bottom: 30px;
  float: left;
  background-color: $frz-lightest-gray-color;
  animation: fadeInOpacity 0.5s ease-in 1;
  -webkit-animation: fadeInOpacity 0.5s ease-in 1;
  -moz-animation: fadeInOpacity 0.5s ease-in 1;
}

.address__autocomplete {
  margin-top: -5px;
  padding: 5px 15px;
  border: 1px solid $frz-semi-light-gray-color;
  border-top: none;
  width: 100%;
  z-index: 200 !important;
}

// ##-Gallery
.fr-gallery-main {
  margin-top: 113px;
  @media screen and (max-width: 767px) {
    margin-top: 51px;
  }
}

.fr-item-list {
  position: relative;
  min-height: 130px;
  .fr-card-btn-bottom {
    margin-bottom: 0;
  }
}

.fr-item-list > .row {
  overflow: hidden;
  margin-top: 0;
}

.fr-gallery-form-add label {
  font-size: $main-font-size;
  //text-transform: capitalize;
  margin-bottom: $offset !important;
  padding-right: $offset;
  width: 20%;
}

.fr-gallery-form-title {
  margin: -20px 0 25px $offset;
}

.fr-gallery-filter {
  padding-bottom: $offset;
}

.fr-gallery-form-add .form-control > option {
  padding: 2px;
}

.fr-gallery-form-add .form-control {
  width: 80%;
  margin-bottom: 15px;
  @media (max-width: 769px) {
    width: 100%;
  }
  &.brand-input {
    width: 100%;
    @media (max-width: 769px) {
      margin-bottom: 0;
    }
  }
}

.fr-violation-gallery-image {
  margin: 15px 0 15px 60px;
}

.fr-violation-gallery-select {
  margin: 0 0 15px 50px;
  @media (max-width: 769px) {
    margin: 0 0 15px -15px;
  }
}

.drop-crop button {
  margin: 5px 0;
}

.fr-drop-crop {
  border: 1px solid $frz-semi-light-gray-color;
  border-radius: 4px;
}

.fr-drop-crop-reject {
  border: 2px solid $frz-red-over-color;
  border-radius: 4px;
}

.fr-drop-crop-reject-badge {
  font-size: 16px;
  background-color: $danger-color;
}

.fr-drop-crop-title {
  border-radius: 4px;
  text-align: center;
}

.fr-glyphicon > .glyphicon {
  font-size: 24px;
  color: $frz-light-gray-color;
  margin-right: 8px;
  &:hover, &:focus {
    color: $frz-blue-color;
    cursor: pointer;
  }
  &.glyphicon-refresh {
    margin: 5px 15px 0 0;
    @media screen and (max-width: 768px) {
      margin: 15px 15px 0 0;
    }
  }
  &.glyphicon-refresh__gallery {
    margin: 5px 15px 0 15px;
    @media screen and (max-width: 1024px) {
      margin: 15px 15px 0 0;
    }
  }
  &.glyphicon-trash {
    color: $frz-red-color;
    margin: 5px 15px 0 -15px;
    &:hover, &:focus {
      color: $frz-red-over-color;
      cursor: pointer;
    }
  }
  &.glyphicon-search {
    margin-right: 0;
  }
  &.glyphicon-chevron-up {
    padding-left: 5px;
    margin-right: 0;
    font-size: 16px;
  }
}

.fr-glyphicon__gallery {
  top: 0;
}

// ##-Artists
.fr-artist-form-title {
  margin: -20px 0 0 $offset;
}

.fr-fav-title {
  display: block;
  font-size: 18px;
  color: $blue-emphasis-color;
}

// ##-TAB- Appointments
.fr-apt-left-container {
  display: inline-block;
}

.fr-appointments__filter {
  float: right;
  display: inline-block;
  padding: 5px 5px 0 5px;
  margin-top: 17px;
  opacity: 0.99;

    @media only screen and (max-width: 769px) {
      float: none !important;
      margin-top: 0;
    }
    .input-group-btn {
      z-index: 100;
    .fr-apt-btn-reset {
      position: absolute;
      right: 38px;
      top: 0;
      border-radius: 0 3px 3px 0 !important;
      padding: 3px 6px 2px 6px;
    }

    .glyphicon-search {
      margin-top: -3px;
      color: $frz-white-color;
      &:hover, &:focus, &:active {
        color: $frz-blue-over-color;
      }
    }
    .glyphicon-remove {
      top: 2px;
    }
  }
}

@media (max-width: 769px) {
  .fr-appointments__filter {
    margin: 0;
    float: none;
    padding-top: 0;
    padding-left: 0;
    .fr-apt-btn-reset {
      right: 46px;
      border-radius: 0 3px 3px 0;
      padding: 4px 6px 3px;
      .glyphicon-remove {
        display: inline-block;
      }
    }
  }
}

.fr-btn-table {
  margin: 5px 5px 0 0;
}

.fr-apt-btn__action {
  margin: 0 4px;
  padding: 4px 10px;
}

.fr-apt-btn__paid {
  margin: 0 4px;
  padding: 4px 12px;
}

.fr-book-btn {
  padding: 4px 12px;
  margin: 0 5px;
}

.fr-pers-apt__action {
  margin: 5px 2px;
}

.fr-apt-inv__action {
  margin-top: 5px;
}

.fr-apt-lbl {
  font-size: 20px;
  margin-left: - $offset;
}

.fr-apt-mt {
  margin-top: 2px;
}

.fr-apt-line:before {
  content: "";
  display: block;
  border-top: 4px solid $frz-blue-color;
}

.fr-apt-status {
  margin-top: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.fr-apt-upcoming {
  @extend .fr-apt-status;
  background-color: $frz-green-color;
  color: $frz-white-color;
}

.fr-apt-done {
  @extend .fr-apt-status;
  background-color: $apt-done-color;
  color: $basic-gray-color;
}

.fr-apt-canceled, .fr-apt-upcomingCanceled {
  @extend .fr-apt-status;
  background-color: $frz-red-color;
  color: $frz-white-color;
}

.fr-apt-pending {
  @extend .fr-apt-status;
  background-color: $danger-color;
  color: $frz-white-color;

}

.fr-apt-span {
  &__canceled {
    background-color: $frz-white-color;
    color: $frz-red-color;
    font-size: 36px;
  }
  &__pending {
    background-color: $frz-white-color;
    color: $danger-color;
    font-size: 36px;
  }
  &__done {
    background-color: $frz-white-color;
    color: $basic-gray-color;
    font-size: 36px;
  }
  &__upcoming {
    background-color: $frz-white-color;
    color: $frz-green-color;
    font-size: 36px;
  }
  &__no-show {
    background-color: $frz-white-color;
    color: $frz-purple-color;
    font-size: 36px;
  }
}

.badge {
  font-size: 14px;
  font-weight: 500;
}

.notification-badge {
  position: absolute;
  top: 12px;
  right: 5px;
  height: 20px;
  width: 20px;
  &.sent {
    background: $badge-message-sent no-repeat top left;
    background-size: 20px 20px;
  }
  &.history {
    background: $badge-message-history no-repeat top left;
    background-size: 20px 20px;
  }
  &.confirmed {
    background: $badge-message-confirmed no-repeat top left;
    background-size: 20px 20px;
  }
  @media screen and (max-width: 769px) {
    top: 10px;
  }
}

.fr-apt-header {
  @extend .fr-list-header;
}

.fr-apt-no-show {
  @extend .fr-apt-status;
  background-color: $frz-purple-color;
  color: $frz-white-color;
}

.fr-apt-modal-header {
  @include rounded(6px, 6px, 0, 0);
}

.fr-apt-modal-footer {
  text-align: center;
}

.fr-apt-modal .modal-body {
  max-height: 200px;
}

.fr-table-centred {
  display: flex;
  align-items: center;
  &__bordered {
    display: flex;
    align-items: center;
    border-bottom: 1px dashed $frz-semi-light-gray-color;
    min-height: 40px;
    &.services {
      padding-top: 2px;
      padding-bottom: 2px;
      @media screen and (max-width: 768px) {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
  &__violations {
    align-items: baseline;
    padding-top: 5px;
  }
  &__btn-toolbar {
    margin-top: -5px;
  }
}

.fr-apt-cell {
  padding: 5px 15px 2px 15px;
}

.fr-apt-date-cell {
  padding: 5px 5px 2px 15px;
}

@media (max-width: 769px) {
  .fr-table-centred {
    display: block;
    &__bordered {
      display: flex;
    }
  }

}

//##-Location
@media (max-width: 991px) {
  .fr-address-zip {
    margin-bottom: 15px;
  }
}

// ##PROMO
// ##-Location
.fr-map-wide {
  width: auto;
  height: 500px;
}

.fr-promo-location {
  position: relative;
}

.fr-promo-location-img {
  background-color: red;
  margin: $offset;
  position: absolute;
  z-index: 1000;
  left: 0;
}

.fr-promo-location-adr {
  position: absolute;
  right: 0;
  width: 40%;
  max-height: 300px;
  margin: 0;
  padding: $offset 0 0;
  overflow-y: auto;
  border-bottom-left-radius: 5px;
  background-color: $semi-dark-color;
  color: $frz-white-color;
  text-shadow: 0 0 4px $semi-dark-color;
  z-index: 1000;
  p {
    margin-top: 3px;
    padding-left: 5px;
  }
  .btn-link:focus {
    color: $basic-gray-color;
  }
  .btn-link.disabled {
    color: $frz-semi-light-gray-color;
    pointer-events: none;
  }
  @media screen and (max-width: 769px) {
    padding: $offset 0 8px;
    width: 50%;
  }
}

.fr-promo-title h1 { //fixed google maps bug
  font-family: $font-main;
  font-weight: 300;
}

// ##MAIN PAGE TABS

.items-filter-title {
  color: $frz-black-color;
  font-size: 36px;
}

.fr-main-tabs {
  font-family: $font-main;
  cursor: pointer;
  .datepicker_wrapper {
    margin-top: 13px;
    position: absolute;
  }
  li {
    height: inherit;
    padding: 0 1px;
    margin-bottom: 0;
    select {
      option {
        font-size: $main-font-size;
      }
    }
    a, > div {
      //background-color: $frz-white-color;
      height: inherit;
      &:hover {
        background-color: $frz-light-gray-color;
      }
    }
    &.active {
      a, > div {
        background-color: $frz-semi-light-gray-color;
        &:hover, &:focus {
          background-color: $frz-semi-light-gray-color;
        }
      }
    }
    > a {
      &[aria-expanded="true"] {
        background-color: $frz-blue-color;
        &:hover, &:focus {
          background-color: $frz-blue-color;
          border-color: $frz-blue-color;
        }
      }
      &:hover, &:focus {
        background-color: $frz-blue-color;
      }
      .active {
        background-color: $frz-blue-color;
      }
    }
    @media screen and (max-width: 769px) {
      padding: 0;
    }
  }
  .fr-main-tabs-fixed {
    height: 135px;
  }
}

.main-tab:hover {
  .main-tab__item {
    background-color: $frz-light-gray-color;
  }
}

.main-tab {
  &__item {
    color: $basic-gray-color;
    border-radius: 0;
    background-color: $white-transparent-color;
    margin-top: 2px;
    font-family: $font-main;
    font-size: 18px;
    //font-weight: bold;
    line-height: 1.42857143;
    border: 1px solid transparent;
    position: relative;
    display: block;
    padding: 8px 15px 50px;
    outline: none;
    &.active {
      background-color: $frz-light-gray-color !important;
    }
  }
  .dropdown-menu {
    li {
      a {
        font-size: $main-font-size;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .main-tab__item {
    font-family: $font-main;
    padding: 6px 15px 6px 15px;
  }
}

@media screen and (max-width: 441px) {
  .main-tab__item {
    font-family: $font-tabs;
    font-weight: bold;
    text-align: center;
    padding: 6px 5px 6px 5px;
  }
}

// ##OVERRIDE

@media (max-width: 991px) {
  .show-only-if-collapsed {
    display: block;
  }
  .hide-only-if-collapsed {
    display: none;
  }
}

@media (max-width: 991px) { // collapse on SM screen size
  .navbar-header {
    float: none;
  }
  .navbar-toggle {
    display: block;
    margin-right: 0;
    background-color: $frz-light-gray-color;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    float: none !important;
    margin: 0 -15px;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-nav > li > button { //collapsing navbar button style difference fix for lyb, login, signup
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    background-color: $frz-white-color;
    &:hover {
      background-color: $frz-white-color;
    }
  }

  .navbar-text {
    float: none;
    margin: 15px 0;
  }
  /* since 3.1.0 */
  .navbar-collapse.collapse.in {
    display: block !important;
  }
  .collapsing {
    overflow: hidden !important;
  }
}

// override recaptcha size
#g-recaptcha,
#g-recaptcha-contact,
#g-recaptcha-affiliate {
  transform: scale(0.89);
  -webkit-transform: scale(0.89);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  @media screen and (max-width: 769px) {
    transform: scale(0.80);
    -webkit-transform: scale(0.80);
  }
}

// ##FILTER
.items-filters {
  margin: 0;
}

.fr-filter-header {
  padding: $offset;
}

.fr-filter-header:nth-child(1) {
  background-color: $frz-blue-color;
}

.fr-filter-header:nth-child(2) {
  background-color: $frz-semi-light-gray-color;
}

.fr-filter-header:nth-child(3) {
  background-color: $frz-semi-light-gray-color;
}

.fr-filter-header:nth-child(4) {
  background-color: $frz-semi-light-gray-color;
}

.fr-filter-panel {
  background-color: $frz-light-gray-color;
  &.services-filter {
    > div {
      &:first-of-type {
        > div {
          margin-top: 10px;
        }
      }
      &:last-of-type {
        > div {
          &:last-of-type {
            &:not(.services) {
              > div {
                display: inline-block;
                min-width: 135px;
              }
            }
          }
        }
      }
    }
  }
}

//##ERROR MESSAGES
.fr-violation {
  text-align: left;
  width: 100%;
  margin: 0 0 15px -15px;
}

.fr-violation__popover {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  left: 10px;
  width: calc(100% - 20px) !important;
  margin-top: 0;
  .popover-content {
    text-align: left;
    padding: 15px 5px 0 5px;
  }
}

li.fr-error {
  &.fr-alert-msg {
    padding: 0;
    margin-right: 0;
    font-size: 14px;
    color: $frz-red-color;
  }
}

.fr-grid-violation {
  width: 100%;
  margin: 7px 0 7px -15px;
}

.error-message {
  color: $frz-red-color;
  padding: 6px;
}

.info-message {
  margin-top: 10px;
  font-weight: bold;
  color: $frz-green-color;
}

.no-fav-message {
  line-height: 200px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  width: 100%;
}

.profile-message {
  padding: 0 15px;
  font-size: 18px;
}

.loading-message {
  display: block;
  padding: 15px;
  text-align: center;
}

// ##MISC
.fr-clr {
  clear: both;
  content: '';
  display: block;
}

.fr-capitalize {
  text-transform: capitalize;
}

.fr-txt-red {
  color: $frz-red-color;
}

.fr-border-red {
  border: 1px solid $frz-red-color;
}

.fr-uppercase {
  text-transform: uppercase;
}

.fr-inline {
  display: inline;
}

.fr-block {
  display: block;
}

.fr-mb {
  margin-bottom: $offset;
}

.fr-mb2 {
  margin-bottom: $offset * 2;
}

.fr-ml {
  margin-left: $offset;
}

.fr-mr {
  margin-right: $offset;
}

.fr-mt0 {
  margin-top: 0;
}

.fr-mt {
  margin-top: $offset;
}

.fr-fl {
  float: left;
}

.fr-fr {
  float: right;
}

.fr-mb-min {
  margin-bottom: 2px;
}

.fr-mt-ng {
  margin-top: -15px;
}

.fr-mt-ng-xs {
  @media screen and (max-width: 769px) {
    margin-top: -15px;
  }
}

.fr-ml-xs {
  margin-left: $offset;
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
}

.fr-pl0 {
  padding-left: 0;
}

.fr-pl0-xs {
  @media screen and (max-width: 961px) {
    padding-left: 0;
  }
}

.fr-pl0-lg {
  @media screen and (min-width: 992px) {
    padding-left: 0;
  }
}

.fr-pr0 {
  padding-right: 0;
}

.fr-pr-md {
  padding-right: $offset;
  @media screen and (max-width: 992px) {
    padding-right: 0;
  }
}

.fr-pb-xs {
  padding-bottom: $offset;
  @media screen and (max-width: 768px) {
    padding-bottom: 0;
  }
}

.fr-ml-ng {
  margin-left: -15px;
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
}

.fr-spacer {
  height: 33px;
  width: 100%;
  font-size: 0;
  margin: 0;
  padding: 0;
  border: 0;
  display: block;
}

.fr-span-message {
  font-size: $main-font-size;
  line-height: 5;
}

.fr-text-center {
  text-align: center;
}

.fr-text-right {
  text-align: right;
}

.fr-text-left {
  text-align: left;
}

.fr-text-lg-left {
  @media screen and (min-width: 992px) {
    text-align: left;
  }
}

.fr-text-xs-left {
  @media screen and (max-width: 991px) {
    text-align: left;
  }
}

.fr-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}

.fr-max-width {
  max-width: 100%;
}

html {
  @media (min-width: 480px) {
    .main-content {
      min-height: 200px;
    }
  }
  @media (min-width: 768px) {
    .container {
      width: 100%;
    }
    .main-content {
      min-height: 480px;
    }
  }
  @media (min-width: 992px) {
    .container {
      width: 100%;
    }
    .main-content {
      min-height: 700px;
    }
  }

  @media (min-width: 1200px) {
    .container {
      width: 1170px;
      &.full-width {
        width: 100%;
        padding: 0 30px;
      }
    }
  }
  .grid.row {
    margin-right: -15px;
    margin-left: -15px;
    .col-md-3 {
      margin: 1px 0;
      .fr-card {
        margin-left: -14px;
        margin-bottom: 0;
        margin-right: -14px;
        border-radius: 3px;
        overflow: hidden;
        img {
          width: 100%;
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          -ms-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s;
          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }
  }
  .services-filter {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    max-height: 500px;
    &.hide {
      display: block !important;
      max-height: 0;
      overflow: hidden;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      -ms-transition: all 0.5s;
      -o-transition: all 0.5s;
      transition: all 0.5s;
      opacity: 1;
    }
  }
  .alert-wrapper {
    float: left;
    .alert {
      text-align: center;
      margin: 20px 15px;
      display: inline-block;
      padding: 6px 10px 6px 10px;
    }
    @media (max-width: 480px) {
      width: 25%;
      .alert {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
        margin-bottom: 5px;
        margin-top: 7px;
      }
    }
  }
  .fr-header-wrap {
    position: static;
    //top: -100px;
    -webkit-transition: all 0.5s ease-in;
    -ms-transition: all 0.5s ease-in;
    -o-transition: all 0.5s ease-in;
    transition: all 0.5s ease-in;
    &.fixed {
      width: 100%;
      left: 0;
      top: 0;
      position: fixed;
      z-index: 101;
      animation: smoothScroll 1s forwards;
    }
  }
}

.form-disclaimer {
  color: $gray20-color;
  margin-bottom: 10px;

  a {
    color: $blue-emphasis-color;
  }
}

.fr-public {
  position: absolute;
  top: 7px;
  left: 7px;
}

.fr-is-public {
  background-color: $frz-green-color;
}

.fr-not-public {
  background-color: $danger-color;
}

.fr-rounded {
  border-radius: 4px !important;
}

.fr-red-badge {
  height: 25px;
  width: 25px;
  background: $badge-red no-repeat top left;
  background-size: 25px 25px;
  &.small-badge {
    height: 20px;
    width: 20px;
    margin-bottom: 5px;
    background: $badge-red no-repeat top left;
    background-size: 20px 20px;
  }
  @media screen and (max-width: 769px) {
    height: 20px;
    width: 20px;
    background: $badge-red no-repeat top left;
    background-size: 20px 20px;
  }
}

.fr-badge__header {
  position: absolute;
  top: 7px;
  left: -10px;
}

.fr-orange-badge {
  height: 25px;
  width: 25px;
  background: $badge-orange no-repeat top left;
  background-size: 25px 25px;
  &.small-badge {
    height: 20px;
    width: 20px;
    margin-bottom: 5px;
    background: $badge-orange no-repeat top left;
    background-size: 20px 20px;
  }
  @media screen and (max-width: 769px) {
    height: 20px;
    width: 20px;
    background: $badge-red no-repeat top left;
    background-size: 20px 20px;
  }
}

.personal-profile__billing-title {
  margin-top: 0;
  font-size: 26px;
  padding-bottom: 10px;
  @media screen and (max-width: 991px) {
    font-size: 22px;
    padding-bottom: 15px;
  }
}

.subscription-profile {
  &__section-separator {
    border-bottom: 1px dashed $frz-semi-light-gray-color;
    padding-bottom: 5px;
  }
  &__plan-title {
    margin-top: 0;
    font-size: 26px;
    padding-bottom: 10px;
    @media screen and (max-width: 991px) {
      font-size: 22px;
    }
  }
  &__spinner {
    position: relative;
    top: -13px;
    right: 15px;
    @media screen and (max-width: 991px) {
      left: 165px;
    }
  }
}

.subscription-status, .transaction-status {
  font-size: 16px;
  &__success {
    margin: 0 15px 0 15px;
    background-color: $frz-green-color;
  }
  &__danger {
    margin: 0 15px 0 15px;
    background-color: $frz-red-color;
  }
  &__default {
    background-color: $frz-green-color;
  }
}

.subscription-status__success, .subscription-status__danger {
  @media screen and (max-width: 768px) {
    margin: 0 0 0 6px;
  }
}

.transaction-status {
  &__success {
    margin: 0;
    background-color: $frz-green-color;
  }
  &__fail {
    margin: 0;
    background-color: $frz-red-color;
  }
  &__other {
    margin: 0;
    background-color: $frz-orange-color;
  }
}

.bank-card-profile, .my-cards-cnt, .subscription-profile {
  &__container {
    padding-left: 15px;
    padding-right: 15px;
    margin: 15px -15px 15px -15px;
  }
  &__payment-sources {
    width: 100%;
    padding-right: 15px;
    @media screen and (max-width: 992px) {
      padding-right: 0;
    }
    &-table {
      display: flex;
      align-items: center;
      padding-bottom: 4px;
      padding-top: 4px;
    }
  }
  &__button-toolbar {
    float: right;
    @media screen and (max-width: 480px) {
      float: none;
    }
  }
  &__banner {
    background-color: $frz-light-gray-color;
    border-radius: 8px;
    padding: 15px;
  }
  &__btn-subscribe {
    float: right;
    @media screen and (max-width: 991px) {
      margin-bottom: 15px;
      float: left;
    }
  }
  &__btn-upgrade {
    margin-right: 15px;
    @media screen and (max-width: 991px) {
      margin-bottom: 15px;
      float: left;
    }
  }
  &__title {
    margin-top: 0;
    font-size: 30px;
    font-weight: bold;
    @media screen and (max-width: 991px) {
      font-size: 22px;
    }
  }
  .fr-btn-table {
    margin-bottom: 5px;
    @media screen and (max-width: 480px) {
      margin-bottom: 0;
    }
  }
  .fr-link-delete {
    padding-left: 0;
    @media screen and (max-width: 991px) {
      margin-top: 0;
    }
  }
  .expired {
    color: $frz-red-color;
  }
  .profile-status {
    &__success {
      margin: 0 15px 0 15px;
      background-color: $frz-green-color;
    }
    &__danger {
      margin: 0 15px 0 15px;
      background-color: $frz-red-color;
    }
    &__default {
      background-color: $frz-green-color;
    }
    &__pending {
      margin: 0 15px 0 15px;
      background-color: $frz-orange-color;
    }
  }
  .bank-card {
    &-green {
      float: left;
      height: 22px;
      width: 33px;
      background: $card-green no-repeat top left;
      background-size: 33px 22px;
    }
    &-gray {
      float: left;
      height: 22px;
      width: 33px;
      background: $card-gray no-repeat top left;
      background-size: 33px 22px;
    }
    &-red {
      float: left;
      height: 22px;
      width: 33px;
      background: $card-red no-repeat top left;
      background-size: 33px 22px;
    }
  }
  .bank {
    &-green {
      float: left;
      height: 22px;
      width: 33px;
      background: $bank-green no-repeat top left;
      background-size: 33px 22px;
    }
    &-gray {
      float: left;
      height: 22px;
      width: 33px;
      background: $bank-gray no-repeat top left;
      background-size: 33px 22px;
    }
  }
  .payment-sources {
    padding-right: 15px;
    @media screen and (max-width: 991px) {
      padding-right: 0;
    }
  }
}

.dropdown-default,
.dropdown-default__selected,
.dropdown-default--menu {
  width: 100%;
  text-align: left;
  li {
    a {
      text-overflow: ellipsis;
      overflow-x: hidden;
      font-size: $main-font-size;
    }
  }
}

.dropdown-default__selected,
.dropdown-gallery__selected,
.dropdown-service-form__selected,
.dropdown-book-clients__selected,
.dropdown-gallery-filter__selected,
.dropdown-book-apt__selected {
  padding-right: 15px;
  font-size: $main-font-size;
  overflow: hidden;
  text-overflow: ellipsis;
  &:before {
    content: "\E252";
    font-family: $font-glyphicon;
    color: $logo-primary-color;
    position: absolute;
    right: 7px;
  }
  > .caret {
    display: none;
  }
}

.dropdown-default--menu {
  max-height: 40vh;
  overflow-y: scroll;
  text-overflow: ellipsis;
  overflow-x: hidden;
  @media #{$mobile} {
    max-height: 80vh;
  }
}

.dropdown-menu {
  li {
    a {
      font-size: $main-font-size;
      text-overflow: ellipsis;
      overflow-x: hidden;
    }
  }
}

.dropdown-gallery {
  width: 80%;
  margin-bottom: 15px;
  text-align: left;
}

.dropdown-gallery__selected,
.dropdown-gallery--menu,
.dropdown-book-apt__selected,
.dropdown-book-apt--menu,
.dropdown-gallery--menu,
.dropdown-service-form__selected,
.dropdown-service-form__disabled,
.dropdown-service-form--menu,
.dropdown-book-clients__selected,
.dropdown-gallery-filter__selected,
.dropdown-gallery-filter--menu,
.dropdown-book-clients--menu {
  width: 100%;
  text-align: left;
  color: #555;
}

.dropdown-book-apt,
.dropdown-book-clients {
  width: 100%;
  margin-bottom: 15px;
  text-align: left;
}

.dropdown-book-apt--menu,
.dropdown-book-clients--menu {
  height: auto;
  max-height: 200px;
  overflow-x: hidden
}

.dropdown-book-clients--menu {
  position: relative;
}

.dropdown-service-form {
  width: 60%;
  text-align: left;
  color: #555;
}

.dropdown-gallery-filter {
  width: 108%;
  @media screen and (max-width: 769px) {
    width: 100%;
  }
}

.dropdown-gallery--menu > li > a {
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-brand-selector--menu {
  display: block;
  max-height: 300px;
  overflow: auto;
  margin: -15px 0 15px;
  width: 100%;
  @media screen and (max-width: 769px) {
    margin-top: 0;
    max-height: 150px;
  }
}

.find-client-list {
  display: block;
  width: 100%;
  overflow-x: hidden;
  max-height: 200px;
  position: relative;
  margin-top: -15px;
  margin-bottom: 15px;
}
