@import "./../vars/colors";
.pagination {
  .active {
    a, a:hover, a:active, a:focus {
      background-color: $frz-blue-over-color;
      border-color: $frz-blue-over-color;
    }
  }
}
.fr-pagination-standard {
  margin-left: 0;
  li {
    a, a:active, a:hover, span:hover {
      background-color: $frz-white-color;
      border-color: $frz-blue-color;
      color: $frz-blue-color;
      margin-bottom: 7px;
      .active, a:hover, a:focus {
        color: $frz-white-color;
        background-color: $frz-blue-over-color;
        border-color: $frz-blue-over-color;
      }
    }
  }
  .active {
    span:hover {
      z-index: 3;
      color: $frz-blue-color;
      cursor: default;
      background-color: $frz-white-color;
      border-color: $frz-blue-color;
    }
  }
  .disabled {
    a, a:active, a:hover, span:hover {
      background-color: $frz-white-color;
      border-color: $frz-blue-color;
      color: $frz-blue-color;
      pointer-events: none;
    }
  }
}





