@import "../vars/_colors";
@import "../vars/_assets";

.popover {
  display: block !important;
  max-width: 200px !important;
  padding: 0 2px 2px 0;
  width: 200px;
  margin: 2px;

  &-share {
    border-radius: 10px;
    width: auto;
    padding: 0;
    z-index: 2000;
    &__block {
      margin-left: 15px;
      display: inline-block;
      height: 40px;
      &:first-child {
        margin: 0;
      }
    }
    .popover-content {
      display: flex;
      border-radius: 10px;
      padding: 15px;
    }
    &__btn {
      background: none;
      border: none;
      padding: 0;
    }
    &__facebook {
      background: transparent $social-icons no-repeat 0 0;
      transition: background 0.4s linear;
      width: 40px;
      height: 40px;
      &:hover {
        background: $social-icons no-repeat 0 -40px;
        transition: background 0.4s linear;
      }
    }
    &__pinterest {
      background: transparent $social-icons no-repeat -120px 0;
      transition: background 0.4s linear;
      border: none;
      padding: 0;
      width: 40px;
      height: 40px;
      &:hover {
        background: $social-icons no-repeat -120px -40px;
        transition: background 0.4s linear;
      }
    }
  }

}

.popover-title {
  margin: 6px; // reset heading margin
  padding: 0 2px 2px 4px;
  font-family: $font-main;
  font-size: 16px;
  background-color: $frz-white-color !important;
}

.popover-content {
  background: transparent;
  margin-top: 5px;
  padding-bottom: 15px;
  color: #616161;
  font-family: $font-main;
  font-size: 16px;
  text-align: center;
  .title {
    margin-top: 10px;
  }
  .comment {
    text-align: left;
  }
  .comment-title {
    margin-top: 10px;
    text-align: left;
  }
  .btn-link {
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    color: $frz-blue-color;
  }
  .close {
    position: absolute;
    top: 2px;
    right: 2px;
    height: 25px;
    width: 25px;
    &:focus, &:hover, &:active {
      outline: none;
    }
  }
  .action-btn {
    margin: 10px 0 15px;
    &.pull-right {
      .popover-btn {
        margin-right: -2px;
      }
    }
    &--mb {
      margin: 10px 0 5px;
    }
  }
  .comment-btn {
    margin-left: 5px;
    margin-bottom: 0;
    font-size: 14px;
    padding: 0 3px;
  }
  .btn-container {
    padding: 0 12px 0 15px;
    .popover-btn-block {
      margin-left: 5px;
    }
  }
}

.popover-txt-sel {
  font-weight: bold;
}

.popover-btn {
  display: inline-block;
  padding: 2px 12px;
  margin: 3px 3px 3px 0;
  font-size: $main-font-size;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  transition: 0.4s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 3px solid;
  border-radius: 4px;
  outline: none;
}

.popover-btn-book, .popover-btn-unblock {
  background-color: $frz-green-color;
  border: 3px solid $frz-green-color;
  color: $frz-white-color;
  &:hover {
    background-color: $frz-green-over-color;
    border: 3px solid $frz-green-over-color;
    color: $frz-white-color;
  }
}

.popover-btn-block {
  background-color: $frz-red-color;
  border: 3px solid $frz-red-color;
  color: $frz-white-color;
  outline: none;
  &:hover {
    background-color: $frz-red-over-color;
    border: 3px solid $frz-red-over-color;
    color: $frz-white-color;
  }
}

.popover-btn-edit {
  background-color: $frz-blue-color;
  border: 3px solid $frz-blue-color;
  color: $frz-white-color;
  outline: none;
  &:hover {
    background-color: $frz-blue-over-color;
    border: 3px solid $frz-blue-over-color;
    color: $frz-white-color;
  }
}

.popover-btn-cancel {
  background-color: $frz-white-color;
  border: 0 transparent;
  padding: 5px;
  color: $frz-red-color;
  &:hover {
    background-color: $frz-white-color;
    color: $frz-red-over-color;
  }
}

.popover-btn-noshow {
  background-color: $frz-purple-color;
  border: 3px solid $frz-purple-color;
  color: $frz-white-color;
  &:hover {
    background-color: $frz-purple-over-color;
    border: 3px solid $frz-purple-over-color;
    color: $frz-white-color;
  }
}

.popover.bottom {
  margin-top: -3px;
}

.service-bold {
  font-weight: bold;
}

.ml-min {
  margin-left: 5px;
}