/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/* cyrillic-ext */
@import url(style-vendors/cropper.css);
@import url(style-vendors/datepicker.css);
@import url(style-vendors/dropzone.css);
@import url(style-vendors/jquery.Jcrop.min.css);
@import url(style-vendors/offcanvas.css);
@import url(style-vendors/slider.css);
@import url(style-vendors/bootstrap-slider.min.css);
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/0eC6fl06luXEYWpBSJvXCBJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Fl4y0QdOxyyTHEGMXX8kcRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Roboto Condensed';
  src: url("fonts/roboto-condensed-light/RobotoCondensed-Light.woff") format("woff"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.ttf") format("truetype"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.svg#RobotoCondensed-Light") format("svg");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.eot");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.woff2") format("woff2"), url("fonts/roboto-condensed-light/roboto-condensed.light.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("fonts/glyphicons-halflings-regular.woff") format("woff"), url("fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("fonts/glyphicons-halflings-regular.svg#Glyphicons Halflings") format("svg");
  src: url("fonts/glyphicons-halflings-regular.eot");
  src: url("fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/0eC6fl06luXEYWpBSJvXCBJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Fl4y0QdOxyyTHEGMXX8kcRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Roboto Condensed';
  src: url("fonts/roboto-condensed-light/RobotoCondensed-Light.woff") format("woff"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.ttf") format("truetype"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.svg#RobotoCondensed-Light") format("svg");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.eot");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.woff2") format("woff2"), url("fonts/roboto-condensed-light/roboto-condensed.light.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("fonts/glyphicons-halflings-regular.woff") format("woff"), url("fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("fonts/glyphicons-halflings-regular.svg#Glyphicons Halflings") format("svg");
  src: url("fonts/glyphicons-halflings-regular.eot");
  src: url("fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@keyframes fadeInOpacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeInRight {
  0% {
    -webkit-transform: translateX(75%);
    transform: translateX(75%);
    visibility: visible; }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInDown {
  0% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes slideDown {
  0% {
    top: calc(100% - 15px);
    height: 0;
    opacity: 1; }
  100% {
    top: 15px;
    height: calc(100% - 15px);
    opacity: 0; } }

@keyframes slideUp {
  0% {
    height: 15px;
    opacity: 1; }
  100% {
    height: calc(100% - 15px);
    opacity: 0; } }

.btn {
  font-size: 16px; }
  .btn.active:focus {
    outline: none; }

.fr-btn-more {
  margin: 30px 0 10px;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #46e6aa;
  border: 3px solid #46e6aa;
  -webkit-transition: background 0.4s linear;
  -moz-transition: background 0.4s linear;
  -o-transition: background 0.4s linear;
  transition: background 0.4s linear;
  background-color: #46e6aa;
  border: 3px solid #46e6aa;
  color: #ffffff; }
  .fr-btn-more:hover, .fr-btn-more:focus, .fr-btn-more:active, .fr-btn-more:active:focus {
    background-color: #0de592;
    border: 3px solid #0de592;
    color: #ffffff;
    outline: none !important;
    box-shadow: none; }
  .fr-btn-more.hidden {
    display: block !important;
    visibility: hidden !important; }
  .fr-btn-more:hover {
    background-color: #0de592;
    border-color: #0de592;
    color: #ffffff; }

.fr-btn {
  background-color: #46aae6;
  border: 3px solid #46aae6;
  color: #ffffff;
  padding: 4px 12px;
  margin-bottom: 10px;
  color: #ffffff;
  background-color: #46aae6;
  border: 3px solid #46aae6;
  -webkit-transition: background 0.4s linear;
  -moz-transition: background 0.4s linear;
  -o-transition: background 0.4s linear;
  transition: background 0.4s linear; }
  .fr-btn:hover, .fr-btn:focus, .fr-btn:active, .fr-btn:active:focus {
    background-color: #0d96e5;
    border: 3px solid #0d96e5;
    color: #ffffff;
    outline: none !important;
    box-shadow: none; }
  .fr-btn:hover, .fr-btn:active, .fr-btn:focus {
    background-color: #0d96e5;
    border: 3px solid #0d96e5;
    color: #ffffff; }
  .fr-btn:disabled:hover {
    background-color: #ffffff;
    border: 3px solid #46aae6;
    color: #46aae6; }
  .fr-btn-small {
    padding: 3px 6px; }

.btn-link:focus, .btn-link:active {
  outline: 0 !important;
  text-decoration: none !important;
  background-color: inherit !important;
  border-color: transparent !important; }

.btn-link.appointment-filter {
  padding-top: 0; }

.btn-link.no-card {
  padding-right: 0;
  padding-left: 0;
  font-weight: bold; }

.btn-link.full-width {
  width: 100%; }

.fr-btn-danger {
  background-color: #e64650 !important;
  border: 3px solid #e64650; }
  .fr-btn-danger:hover, .fr-btn-danger:active, .fr-btn-danger:focus {
    background-color: #e50d1c !important;
    border-color: #e50d1c;
    outline: 0; }

.fr-btn-remove {
  background-color: #e64650;
  border: 3px solid #e64650;
  color: #ffffff;
  background-color: #e64650;
  border: 3px solid #e64650;
  -webkit-transition: background 0.4s linear;
  -moz-transition: background 0.4s linear;
  -o-transition: background 0.4s linear;
  transition: background 0.4s linear;
  color: #ffffff; }
  .fr-btn-remove:hover, .fr-btn-remove:focus, .fr-btn-remove:active, .fr-btn-remove:active:focus {
    background-color: #e50d1c;
    border: 3px solid #e50d1c;
    color: #ffffff;
    outline: none !important;
    box-shadow: none; }
  .fr-btn-remove:hover {
    background-color: #e50d1c;
    border: 3px solid #e50d1c;
    color: #ffffff; }

.fr-btn-edit {
  color: #ffffff;
  background-color: #46aae6;
  border: 3px solid #46aae6;
  -webkit-transition: background 0.4s linear;
  -moz-transition: background 0.4s linear;
  -o-transition: background 0.4s linear;
  transition: background 0.4s linear; }
  .fr-btn-edit:hover, .fr-btn-edit:focus, .fr-btn-edit:active, .fr-btn-edit:active:focus {
    background-color: #0d96e5;
    border: 3px solid #0d96e5;
    color: #ffffff;
    outline: none !important;
    box-shadow: none; }

.fr-btn-book, .fr-btn-add {
  color: #ffffff;
  background-color: #46e6aa;
  border: 3px solid #46e6aa;
  -webkit-transition: background 0.4s linear;
  -moz-transition: background 0.4s linear;
  -o-transition: background 0.4s linear;
  transition: background 0.4s linear; }
  .fr-btn-book:hover, .fr-btn-book:focus, .fr-btn-book:active, .fr-btn-book:active:focus, .fr-btn-add:hover, .fr-btn-add:focus, .fr-btn-add:active, .fr-btn-add:active:focus {
    background-color: #0de592;
    border: 3px solid #0de592;
    color: #ffffff;
    outline: none !important;
    box-shadow: none; }
  .fr-btn-book:disabled, .fr-btn-book:disabled:hover, .fr-btn-add:disabled, .fr-btn-add:disabled:hover {
    background-color: #cccccc;
    border: 1px solid #cccccc;
    color: #333;
    outline: none !important; }

.fr-link {
  color: #ffffff; }
  .fr-link:hover {
    color: #ffffff;
    cursor: pointer; }
  .fr-link:visited, .fr-link:active, .fr-link:focus {
    color: #ffffff; }

.fr-btn-serv-save {
  background-color: #46aae6;
  border-color: #46aae6;
  color: #ffffff;
  color: #ffffff;
  background-color: #46aae6;
  border: 3px solid #46aae6;
  -webkit-transition: background 0.4s linear;
  -moz-transition: background 0.4s linear;
  -o-transition: background 0.4s linear;
  transition: background 0.4s linear; }
  .fr-btn-serv-save:hover, .fr-btn-serv-save:focus, .fr-btn-serv-save:active, .fr-btn-serv-save:active:focus {
    background-color: #0d96e5;
    border: 3px solid #0d96e5;
    color: #ffffff;
    outline: none !important;
    box-shadow: none; }
  .fr-btn-serv-save:hover {
    background-color: #0d96e5 !important;
    border-color: #0d96e5;
    color: #ffffff; }

.fr-btn-dismiss:hover {
  background-color: #0d96e5 !important;
  border-color: #0d96e5;
  color: #ffffff; }

.fr-btn-serv-edit:hover {
  background-color: #0d96e5 !important;
  border-color: #0d96e5;
  color: #ffffff; }

.fr-btn-serv-remove {
  color: #e64650; }
  .fr-btn-serv-remove:hover {
    color: #e50d1c; }

.fr-btn-onoff .btn-default:first-child:active {
  color: #ffffff;
  background-color: #46e6aa; }

.fr-btn-onoff .btn-default:first-child.active {
  color: #ffffff;
  background-color: #46e6aa; }

.fr-btn-onoff .btn-default:first-child:hover {
  background-color: #0de592; }

.fr-btn-onoff .btn-default:last-child:active {
  background-color: #e64650;
  color: #ffffff; }

.fr-btn-onoff .btn-default:last-child.active {
  background-color: #e64650;
  color: #ffffff; }

.fr-btn-onoff .btn-default:last-child:hover {
  background-color: #e50d1c;
  color: #ffffff; }

.fr-btn-routed .btn-default:active {
  background-color: #46aae6; }

.fr-btn-routed .btn-default:active {
  background-color: #46aae6; }

.fr-btn-with-input .input-group-btn > .btn {
  border-left-width: 0;
  left: -2px;
  padding-bottom: 2px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.fr-btn-with-input .form-control:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
  border-color: #cccccc; }

.fr-btn-with-input {
  margin-bottom: 15px; }

.fr-uploader-remove-btn, .fr-link-delete {
  -webkit-transition: background 0.4s linear;
  -moz-transition: background 0.4s linear;
  -o-transition: background 0.4s linear;
  transition: background 0.4s linear;
  color: #e64650; }
  .fr-uploader-remove-btn:hover, .fr-link-delete:hover {
    color: #e50d1c; }

.fr-big-button {
  padding: 7px 50px; }

.fr-btn-update, .fr-btn-apply {
  color: #ffffff;
  background-color: #46aae6;
  -webkit-transition: background 0.4s linear;
  -moz-transition: background 0.4s linear;
  -o-transition: background 0.4s linear;
  transition: background 0.4s linear; }
  .fr-btn-update:hover, .fr-btn-apply:hover {
    background-color: #0d96e5;
    color: #ffffff; }
  .fr-btn-update.address, .fr-btn-apply.address {
    padding: 3px 12px 3px !important;
    border: 3px solid #46aae6;
    box-shadow: none !important; }
    .fr-btn-update.address:hover, .fr-btn-update.address:active, .fr-btn-update.address:focus, .fr-btn-apply.address:hover, .fr-btn-apply.address:active, .fr-btn-apply.address:focus {
      color: #ffffff;
      background-color: #0d96e5;
      border: 3px solid #0d96e5;
      border-left-width: 0; }
    @media screen and (max-width: 769px) {
      .fr-btn-update.address, .fr-btn-apply.address {
        padding-top: 3px !important; } }

.fr-btn-apply {
  padding: 4px 38px; }

.fr-btn-dropdown {
  text-align: center;
  -webkit-transition: background 0.4s linear;
  -moz-transition: background 0.4s linear;
  -o-transition: background 0.4s linear;
  transition: background 0.4s linear;
  color: #ffffff;
  background-color: #46aae6 !important;
  border: 3px solid #46aae6;
  border-radius: 4px !important;
  padding: 6px 15px 7px 15px; }
  .fr-btn-dropdown:hover, .fr-btn-dropdown:focus {
    background-color: #0d96e5 !important;
    border-color: #0d96e5 !important;
    color: #ffffff !important;
    border: 3px solid #0d96e5;
    text-decoration: none; }
  .fr-btn-dropdown > .fr-span-complete {
    margin-right: 5px;
    height: 25px;
    width: 25px; }
  .fr-btn-dropdown > .fr-span-fully-incomplete {
    margin-right: 5px;
    height: 25px;
    width: 25px;
    background: url("img/badge-red.svg") no-repeat top left;
    background-size: 25px 25px; }
  .fr-btn-dropdown > .fr-span-incomplete {
    margin-right: 5px;
    height: 25px;
    width: 25px;
    background: url("img/badge-orange.svg") no-repeat top left;
    background-size: 25px 25px; }

.fr-btn-big__green {
  color: #ffffff;
  background-color: #46e6aa;
  border: 3px solid #46e6aa;
  -webkit-transition: background 0.4s linear;
  -moz-transition: background 0.4s linear;
  -o-transition: background 0.4s linear;
  transition: background 0.4s linear;
  font-size: 18px; }
  .fr-btn-big__green:hover, .fr-btn-big__green:focus, .fr-btn-big__green:active, .fr-btn-big__green:active:focus {
    background-color: #0de592;
    border: 3px solid #0de592;
    color: #ffffff;
    outline: none !important;
    box-shadow: none; }
  .fr-btn-big__green.client {
    padding-left: 55px;
    padding-right: 55px; }
  .fr-btn-big__green.professional {
    padding-left: 21px;
    padding-right: 21px; }
  .fr-btn-big__green.landing {
    font-size: 24px; }

.fr-btn-address-remove {
  padding: 4px 12px;
  color: #ffffff;
  background-color: #e64650;
  border: 3px solid #e64650;
  -webkit-transition: background 0.4s linear;
  -moz-transition: background 0.4s linear;
  -o-transition: background 0.4s linear;
  transition: background 0.4s linear;
  color: #ffffff; }
  .fr-btn-address-remove:hover, .fr-btn-address-remove:focus, .fr-btn-address-remove:active, .fr-btn-address-remove:active:focus {
    background-color: #e50d1c;
    border: 3px solid #e50d1c;
    color: #ffffff;
    outline: none !important;
    box-shadow: none; }

.fr-btn-go-up {
  position: fixed;
  bottom: 50px;
  right: 10px;
  opacity: 0;
  border: 3px solid #46aae6;
  color: #ffffff;
  background-color: #46aae6;
  padding: 4px 10px;
  visibility: hidden;
  overflow: hidden;
  text-align: center;
  z-index: 11;
  -webkit-box-shadow: 3px 3px 8px 2px #999999;
  -moz-box-shadow: 3px 3px 8px 2px #999999;
  box-shadow: 3px 3px 8px 2px #999999;
  transform: translate3d(0, 0, 0);
  color: #ffffff;
  background-color: #46aae6;
  border: 3px solid #46aae6;
  -webkit-transition: background 0.4s linear;
  -moz-transition: background 0.4s linear;
  -o-transition: background 0.4s linear;
  transition: background 0.4s linear; }
  .fr-btn-go-up:hover, .fr-btn-go-up:focus, .fr-btn-go-up:active, .fr-btn-go-up:active:focus {
    background-color: #0d96e5;
    border: 3px solid #0d96e5;
    color: #ffffff;
    outline: none !important;
    box-shadow: none; }
  .fr-btn-go-up:active, .fr-btn-go-up:focus, .fr-btn-go-up:hover {
    border: 3px solid #0d96e5;
    color: #ffffff;
    background-color: #0d96e5; }

.fr-btn-go-up.show {
  visibility: visible;
  cursor: pointer;
  opacity: 1; }

.fr-serv-action-btn {
  padding: 3px 6px;
  float: right;
  background-color: #46aae6;
  border: 3px solid #46aae6;
  color: #ffffff;
  color: #ffffff;
  background-color: #46aae6;
  border: 3px solid #46aae6;
  -webkit-transition: background 0.4s linear;
  -moz-transition: background 0.4s linear;
  -o-transition: background 0.4s linear;
  transition: background 0.4s linear; }
  .fr-serv-action-btn:hover, .fr-serv-action-btn:focus, .fr-serv-action-btn:active, .fr-serv-action-btn:active:focus {
    background-color: #0d96e5;
    border: 3px solid #0d96e5;
    color: #ffffff;
    outline: none !important;
    box-shadow: none; }
  @media screen and (max-width: 769px) {
    .fr-serv-action-btn {
      margin: 5px 5px 5px 0; } }

.btn-social {
  border: none; }

.fr-btn-assign {
  margin-bottom: 10px;
  color: #ffffff;
  background-color: #46e6aa;
  border: 3px solid #46e6aa;
  -webkit-transition: background 0.4s linear;
  -moz-transition: background 0.4s linear;
  -o-transition: background 0.4s linear;
  transition: background 0.4s linear; }
  .fr-btn-assign:hover, .fr-btn-assign:focus, .fr-btn-assign:active, .fr-btn-assign:active:focus {
    background-color: #0de592;
    border: 3px solid #0de592;
    color: #ffffff;
    outline: none !important;
    box-shadow: none; }
  .fr-btn-assign:disabled, .fr-btn-assign:disabled:hover {
    background-color: #cccccc;
    border: 1px solid #cccccc;
    color: #333;
    outline: none !important; }

.btn-success {
  color: #ffffff;
  background-color: #46e6aa;
  border-color: #46e6aa;
  color: #ffffff;
  background-color: #46e6aa;
  border: 3px solid #46e6aa;
  -webkit-transition: background 0.4s linear;
  -moz-transition: background 0.4s linear;
  -o-transition: background 0.4s linear;
  transition: background 0.4s linear; }
  .btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success:active:focus {
    background-color: #0de592;
    border: 3px solid #0de592;
    color: #ffffff;
    outline: none !important;
    box-shadow: none; }

.btn-danger {
  color: #ffffff;
  background-color: #e64650;
  border-color: #e64650;
  color: #ffffff;
  background-color: #e64650;
  border: 3px solid #e64650;
  -webkit-transition: background 0.4s linear;
  -moz-transition: background 0.4s linear;
  -o-transition: background 0.4s linear;
  transition: background 0.4s linear; }
  .btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger:active:focus {
    background-color: #e50d1c;
    border: 3px solid #e50d1c;
    color: #ffffff;
    outline: none !important;
    box-shadow: none; }

.btn-reset {
  position: absolute;
  right: 15px;
  top: 0;
  border-radius: 0 3px 3px 0;
  padding: 4px 6px;
  z-index: 100; }
  @media screen and (max-width: 769px) {
    .btn-reset {
      padding: 7px 8px; }
      .btn-reset .glyphicon-remove {
        display: inline-block; } }
  .btn-reset.address {
    padding: 5px 6px 1px; }
    @media screen and (max-width: 769px) {
      .btn-reset.address {
        padding: 8px 6px 4px; } }

.fr-btn-double-photo-before {
  display: block;
  height: 76px;
  width: 76px;
  cursor: pointer;
  background: url("img/before.svg") center no-repeat;
  background-size: 76px 76px; }

.fr-btn-double-photo-after {
  display: block;
  height: 76px;
  width: 76px;
  cursor: pointer;
  background: url("img/after.svg") center no-repeat;
  background-size: 76px 76px; }

.fr-gallery-btn-add {
  padding: 4px 40px;
  margin-bottom: 10px;
  border: 3px solid #46e6aa;
  border-radius: 4px;
  color: #ffffff;
  background-color: #46e6aa;
  color: #ffffff;
  background-color: #46e6aa;
  border: 3px solid #46e6aa;
  -webkit-transition: background 0.4s linear;
  -moz-transition: background 0.4s linear;
  -o-transition: background 0.4s linear;
  transition: background 0.4s linear;
  text-decoration: none; }
  .fr-gallery-btn-add:hover, .fr-gallery-btn-add:focus, .fr-gallery-btn-add:active, .fr-gallery-btn-add:active:focus {
    background-color: #0de592;
    border: 3px solid #0de592;
    color: #ffffff;
    outline: none !important;
    box-shadow: none; }
  @media screen and (max-width: 769px) {
    .fr-gallery-btn-add {
      padding-right: 16px;
      padding-left: 16px; } }
  @media screen and (max-width: 667px) {
    .fr-gallery-btn-add {
      padding-right: 6px;
      padding-left: 6px; } }
  @media screen and (max-width: 480px) {
    .fr-gallery-btn-add.fr-mr {
      margin-right: 0; } }

.fr-btn--inverse {
  background-color: #46aae6;
  color: #ffffff;
  padding: 3px 10px 1px 10px;
  font-size: 18px;
  text-transform: uppercase;
  outline: none; }
  .fr-btn--inverse:hover, .fr-btn--inverse:active, .fr-btn--inverse:focus {
    outline: none;
    background-color: #0d96e5;
    color: #ffffff; }
  .fr-btn--inverse:active:focus {
    outline: none; }

.fr-btn--clear {
  background-color: #ffffff;
  border: 2px solid #46aae6;
  color: #46aae6;
  padding: 5px 12px; }
  .fr-btn--clear:hover, .fr-btn--clear:active, .fr-btn--clear:focus {
    border: 2px solid #0d96e5;
    color: #0d96e5; }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/0eC6fl06luXEYWpBSJvXCBJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Fl4y0QdOxyyTHEGMXX8kcRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Roboto Condensed';
  src: url("fonts/roboto-condensed-light/RobotoCondensed-Light.woff") format("woff"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.ttf") format("truetype"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.svg#RobotoCondensed-Light") format("svg");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.eot");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.woff2") format("woff2"), url("fonts/roboto-condensed-light/roboto-condensed.light.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("fonts/glyphicons-halflings-regular.woff") format("woff"), url("fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("fonts/glyphicons-halflings-regular.svg#Glyphicons Halflings") format("svg");
  src: url("fonts/glyphicons-halflings-regular.eot");
  src: url("fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px; }
  .spinner__gif {
    position: absolute;
    top: 8px;
    right: 110px;
    opacity: 0.5;
    background: url("img/spinner.gif") no-repeat top left;
    background-size: 50px 50px;
    height: 50px;
    width: 50px; }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
.loader-wrapper {
  display: block;
  height: 45px;
  margin-top: 30px; }

.loader {
  background: white;
  position: absolute;
  left: 50%;
  margin-left: -70px;
  width: 140px;
  border: 7px solid #e6e6e6;
  border-radius: 11px;
  padding: 4px;
  zoom: 1;
  animation: loader-border 2.3s ease-in infinite;
  -o-animation: loader-border 2.3s ease-in infinite;
  -ms-animation: loader-border 2.3s ease-in infinite;
  -webkit-animation: loader-border 2.3s ease-in infinite;
  -moz-animation: loader-border 2.3s ease-in infinite; }

.loader-span {
  display: block;
  position: relative;
  width: 0;
  height: 14px;
  background: #46aae6;
  animation: loader-slide 2.3s ease-in infinite;
  -o-animation: loader-slide 2.3s ease-in infinite;
  -ms-animation: loader-slide 2.3s ease-in infinite;
  -webkit-animation: loader-slide 2.3s ease-in infinite;
  -moz-animation: loader-slide 2.3s ease-in infinite; }

.loader:after {
  display: block;
  position: absolute;
  top: 4px;
  left: 66%;
  height: 14px;
  width: 4px;
  background: white;
  content: '';
  clear: both;
  z-index: 10; }

.loader:before {
  position: absolute;
  display: block;
  width: 4px;
  left: 33%;
  height: 14px;
  background: white;
  z-index: 10;
  content: ''; }

@keyframes loader-slide {
  0% {
    width: 0;
    margin-left: 0;
    opacity: 0; }
  0% {
    width: 0;
    margin-left: 0;
    opacity: 1; }
  50% {
    width: 0;
    margin-left: 0; }
  75% {
    width: 100%;
    margin-left: 0; }
  90% {
    width: 0;
    margin-left: 100%;
    opacity: 1; }
  100% {
    width: 0;
    margin-left: 100%;
    opacity: 0; } }

.loader-msg-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: nowrap;
  margin: 0 -15px 0 -15px;
  align-items: center;
  min-height: 260px; }
  @media screen and (max-width: 769px) {
    .loader-msg-wrapper {
      min-height: 180px; } }
  .loader-msg-wrapper--full-height {
    min-height: 490px; }
    @media screen and (max-width: 769px) {
      .loader-msg-wrapper--full-height {
        min-height: 200px; } }
  .loader-msg-wrapper--item {
    flex: 1 1 auto;
    margin: 10px 15px 10px 15px;
    align-self: center;
    text-align: center;
    font-size: 16px; }

.loader-msg-block {
  display: block;
  padding-bottom: 50px;
  height: auto; }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
.pagination .active a, .pagination .active a:hover, .pagination .active a:active, .pagination .active a:focus {
  background-color: #0d96e5;
  border-color: #0d96e5; }

.fr-pagination-standard {
  margin-left: 0; }
  .fr-pagination-standard li a, .fr-pagination-standard li a:active, .fr-pagination-standard li a:hover, .fr-pagination-standard li span:hover {
    background-color: #ffffff;
    border-color: #46aae6;
    color: #46aae6;
    margin-bottom: 7px; }
    .fr-pagination-standard li a .active, .fr-pagination-standard li a a:hover, .fr-pagination-standard li a a:focus, .fr-pagination-standard li a:active .active, .fr-pagination-standard li a:active a:hover, .fr-pagination-standard li a:active a:focus, .fr-pagination-standard li a:hover .active, .fr-pagination-standard li a:hover a:hover, .fr-pagination-standard li a:hover a:focus, .fr-pagination-standard li span:hover .active, .fr-pagination-standard li span:hover a:hover, .fr-pagination-standard li span:hover a:focus {
      color: #ffffff;
      background-color: #0d96e5;
      border-color: #0d96e5; }
  .fr-pagination-standard .active span:hover {
    z-index: 3;
    color: #46aae6;
    cursor: default;
    background-color: #ffffff;
    border-color: #46aae6; }
  .fr-pagination-standard .disabled a, .fr-pagination-standard .disabled a:active, .fr-pagination-standard .disabled a:hover, .fr-pagination-standard .disabled span:hover {
    background-color: #ffffff;
    border-color: #46aae6;
    color: #46aae6;
    pointer-events: none; }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
.gallery-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  color: #464646; }
  .gallery-message__block {
    font-size: 30px;
    align-self: flex-start; }

.gallery-end-message {
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #cccccc; }

.screen-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  margin-top: 80px; }
  .screen-message__block {
    align-self: flex-start;
    color: #464646; }
    .screen-message__block-content {
      font-size: 30px;
      font-weight: 200;
      text-align: center; }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/0eC6fl06luXEYWpBSJvXCBJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Fl4y0QdOxyyTHEGMXX8kcRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Roboto Condensed';
  src: url("fonts/roboto-condensed-light/RobotoCondensed-Light.woff") format("woff"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.ttf") format("truetype"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.svg#RobotoCondensed-Light") format("svg");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.eot");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.woff2") format("woff2"), url("fonts/roboto-condensed-light/roboto-condensed.light.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("fonts/glyphicons-halflings-regular.woff") format("woff"), url("fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("fonts/glyphicons-halflings-regular.svg#Glyphicons Halflings") format("svg");
  src: url("fonts/glyphicons-halflings-regular.eot");
  src: url("fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/0eC6fl06luXEYWpBSJvXCBJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Fl4y0QdOxyyTHEGMXX8kcRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Roboto Condensed';
  src: url("fonts/roboto-condensed-light/RobotoCondensed-Light.woff") format("woff"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.ttf") format("truetype"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.svg#RobotoCondensed-Light") format("svg");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.eot");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.woff2") format("woff2"), url("fonts/roboto-condensed-light/roboto-condensed.light.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("fonts/glyphicons-halflings-regular.woff") format("woff"), url("fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("fonts/glyphicons-halflings-regular.svg#Glyphicons Halflings") format("svg");
  src: url("fonts/glyphicons-halflings-regular.eot");
  src: url("fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@keyframes fadeInOpacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeInRight {
  0% {
    -webkit-transform: translateX(75%);
    transform: translateX(75%);
    visibility: visible; }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInDown {
  0% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes slideDown {
  0% {
    top: calc(100% - 15px);
    height: 0;
    opacity: 1; }
  100% {
    top: 15px;
    height: calc(100% - 15px);
    opacity: 0; } }

@keyframes slideUp {
  0% {
    height: 15px;
    opacity: 1; }
  100% {
    height: calc(100% - 15px);
    opacity: 0; } }

.fr-header:after, .fr-footer:after {
  clear: both;
  content: '';
  display: block; }

html {
  height: 100%;
  overflow-x: hidden; }
  @media screen and (min-width: 992px) {
    html {
      margin-left: calc(99vw - 100%);
      margin-right: 0; } }

body {
  background: #ffffff;
  color: #464646;
  font-family: "Roboto", Helvetica, sans-serif;
  font-size: 16px; }
  body #react-app {
    min-height: calc(100% - 280px); }

h1 {
  font-size: 48px; }

h2 {
  font-size: 32px; }

h3 {
  font-size: 24px; }

.container {
  position: relative; }

form label {
  font-weight: normal;
  font-size: 30px; }
  @media screen and (max-width: 641px) {
    form label {
      font-size: 18px; } }

*:not(input) {
  -ms-user-select: none; }

.overflow-hide {
  overflow: hidden;
  max-height: 100%; }

.frizo-app {
  overflow-x: hidden; }

.fr-filter-panel .col-md-12 {
  min-height: 0; }

.fr-filter-panel .btn-group input:checked + label {
  background: #cccccc;
  color: #ffffff;
  font-weight: bold; }

.fr-filter-panel .btn-group > div {
  padding: 0;
  overflow: hidden; }
  .fr-filter-panel .btn-group > div label {
    border-radius: 0;
    border: 1px solid #464646;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    border-left-width: 0; }
  .fr-filter-panel .btn-group > div:first-of-type label {
    border-left-width: 1px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .fr-filter-panel .btn-group > div:last-of-type label {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }

.fr-filter-panel .btn-group input + label {
  display: block;
  padding: 6px 12px;
  margin: 0;
  background: #ffffff;
  text-align: center;
  font-weight: normal; }
  .fr-filter-panel .btn-group input + label:before {
    display: none !important;
    font-size: 0; }

.fr-filter-panel label {
  vertical-align: middle; }

.fr-filter-panel .col-md-12.services > div {
  width: auto;
  transition: all 0.5s;
  max-height: 60px; }
  .fr-filter-panel .col-md-12.services > div.hidden, .fr-filter-panel .col-md-12.services > div[data-attr-visible="hide"] {
    overflow: hidden;
    width: 0;
    max-height: 0;
    transition: all 0.5s;
    display: block !important; }
    .fr-filter-panel .col-md-12.services > div.hidden label, .fr-filter-panel .col-md-12.services > div[data-attr-visible="hide"] label {
      height: 0; }

input[type=checkbox], input[type=radio] {
  margin-right: 4px; }
  input[type=checkbox] + label, input[type=radio] + label {
    margin-right: 10px; }

input::-ms-clear {
  display: none; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

[placeholder]:focus::-webkit-input-placeholder {
  transition: text-indent 0.5s 0.5s ease;
  text-indent: -100%; }

[placeholder]:focus::-moz-placeholder {
  transition: text-indent 0.5s 0.5s ease;
  text-indent: -100%; }

[placeholder]:focus:-ms-input-placeholder {
  transition: text-indent 0.5s 0.5s ease;
  text-indent: -100%; }

.fr-header {
  padding-top: 15px;
  padding-right: 0; }
  .fr-header-logo {
    background: url("img/logo.svg") no-repeat top left;
    background-size: 188px 50px;
    float: left;
    height: 50px;
    width: 188px;
    margin-right: 45px; }
  .fr-header-wrap {
    background-color: #ffffff; }
  .fr-header .navbar {
    margin-bottom: 15px; }
  .fr-header .nav .open > a {
    background-color: #ffffff; }

@media only screen and (max-width: 991px) {
  .fr-header {
    padding-right: 15px; }
  .clients-menu {
    animation: fadeInOpacity 0.5s linear 1;
    -webkit-animation: fadeInOpacity 0.5s linear 1;
    -moz-animation: fadeInOpacity 0.5s linear 1; } }

.glyphicon.glyphicon-user {
  margin-right: 5px; }

.glyphicon.glyphicon-option-horizontal {
  top: 4px; }

.glyphicon.glyphicon-check {
  color: #46e6aa; }

.fr-login {
  background: #e6e6e6;
  display: inline;
  float: left;
  width: 300px; }

#footer {
  clear: both;
  position: relative;
  bottom: 0;
  width: 100%; }

.fr-footer {
  background-color: #464646;
  border-top: 4px solid #46aae6;
  /* todo: create border class in sass!! */
  padding: 20px;
  text-align: center;
  font-size: 18px; }
  .fr-footer-wrap {
    background-color: #464646;
    border-bottom: 4px solid #46aae6;
    /* todo: create border class in sass!! */ }
  .fr-footer-copyright {
    background-color: #ffffff;
    padding: 22px 15px 0 15px; }
  .fr-footer-version {
    background-color: #ffffff;
    padding: 22px 15px 15px 15px;
    color: #cccccc;
    text-align: right; }
  .fr-footer-text {
    display: block;
    padding: 10px 0; }
  .fr-footer-faq {
    display: block;
    padding: 10px 0;
    float: right; }
  .fr-footer-contact-us {
    padding: 8px 0 8px 0;
    text-align: center; }
  .fr-footer .fr-lang-flag {
    float: left;
    height: 27px;
    width: 40px;
    margin-top: 7px; }
  .fr-footer .fr-lang-en {
    background: url("img/flag-us.svg") no-repeat left top;
    background-size: 51px 27px; }
  .fr-footer .fr-lang-es {
    background: url("img/flag-es.svg") no-repeat left top;
    background-size: 51px 27px; }
  .fr-footer .fr-footer-store {
    padding-right: 0;
    text-align: right; }
  @media only screen and (max-width: 991px) {
    .fr-footer .fr-social {
      display: inline-block; }
    .fr-footer .fr-footer-store {
      text-align: center; }
    .fr-footer .fr-footer-faq {
      float: none; }
    .fr-footer .fr-footer-center-flag {
      display: inline-block;
      margin: 0 auto; } }
  @media only screen and (max-width: 321px) {
    .fr-footer .fr-footer-store {
      padding-left: 0; }
    .fr-footer .fr-iosicon, .fr-footer .fr-google-icon {
      margin: 0; } }
  .fr-footer a {
    color: #ffffff;
    outline: none; }
    .fr-footer a:hover {
      cursor: pointer; }
  .fr-footer ul.fr-social {
    margin: 0;
    min-width: 200px;
    padding: 0;
    /* For IE, the outcast */
    zoom: 1;
    *display: inline; }
    .fr-footer ul.fr-social li {
      display: inline; }
      .fr-footer ul.fr-social li a {
        float: left;
        margin-right: 10px;
        width: 40px;
        height: 40px; }
      .fr-footer ul.fr-social li.fr-fb a {
        background: transparent url("img/facebook-inactive.svg") no-repeat;
        background-size: 40px 40px; }
        .fr-footer ul.fr-social li.fr-fb a:hover {
          background: transparent url("img/facebook.svg") no-repeat;
          background-size: 40px 40px; }
      .fr-footer ul.fr-social li.fr-ig a {
        background: transparent url("img/instagram-inactive.svg") no-repeat;
        background-size: 40px 40px; }
        .fr-footer ul.fr-social li.fr-ig a:hover {
          background: transparent url("img/instagram.svg") no-repeat;
          background-size: 40px 40px; }
      .fr-footer ul.fr-social li.fr-pt a {
        background: transparent url("img/pinterest-inactive.svg") no-repeat;
        background-size: 40px 40px; }
        .fr-footer ul.fr-social li.fr-pt a:hover {
          background: transparent url("img/pinterest.svg") no-repeat;
          background-size: 40px 40px; }
      .fr-footer ul.fr-social li.fr-yt a {
        background: transparent url("img/youtube-inactive.svg") no-repeat;
        background-size: 40px 40px; }
        .fr-footer ul.fr-social li.fr-yt a:hover {
          background: transparent url("img/youtube.svg") no-repeat;
          background-size: 40px 40px; }
  .fr-footer-array {
    color: #f3f3f3;
    cursor: none;
    text-decoration: none; }
    .fr-footer-array:hover, .fr-footer-array:focus, .fr-footer-array:active {
      color: #f3f3f3;
      cursor: none;
      text-decoration: none; }

.fr-iosicon {
  display: inline-block;
  float: none;
  background: url("img/appstore-badge.svg") no-repeat top left;
  background-size: 120px 40px;
  height: 40px;
  width: 120px; }
  .fr-iosicon.large {
    margin-right: 15px;
    background-size: 180px 60px;
    height: 60px;
    width: 180px; }
    @media screen and (max-width: 480px) {
      .fr-iosicon.large {
        margin-right: 0; } }

.fr-google-icon {
  display: inline-block;
  float: none;
  margin-left: 7px;
  margin-right: 10px;
  background: url("img/google-play-badge.svg") no-repeat top left;
  background-size: 135px 40px;
  height: 40px;
  width: 135px; }
  .fr-google-icon.large {
    margin-left: 15px;
    background-size: 203px 60px;
    height: 60px;
    width: 203px; }
    @media screen and (max-width: 769px) {
      .fr-google-icon.large {
        margin-left: 0;
        margin-right: 4px; } }

@media only screen and (max-width: 321px) {
  .fr-iosicon, .fr-google-icon {
    margin: 0; } }

@media (max-width: 991px) {
  .fr-footer-copyright, .fr-footer-version, .fr-footer-array {
    text-align: center; } }

.fr-header {
  font-size: 18px; }
  .fr-header .dropdown-menu > li > a {
    padding: 0;
    margin: 3px 20px; }
  .fr-header .nav li > a {
    padding-right: 0; }
    .fr-header .nav li > a:focus, .fr-header .nav li > a:hover {
      color: #464646; }
  .fr-header .fr-navbar {
    background: none;
    border: none;
    text-transform: uppercase; }
    .fr-header .fr-navbar a {
      color: rgba(0, 0, 0, 0.5); }
    .fr-header .fr-navbar .navbar-nav {
      vertical-align: middle; }
  .fr-header .navbar-inverse .navbar-toggle,
  .fr-header .navbar-inverse .navbar-collapse, .fr-header .navbar-inverse .navbar-form {
    border-color: transparent; }
  .fr-header .navbar-inverse .navbar-collapse {
    box-shadow: none; }
  @media only screen and (max-width: 991px) {
    .fr-header .dropdown-menu {
      display: block;
      background-color: transparent;
      border: 0 none;
      box-shadow: none;
      margin-top: 0;
      position: static;
      width: 100%; }
    .fr-header .navbar-nav .dropdown-menu .dropdown-header {
      padding: 10px 15px 10px 15px; }
    .fr-header .navbar-nav .dropdown-menu > li {
      margin: 15px 15px 15px 0;
      height: 25px;
      padding: 0; }
    .fr-header .navbar-nav .dropdown-menu > li > a {
      margin-left: 0; }
    .fr-header .navbar-default .navbar-nav .dropdown-menu > li > a {
      color: #777; } }
  .fr-header .dropdown-menu,
  .fr-header .navbar-nav .dropdown-menu > li > a,
  .fr-header .navbar-inverse .navbar-nav > .open > a,
  .fr-header .navbar-inverse .navbar-nav > .open > a:focus {
    background-color: #ffffff;
    font-size: 18px; }
  .fr-header .navbar-nav .dropdown-menu > li > a:hover {
    color: #464646; }
  .fr-header .fr-navbar-dropdown-toggle {
    padding-top: 0; }
  .fr-header .fr-li-hidden {
    visibility: hidden;
    margin: 0;
    height: 0; }
  .fr-header .fr-li-visible {
    visibility: visible;
    margin: 15px 0 15px 15px; }
  .fr-header .nav li {
    margin: 15px 0 0 15px; }
    @media (min-width: 991px) {
      .fr-header .nav li {
        float: left; } }
    .fr-header .nav li > a {
      padding: 0;
      text-decoration: none; }
    .fr-header .nav li > a:hover {
      text-decoration: underline; }
    .fr-header .nav li a:focus, .fr-header .nav li a:hover {
      outline: none;
      background-color: #ffffff;
      border: none; }
    .fr-header .nav li.dropdown-beauty {
      margin-top: 12px; }
  .fr-header .fr-discount {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 500;
    color: #46e6aa;
    text-decoration: none; }
    .fr-header .fr-discount:hover {
      color: #0de592; }

.navbar-right {
  margin-right: 0; }
  .navbar-right .caret {
    margin-left: 5px; }
  .navbar-right .badge {
    margin-left: 5px; }
  .navbar-right .header-menu__dropdown {
    margin-left: -19px;
    left: 0;
    right: auto; }
    .navbar-right .header-menu__dropdown > li {
      margin: 7px 0 0 7px; }
      .navbar-right .header-menu__dropdown > li > a:hover, .navbar-right .header-menu__dropdown > li a:focus {
        text-decoration: underline; }
    .navbar-right .header-menu__dropdown > li:first-child {
      margin-top: 7px; }
    .navbar-right .header-menu__dropdown > li:last-child {
      margin-bottom: 7px; }

@media screen and (max-width: 991px) {
  .navbar-right .header-menu__dropdown {
    margin: -15px 0 15px 0 !important; }
    .navbar-right .header-menu__dropdown > li > a {
      color: rgba(0, 0, 0, 0.5); } }

.fr-banner-main, .fr-banner-main--header, .fr-banner-main--landing {
  text-align: center;
  padding-bottom: 9px;
  color: #464646; }
  .fr-banner-main h1, .fr-banner-main--header h1, .fr-banner-main--landing h1 {
    font-size: 48px;
    font-weight: 200; }
    @media screen and (max-width: 1024px) {
      .fr-banner-main h1, .fr-banner-main--header h1, .fr-banner-main--landing h1 {
        font-size: 40px; } }
    @media screen and (max-width: 769px) {
      .fr-banner-main h1, .fr-banner-main--header h1, .fr-banner-main--landing h1 {
        font-size: 32px;
        margin: 10px 0 0 0; } }
  .fr-banner-main.pricing, .fr-banner-main--header.pricing, .fr-banner-main--landing.pricing {
    margin-bottom: 10px; }

.fr-banner-main--header {
  color: #ffffff; }

.fr-banner-main--landing {
  margin-top: 0; }
  .fr-banner-main--landing h1 {
    font-weight: 200; }
  .fr-banner-main--landing a {
    font-size: 24px; }
  @media screen and (max-width: 769px) {
    .fr-banner-main--landing {
      margin-top: -5px; } }

.fr-banner-top {
  background-color: #ffffff;
  color: #464646;
  padding: 10px 0 10px 0; }
  .fr-banner-top h1, .fr-banner-top .fr-link-h1 {
    display: inline-block;
    font-size: 48px;
    font-weight: 200;
    margin: 0; }
    @media screen and (max-width: 641px) {
      .fr-banner-top h1, .fr-banner-top .fr-link-h1 {
        margin-top: 2px;
        font-size: 28px; }
        .fr-banner-top h1 span, .fr-banner-top .fr-link-h1 span {
          margin-bottom: 4px; } }
  .fr-banner-top h2 {
    display: inline;
    margin: 0;
    text-transform: uppercase; }
  .fr-banner-top .fr-link-h1 {
    margin-top: 0;
    color: #46aae6;
    text-decoration: none; }
    @media screen and (max-width: 769px) {
      .fr-banner-top .fr-link-h1 {
        margin-top: -4px; } }
    .fr-banner-top .fr-link-h1:focus, .fr-banner-top .fr-link-h1:hover {
      cursor: pointer; }
  .fr-banner-top .fr-link-h1[data-hover='long']:focus, .fr-banner-top .fr-link-h1[data-hover='long']:hover {
    position: relative;
    cursor: pointer; }
    .fr-banner-top .fr-link-h1[data-hover='long']:focus:after, .fr-banner-top .fr-link-h1[data-hover='long']:hover:after {
      position: absolute;
      content: '';
      left: 0;
      bottom: 11px;
      height: 2px;
      background: #46aae6;
      right: 35px; }
  @media screen and (max-width: 769px) {
    .fr-banner-top .fr-link-h1[data-hover='long']:focus, .fr-banner-top .fr-link-h1[data-hover='long']:hover {
      position: relative;
      cursor: pointer; }
      .fr-banner-top .fr-link-h1[data-hover='long']:focus:after, .fr-banner-top .fr-link-h1[data-hover='long']:hover:after {
        position: absolute;
        content: '';
        left: 0;
        bottom: 6px;
        height: 2px;
        background: #46aae6;
        right: 25px; } }
  .fr-banner-top .fr-link-h1[data-hover='short']:focus, .fr-banner-top .fr-link-h1[data-hover='short']:hover {
    position: relative;
    cursor: pointer; }
    .fr-banner-top .fr-link-h1[data-hover='short']:focus:after, .fr-banner-top .fr-link-h1[data-hover='short']:hover:after {
      position: absolute;
      content: '';
      left: 0;
      bottom: 11px;
      height: 2px;
      background: #46aae6;
      right: 10px; }
  @media screen and (max-width: 769px) {
    .fr-banner-top .fr-link-h1[data-hover='short']:focus, .fr-banner-top .fr-link-h1[data-hover='short']:hover {
      position: relative;
      cursor: pointer; }
      .fr-banner-top .fr-link-h1[data-hover='short']:focus:after, .fr-banner-top .fr-link-h1[data-hover='short']:hover:after {
        position: absolute;
        content: '';
        left: 0;
        bottom: 6px;
        height: 2px;
        background: #46aae6;
        right: 5px; } }
  .fr-banner-top .fr-text-h1 {
    margin-top: 9px;
    margin-bottom: 17px;
    padding-right: 0;
    padding-left: 3px; }
    @media screen and (max-width: 769px) {
      .fr-banner-top .fr-text-h1 {
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 10px;
        padding-left: 16px; } }
  .fr-banner-top .fr-text-h1__big {
    margin-top: 9px;
    margin-bottom: 17px;
    padding-right: 0;
    padding-left: 4px; }
    @media screen and (max-width: 769px) {
      .fr-banner-top .fr-text-h1__big {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 16px; } }
    @media screen and (max-width: 480px) {
      .fr-banner-top .fr-text-h1__big {
        padding-left: 11px; } }
  .fr-banner-top .fr-profile-complete {
    background-color: #46e6aa;
    text-align: center; }
  .fr-banner-top .fr-banner__button {
    margin: 0;
    padding: 0 12px;
    border-radius: 4px;
    background-color: #ffffff;
    color: #46aae6;
    font-size: 50px;
    font-weight: normal; }
    @media screen and (max-width: 641px) {
      .fr-banner-top .fr-banner__button {
        margin-top: 6px;
        font-size: 28px; } }
  .fr-banner-top__text {
    margin-top: 0;
    margin-bottom: 17px; }
  .fr-banner-top__text--clients, .fr-banner-top__text--apt {
    margin-top: 10px;
    margin-bottom: 19px;
    padding-left: 0; }
    .fr-banner-top__text--clients h1, .fr-banner-top__text--apt h1 {
      margin-top: 0; }
    @media screen and (max-width: 769px) {
      .fr-banner-top__text--clients, .fr-banner-top__text--apt {
        padding-left: 14px;
        padding-right: 27px; } }
    @media screen and (max-width: 641px) {
      .fr-banner-top__text--clients, .fr-banner-top__text--apt {
        margin-bottom: 10px;
        margin-left: 5px; } }
  .fr-banner-top__text--content {
    margin-top: 10px;
    margin-bottom: 19px;
    padding-right: 17px; }
    @media screen and (max-width: 769px) {
      .fr-banner-top__text--content {
        margin-top: -5px;
        margin-bottom: 8px;
        padding-right: 0; } }
    .fr-banner-top__text--content h1 {
      font-size: 48px;
      font-weight: 200; }
      @media screen and (max-width: 769px) {
        .fr-banner-top__text--content h1 {
          font-size: 32px; } }
  .fr-banner-top__text--legal {
    margin-top: 0;
    margin-bottom: 0; }
    @media screen and (max-width: 769px) {
      .fr-banner-top__text--legal {
        font-size: 32px; } }
  .fr-banner-top__text--salonName, .fr-banner-top__text--left {
    margin: 10px 0 19px 0;
    padding-left: 3px; }
    @media screen and (max-width: 991px) {
      .fr-banner-top__text--salonName, .fr-banner-top__text--left {
        margin-top: -5px;
        margin-bottom: 8px;
        padding-left: 16px; } }
  @media screen and (max-width: 769px) {
    .fr-banner-top__margin--mobile {
      margin-top: 5px; } }
  .fr-banner-top__artistName {
    margin: 0 0 0px -10px; }
    @media screen and (max-width: 769px) {
      .fr-banner-top__artistName {
        margin-top: 6px;
        margin-bottom: 0;
        padding-left: 15px;
        font-size: 18px;
        font-weight: 400; } }
    .fr-banner-top__artistName--title {
      display: inline-block;
      margin: 0;
      font-size: 48px;
      font-weight: 200;
      color: inherit; }
      @media screen and (max-width: 769px) {
        .fr-banner-top__artistName--title {
          font-size: 24px;
          font-weight: 400;
          color: inherit; } }
      @media screen and (max-width: 769px) {
        .fr-banner-top__artistName--title.xs-left {
          margin-left: -10px; } }

.fr-profile {
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
  font-size: 18px;
  color: #ffffff; }
  .fr-profile__incomplete {
    background-color: #e6aa46; }
  .fr-profile__fully-incomplete {
    background-color: #e64650; }
  .fr-profile__text {
    font-size: 16px;
    font-weight: 500;
    margin: 5px 0; }
    @media screen and (max-width: 769px) {
      .fr-profile__text {
        padding: 5px 5px 0 5px;
        font-size: 16px; } }
  @media screen and (max-width: 769px) {
    .fr-profile {
      padding-top: 0; } }

.fr-full-width {
  width: 101vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin: -5px -50vw 15px -50vw;
  padding-bottom: 0;
  color: #ffffff; }
  .fr-full-width__book {
    padding-bottom: 2px; }
  @media screen and (max-width: 769px) {
    .fr-full-width {
      margin-top: 0; } }
  .fr-full-width.artist-promo {
    margin-top: 0;
    margin-bottom: 0;
    padding: 8px 0 9px 0;
    color: inherit; }
    @media screen and (max-width: 769px) {
      .fr-full-width.artist-promo {
        padding: 4px 0 3px 0; } }

.fr-container-width {
  width: 1170px;
  margin: auto; }
  @media screen and (max-width: 1170px) {
    .fr-container-width {
      width: 98%;
      margin: auto; } }

.fr-banner-bottom {
  visibility: hidden;
  clear: both;
  position: fixed;
  z-index: 122;
  bottom: 0;
  left: 1px;
  width: calc(100% - 2px);
  height: 70px;
  padding: 15px;
  text-align: center;
  background-color: #464646;
  animation: fadeInUp 1s linear 1;
  -webkit-animation: fadeInUp 1s linear 1;
  -moz-animation: fadeInUp 1s linear 1; }
  .fr-banner-bottom .fr-iosicon {
    margin-right: 7px; }
  .fr-banner-bottom .fr-google-icon {
    margin-right: 0; }
  .fr-banner-bottom__text {
    padding: 10px;
    font-size: 18px;
    font-weight: 400;
    color: #ffffff; }
  .fr-banner-bottom__close {
    position: absolute;
    top: 5px;
    right: 10px;
    background-color: transparent;
    cursor: pointer;
    display: inline-block; }
    .fr-banner-bottom__close .btn {
      color: #cccccc;
      text-shadow: none;
      opacity: 1; }
      .fr-banner-bottom__close .btn:hover, .fr-banner-bottom__close .btn:focus, .fr-banner-bottom__close .btn:active {
        background-color: #464646;
        color: #999999;
        border: none; }
  @media screen and (max-width: 768px) {
    .fr-banner-bottom {
      visibility: visible;
      height: 102px;
      padding: 5px;
      font-size: 16px; } }

.fr-informer-bottom {
  position: fixed;
  bottom: 15px;
  left: 0;
  z-index: 1982;
  height: 50px;
  padding: 15px;
  overflow: visible;
  text-align: center;
  color: #ffffff;
  background-color: #46aae6;
  animation: fadeInUp 1s linear 1;
  -webkit-animation: fadeInUp 1s linear 1;
  -moz-animation: fadeInUp 1s linear 1; }

.informer-title {
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 0;
  margin-bottom: 5px; }

@media only screen and (max-width: 991px) {
  .fr-mt-xs {
    margin-top: 15px; }
  .fr-mb-xs {
    margin-bottom: 15px; }
  .fr-fr-xs {
    float: right; }
  .fr-mb4-xs {
    margin-bottom: 60px; }
  .fr-xs-center {
    float: none;
    text-align: center; } }

@media screen and (min-width: 768px) {
  .fr-xs-break {
    display: none; } }

.nav-tabs > li {
  margin-bottom: 2px; }
  .nav-tabs > li > a {
    color: #464646;
    border-color: #cccccc;
    border-radius: 0;
    margin-top: 2px;
    padding-top: 8px;
    padding-bottom: 7px;
    font-family: "Roboto", Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 500; }
    .nav-tabs > li > a:hover {
      border-color: transparent;
      border-bottom: 4px solid #cccccc;
      background-color: #ffffff;
      padding-bottom: 7px;
      outline: none; }
      .nav-tabs > li > a:hover > .fr-span-fully-incomplete {
        margin-top: -1px;
        margin-left: 7px;
        float: right;
        height: 25px;
        width: 25px;
        background: url("img/badge-red.svg") no-repeat top left;
        background-size: 25px 25px; }
      .nav-tabs > li > a:hover > .fr-span-incomplete {
        margin-top: -1px;
        margin-left: 7px;
        float: right;
        height: 25px;
        width: 25px;
        background: url("img/badge-orange.svg") no-repeat top left;
        background-size: 25px 25px; }
    .nav-tabs > li > a:active {
      border-color: transparent;
      background-color: #e6e6e6;
      color: #46aae6; }
    @media screen and (max-width: 768px) {
      .nav-tabs > li > a {
        margin-top: 0; } }
    @media screen and (max-width: 1024px) {
      .nav-tabs > li > a {
        font-family: "Roboto Condensed", Cambria, Helvetica, sans-serif;
        font-weight: bold; } }

ul.nav-tabs[role="tablist"] {
  margin-bottom: 0;
  border-bottom: 0; }

.fr-navbar-tabs > li {
  margin-bottom: 0; }
  .fr-navbar-tabs > li > a {
    padding-bottom: 7px;
    padding-top: 8px; }
  @media screen and (max-width: 768px) {
    .fr-navbar-tabs > li {
      margin-bottom: 0; } }

.fr-navbar-tabs {
  margin-bottom: 15px;
  margin-right: 0;
  margin-left: 0;
  border-bottom-width: 1px;
  border-color: #cccccc; }

#nav-tabs-wrapper .nav-tabs > ul {
  margin-bottom: 15px;
  margin-right: -15px;
  border-bottom-width: 0;
  border-color: #cccccc; }

#nav-tabs-wrapper li:first-child {
  margin-left: 0; }

.fr-complete, .fr-incomplete {
  background-color: #46e6aa;
  color: #ffffff;
  margin: 0 0 0 4px;
  padding: 2px 5px;
  text-transform: lowercase;
  font-size: 18px;
  border: 1px solid #ffffff;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px; }

.fr-incomplete {
  background-color: #e64650; }

.fr-tab-complete, .fr-tab-fully-incomplete, .fr-tab-incomplete {
  background-color: #46e6aa;
  color: #ffffff;
  margin: -8px -15px 7px -15px;
  font-size: 15px;
  text-align: center;
  border-radius: 4px 4px 0 0; }

.fr-tab-fully-incomplete {
  background-color: #e64650; }

.fr-tab-incomplete {
  background-color: #e6aa46; }

.fr-tab-empty {
  margin-top: 22px !important; }
  @media screen and (max-width: 769px) {
    .fr-tab-empty {
      margin-top: 0 !important; } }

.fr-span-complete {
  display: none; }
  @media only screen and (max-width: 769px) {
    .fr-span-complete {
      display: inline-block;
      margin-top: -1px;
      margin-left: 7px;
      float: right;
      height: 25px;
      width: 25px; } }

.fr-span-fully-incomplete {
  margin-top: -1px;
  margin-left: 7px;
  float: right;
  height: 25px;
  width: 25px;
  background: url("img/badge-red.svg") no-repeat top left;
  background-size: 25px 25px; }

.fr-span-incomplete {
  margin-top: -1px;
  margin-left: 7px;
  float: right;
  height: 25px;
  width: 25px;
  background: url("img/badge-orange.svg") no-repeat top left;
  background-size: 25px 25px; }

@media screen and (max-width: 769px) {
  .fr-span-incomplete, .fr-span-fully-incomplete, .fr-span-complete {
    margin-right: 15px; } }

@media only screen and (max-width: 769px) {
  .li-cap {
    position: fixed;
    margin-left: calc(100% - 138px); } }

.faq-component .fr-routed-tab {
  margin-top: 10px; }

.faq-component .fr-navbar-tabs {
  margin-bottom: 0; }

.faq-component .fr-tab-content {
  margin-top: 0; }

.faq-component .fr-faq-contact {
  margin-bottom: 10px; }
  .faq-component .fr-faq-contact .btn-contact {
    padding: 0;
    margin-bottom: 2px;
    color: #46aae6; }

.panel-title > a {
  display: block; }
  .panel-title > a:hover, .panel-title > a:focus {
    cursor: pointer; }

@media only screen and (max-width: 769px) {
  #nav-tabs-wrapper li:first-child {
    margin-left: 0; }
  .fr-tab-complete, .fr-tab-fully-incomplete, .fr-tab-incomplete {
    display: none; }
  ul.nav-tabs {
    text-align: left; } }

.fr-book__title {
  display: inline-block;
  margin-bottom: 5px;
  font-size: 32px;
  font-weight: normal; }
  @media screen and (max-width: 768px) {
    .fr-book__title {
      font-size: 24px; } }
  @media screen and (max-width: 480px) {
    .fr-book__title {
      font-size: 20px; } }

.fr-book__journal-title {
  display: inline-block;
  margin: 2px 0 10px 0;
  padding-left: 4px;
  font-size: 48px;
  font-weight: 200; }
  @media screen and (max-width: 769px) {
    .fr-book__journal-title {
      padding-left: 14px; } }
  @media screen and (max-width: 480px) {
    .fr-book__journal-title {
      margin-left: 11px;
      font-size: 28px;
      padding-left: 0; } }

@media screen and (max-width: 991px) {
  .fr-book__style {
    margin-top: 30px; } }

.fr-apt-modal .modal-body {
  max-height: 200px; }

.fr-book-appointment-notification {
  background-color: #46e6aa;
  font-size: 18px;
  padding: 15px; }

.fr-book-appointment-header {
  background-color: #e6e6e6;
  font-size: 18px;
  padding: 15px; }

.fr-apt-header-cancel {
  background-color: #e8b7d5;
  font-size: 18px;
  padding: 15px; }

.fr-apt-table-container {
  float: left;
  width: 100%;
  margin-top: -45px; }

.fr-apt-pay-label {
  display: inline-block;
  padding-top: 10px;
  vertical-align: middle; }

.fr-serv-row {
  margin-bottom: 6px;
  display: flex;
  align-items: center; }
  @media screen and (max-width: 769px) {
    .fr-serv-row {
      margin-bottom: 0; } }

.fr-serv-duration, .fr-serv-processing, .fr-serv-price {
  text-align: right;
  padding-right: 30px; }

@media screen and (max-width: 769px) {
  .fr-serv-processing, .fr-serv-price {
    padding-right: 10px; } }

@media screen and (max-width: 769px) {
  .fr-serv-duration {
    padding-right: 15px;
    text-align: right; } }

.fr-serv-price__artist {
  text-align: right;
  padding-right: 60px; }

.fr-serv-price-edit {
  text-align: right;
  padding-right: 30px; }
  .fr-serv-price-edit ::placeholder {
    text-align: left;
    padding-left: 5px; }
  @media screen and (max-width: 769px) {
    .fr-serv-price-edit {
      padding-right: 15px; } }

.fr-serv-action {
  float: right; }
  @media screen and (max-width: 769px) {
    .fr-serv-action {
      padding-right: 0; } }

.fr-serv-edit-control {
  height: 30px;
  float: right;
  padding: 6px 4px;
  margin-top: 0;
  text-align: right; }

.fr-serv-price:before, .fr-serv-price__artist:before {
  content: "$\00a0"; }

.fr-serv-price-edit__artist {
  margin-top: 0;
  padding-right: 60px; }

.fr-serv-btn__edit {
  margin: 0 5px; }

.fr-serv-price-title {
  text-align: right;
  padding-right: 30px;
  /* @media screen and (max-width: 992px) {
     padding-right: 30px;
     text-align: right;
   }*/ }
  @media screen and (max-width: 769px) {
    .fr-serv-price-title {
      padding-right: 10px;
      text-align: right; } }

.fr-serv-duration-title {
  text-align: center; }
  @media screen and (max-width: 769px) {
    .fr-serv-duration-title {
      text-align: left; } }

@media screen and (max-width: 769px) {
  .fr-serv-action-title {
    padding-left: 30px; } }

.fr-serv-action__more {
  float: right;
  margin-top: 10px; }

.fr-serv-badge {
  margin-left: 7px; }

.fr-serv-edit, .fr-client-delete, .fr-btn-table, .fr-apt-cancel {
  margin-bottom: 2px; }

.fr-list-header, .fr-apt-header {
  background-color: #e6e6e6;
  padding: 8px 2px;
  margin-bottom: 2px; }
  .fr-list-header.bordered, .bordered.fr-apt-header {
    padding: 0;
    opacity: 0.95;
    border-bottom: 3px #46aae6 solid; }
    .fr-list-header.bordered > div, .bordered.fr-apt-header > div {
      padding: 8px 15px;
      border-right: 1px solid #ffffff; }
      .fr-list-header.bordered > div:last-child, .bordered.fr-apt-header > div:last-child {
        border-right: 1px solid #e6e6e6; }

.row.fr-table-header {
  background-color: #f3f3f3;
  padding: 4px 2px 4px 15px;
  text-transform: capitalize;
  margin-bottom: 2px; }

.fr-table-content {
  background-color: #ffffff;
  word-wrap: break-word; }

.fr-table-section {
  background-color: #ffffff;
  padding-top: 4px; }

.fr-table-icon {
  font-size: 1.2em;
  padding-right: 15px; }

.fr-serv-select {
  margin-top: 15px; }
  .fr-serv-select.form-control {
    padding-left: 0;
    padding-right: 0; }

.fr-serv-select > option {
  padding: 4px 7px;
  text-overflow: ellipsis;
  overflow: hidden; }
  .fr-serv-select > option:nth-child(even) {
    background-color: #f3f3f3; }

.fr-add-serv-form {
  margin-top: 15px;
  margin-bottom: 15px; }

.fr-add-serv-label {
  width: 40%;
  font-size: 16px;
  padding-right: 15px; }

.fr-add-serv-item {
  width: 60% !important; }

@media (max-width: 769px) {
  .fr-add-serv-item {
    float: right; }
  .fr-add-serv-label {
    margin-top: 10px;
    margin-bottom: 0; } }

.m0 {
  margin: 0; }

.p0 {
  padding: 0; }

.rwd-line {
  display: inline; }
  @media screen and (max-width: 769px) {
    .rwd-line {
      display: block; } }

.no-decoration {
  text-decoration: none !important; }

.fr-wh-address-container {
  margin-top: 15px; }

.fr-wh-adr-select {
  width: 100%; }

.form-control {
  position: relative;
  z-index: 2;
  font-size: 16px; }
  @media screen and (max-width: 769px) {
    .form-control {
      font-size: 16px; } }

.fr-wh-time-container {
  margin: 15px 0 30px 0; }

.fr-wh-time-container-readOnly {
  margin: 15px 0 15px 0; }

.tooltip-inner .tooltip-arrow {
  display: none !important; }

.fr-wh-artist__select {
  margin: 0 0 15px 15px;
  padding-right: 30px; }

.fr-wh-span {
  padding-right: 10px; }
  .fr-wh-span span {
    font-weight: normal !important;
    font-size: 16px !important; }

.slider {
  width: 100% !important; }

.tooltip {
  display: none !important; }

.fr-wh-time-container .fr-wh-time {
  display: inline;
  width: auto;
  margin-left: 15px; }

.fr-wh-time-container .fr-wh-day {
  display: inline; }

.fr-wh-day {
  font-size: 20px;
  font-weight: normal;
  margin-left: -15px; }

.fr-wh-dayoff {
  font-size: 20px;
  font-weight: normal;
  margin-left: -15px;
  margin-bottom: 0; }

.fr-week-day {
  margin-bottom: 30px; }

.fr-wh-slider span.ui-slider-handle.ui-corner-all.ui-state-default {
  height: 28px;
  width: 20px;
  line-height: 70px;
  text-align: center;
  margin: -5px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  outline: none; }

.fr-wh-btn-container {
  position: relative !important;
  margin: 15px; }

textarea.form-control.fr-textarea-as-input {
  height: 140px;
  resize: vertical; }
  textarea.form-control.fr-textarea-as-input.affiliate {
    height: 60px; }

.hide-only-if-collapsed {
  display: block; }

.show-only-if-collapsed {
  display: none; }

.fr-card-container {
  margin-top: 15px; }

.fr-card {
  margin-bottom: 15px;
  margin-left: -15px;
  margin-right: -15px; }

.fr-card-addartist {
  text-align: center;
  padding-top: 100px;
  font-size: 40px; }

.fr-card-info, .fr-card-info-detail {
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 100%;
  text-align: center; }

.fr-card-info-detail {
  bottom: 5px; }

.fr-card-title, .fr-card-title-main {
  color: #ffffff;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  padding: 0 5px 15px 5px;
  overflow: hidden;
  text-overflow: ellipsis; }

@media (max-width: 769px) {
  .fr-card-title-main {
    padding-bottom: 0; } }

.fr-card-artist-title {
  color: #ffffff;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  padding: 0 5px 5px 5px;
  overflow: hidden;
  text-overflow: ellipsis; }

.fr-card-artist-subtitle {
  color: #ffffff;
  font-weight: normal;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  padding-bottom: 15px; }

.fr-card-select {
  width: 100%; }
  .fr-card-select > option {
    padding: 4px 7px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }

.fr-price-range {
  position: absolute;
  top: 25px;
  left: 22px;
  font-size: 25px;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  color: #ffffff; }

.fr-bold-dollars {
  font-weight: bold; }

.fr-card-salontitle {
  color: #ffffff;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  font-size: larger;
  padding-bottom: 15px; }

.fr-card-btn-bottom {
  margin: 0 5px 15px 5px; }

.fr-card-btn__item {
  margin: 0 5px 0 5px; }

.fr-card-btn__promo {
  margin-right: 0;
  margin-bottom: 10px; }

.fr-double-photo {
  position: absolute;
  top: 7px;
  left: 7px;
  height: 34px;
  width: 40px;
  background: url("img/double-photo.svg") no-repeat top left;
  background-size: 40px 34px;
  z-index: 100; }

.fr-profile__title {
  display: inline-block;
  margin-top: 19px;
  font-size: 30px;
  margin-bottom: 5px; }
  @media screen and (max-width: 641px) {
    .fr-profile__title {
      font-size: 18px; } }
  .fr-profile__title-photo, .fr-profile__title-photo-static {
    margin-top: 19px;
    margin-bottom: 5px;
    font-size: 30px;
    text-align: center; }
    @media screen and (max-width: 769px) {
      .fr-profile__title-photo, .fr-profile__title-photo-static {
        text-align: left;
        margin-top: 10px;
        font-size: 18px; } }
  .fr-profile__title-photo-static {
    text-align: left; }

.fr-tab-content {
  margin-top: 30px;
  padding-left: 0;
  padding-right: 30px;
  width: auto; }
  @media screen and (max-width: 768px) {
    .fr-tab-content {
      padding-right: 0; } }

.fr-address-edit-form {
  margin-bottom: 30px;
  float: left;
  background-color: #f3f3f3;
  animation: fadeInOpacity 0.5s ease-in 1;
  -webkit-animation: fadeInOpacity 0.5s ease-in 1;
  -moz-animation: fadeInOpacity 0.5s ease-in 1; }

.address__autocomplete {
  margin-top: -5px;
  padding: 5px 15px;
  border: 1px solid #cccccc;
  border-top: none;
  width: 100%;
  z-index: 200 !important; }

.fr-gallery-main {
  margin-top: 113px; }
  @media screen and (max-width: 767px) {
    .fr-gallery-main {
      margin-top: 51px; } }

.fr-item-list {
  position: relative;
  min-height: 130px; }
  .fr-item-list .fr-card-btn-bottom {
    margin-bottom: 0; }

.fr-item-list > .row {
  overflow: hidden;
  margin-top: 0; }

.fr-gallery-form-add label {
  font-size: 16px;
  margin-bottom: 15px !important;
  padding-right: 15px;
  width: 20%; }

.fr-gallery-form-title {
  margin: -20px 0 25px 15px; }

.fr-gallery-filter {
  padding-bottom: 15px; }

.fr-gallery-form-add .form-control > option {
  padding: 2px; }

.fr-gallery-form-add .form-control {
  width: 80%;
  margin-bottom: 15px; }
  @media (max-width: 769px) {
    .fr-gallery-form-add .form-control {
      width: 100%; } }
  .fr-gallery-form-add .form-control.brand-input {
    width: 100%; }
    @media (max-width: 769px) {
      .fr-gallery-form-add .form-control.brand-input {
        margin-bottom: 0; } }

.fr-violation-gallery-image {
  margin: 15px 0 15px 60px; }

.fr-violation-gallery-select {
  margin: 0 0 15px 50px; }
  @media (max-width: 769px) {
    .fr-violation-gallery-select {
      margin: 0 0 15px -15px; } }

.drop-crop button {
  margin: 5px 0; }

.fr-drop-crop {
  border: 1px solid #cccccc;
  border-radius: 4px; }

.fr-drop-crop-reject {
  border: 2px solid #e50d1c;
  border-radius: 4px; }

.fr-drop-crop-reject-badge {
  font-size: 16px;
  background-color: #e8b7d5; }

.fr-drop-crop-title {
  border-radius: 4px;
  text-align: center; }

.fr-glyphicon > .glyphicon {
  font-size: 24px;
  color: #e6e6e6;
  margin-right: 8px; }
  .fr-glyphicon > .glyphicon:hover, .fr-glyphicon > .glyphicon:focus {
    color: #46aae6;
    cursor: pointer; }
  .fr-glyphicon > .glyphicon.glyphicon-refresh {
    margin: 5px 15px 0 0; }
    @media screen and (max-width: 768px) {
      .fr-glyphicon > .glyphicon.glyphicon-refresh {
        margin: 15px 15px 0 0; } }
  .fr-glyphicon > .glyphicon.glyphicon-refresh__gallery {
    margin: 5px 15px 0 15px; }
    @media screen and (max-width: 1024px) {
      .fr-glyphicon > .glyphicon.glyphicon-refresh__gallery {
        margin: 15px 15px 0 0; } }
  .fr-glyphicon > .glyphicon.glyphicon-trash {
    color: #e64650;
    margin: 5px 15px 0 -15px; }
    .fr-glyphicon > .glyphicon.glyphicon-trash:hover, .fr-glyphicon > .glyphicon.glyphicon-trash:focus {
      color: #e50d1c;
      cursor: pointer; }
  .fr-glyphicon > .glyphicon.glyphicon-search {
    margin-right: 0; }
  .fr-glyphicon > .glyphicon.glyphicon-chevron-up {
    padding-left: 5px;
    margin-right: 0;
    font-size: 16px; }

.fr-glyphicon__gallery {
  top: 0; }

.fr-artist-form-title {
  margin: -20px 0 0 15px; }

.fr-fav-title {
  display: block;
  font-size: 18px;
  color: #46aae6; }

.fr-apt-left-container {
  display: inline-block; }

.fr-appointments__filter {
  float: right;
  display: inline-block;
  padding: 5px 5px 0 5px;
  margin-top: 17px;
  opacity: 0.99; }
  @media only screen and (max-width: 769px) {
    .fr-appointments__filter {
      float: none !important;
      margin-top: 0; } }
  .fr-appointments__filter .input-group-btn {
    z-index: 100; }
    .fr-appointments__filter .input-group-btn .fr-apt-btn-reset {
      position: absolute;
      right: 38px;
      top: 0;
      border-radius: 0 3px 3px 0 !important;
      padding: 3px 6px 2px 6px; }
    .fr-appointments__filter .input-group-btn .glyphicon-search {
      margin-top: -3px;
      color: #ffffff; }
      .fr-appointments__filter .input-group-btn .glyphicon-search:hover, .fr-appointments__filter .input-group-btn .glyphicon-search:focus, .fr-appointments__filter .input-group-btn .glyphicon-search:active {
        color: #0d96e5; }
    .fr-appointments__filter .input-group-btn .glyphicon-remove {
      top: 2px; }

@media (max-width: 769px) {
  .fr-appointments__filter {
    margin: 0;
    float: none;
    padding-top: 0;
    padding-left: 0; }
    .fr-appointments__filter .fr-apt-btn-reset {
      right: 46px;
      border-radius: 0 3px 3px 0;
      padding: 4px 6px 3px; }
      .fr-appointments__filter .fr-apt-btn-reset .glyphicon-remove {
        display: inline-block; } }

.fr-btn-table {
  margin: 5px 5px 0 0; }

.fr-apt-btn__action {
  margin: 0 4px;
  padding: 4px 10px; }

.fr-apt-btn__paid {
  margin: 0 4px;
  padding: 4px 12px; }

.fr-book-btn {
  padding: 4px 12px;
  margin: 0 5px; }

.fr-pers-apt__action {
  margin: 5px 2px; }

.fr-apt-inv__action {
  margin-top: 5px; }

.fr-apt-lbl {
  font-size: 20px;
  margin-left: -15px; }

.fr-apt-mt {
  margin-top: 2px; }

.fr-apt-line:before {
  content: "";
  display: block;
  border-top: 4px solid #46aae6; }

.fr-apt-status, .fr-apt-upcoming, .fr-apt-done, .fr-apt-canceled, .fr-apt-upcomingCanceled, .fr-apt-pending, .fr-apt-no-show {
  margin-top: 2px;
  padding-top: 5px;
  padding-bottom: 5px; }

.fr-apt-upcoming {
  background-color: #46e6aa;
  color: #ffffff; }

.fr-apt-done {
  background-color: #d8d8d8;
  color: #464646; }

.fr-apt-canceled, .fr-apt-upcomingCanceled {
  background-color: #e64650;
  color: #ffffff; }

.fr-apt-pending {
  background-color: #e8b7d5;
  color: #ffffff; }

.fr-apt-span__canceled {
  background-color: #ffffff;
  color: #e64650;
  font-size: 36px; }

.fr-apt-span__pending {
  background-color: #ffffff;
  color: #e8b7d5;
  font-size: 36px; }

.fr-apt-span__done {
  background-color: #ffffff;
  color: #464646;
  font-size: 36px; }

.fr-apt-span__upcoming {
  background-color: #ffffff;
  color: #46e6aa;
  font-size: 36px; }

.fr-apt-span__no-show {
  background-color: #ffffff;
  color: #8246e6;
  font-size: 36px; }

.badge {
  font-size: 14px;
  font-weight: 500; }

.notification-badge {
  position: absolute;
  top: 12px;
  right: 5px;
  height: 20px;
  width: 20px; }
  .notification-badge.sent {
    background: url("img/badge-message-sent.svg") no-repeat top left;
    background-size: 20px 20px; }
  .notification-badge.history {
    background: url("img/badge-message-history.svg") no-repeat top left;
    background-size: 20px 20px; }
  .notification-badge.confirmed {
    background: url("img/badge-message-confirmed.svg") no-repeat top left;
    background-size: 20px 20px; }
  @media screen and (max-width: 769px) {
    .notification-badge {
      top: 10px; } }

.fr-apt-no-show {
  background-color: #8246e6;
  color: #ffffff; }

.fr-apt-modal-header {
  -moz-border-radius: 6px 6px 0 0;
  -webkit-border-radius: 6px 6px 0 0;
  border-radius: 6px 6px 0 0; }

.fr-apt-modal-footer {
  text-align: center; }

.fr-apt-modal .modal-body {
  max-height: 200px; }

.fr-table-centred {
  display: flex;
  align-items: center; }
  .fr-table-centred__bordered {
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #cccccc;
    min-height: 40px; }
    .fr-table-centred__bordered.services {
      padding-top: 2px;
      padding-bottom: 2px; }
      @media screen and (max-width: 768px) {
        .fr-table-centred__bordered.services {
          padding-top: 0;
          padding-bottom: 0; } }
  .fr-table-centred__violations {
    align-items: baseline;
    padding-top: 5px; }
  .fr-table-centred__btn-toolbar {
    margin-top: -5px; }

.fr-apt-cell {
  padding: 5px 15px 2px 15px; }

.fr-apt-date-cell {
  padding: 5px 5px 2px 15px; }

@media (max-width: 769px) {
  .fr-table-centred {
    display: block; }
    .fr-table-centred__bordered {
      display: flex; } }

@media (max-width: 991px) {
  .fr-address-zip {
    margin-bottom: 15px; } }

.fr-map-wide {
  width: auto;
  height: 500px; }

.fr-promo-location {
  position: relative; }

.fr-promo-location-img {
  background-color: red;
  margin: 15px;
  position: absolute;
  z-index: 1000;
  left: 0; }

.fr-promo-location-adr {
  position: absolute;
  right: 0;
  width: 40%;
  max-height: 300px;
  margin: 0;
  padding: 15px 0 0;
  overflow-y: auto;
  border-bottom-left-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  z-index: 1000; }
  .fr-promo-location-adr p {
    margin-top: 3px;
    padding-left: 5px; }
  .fr-promo-location-adr .btn-link:focus {
    color: #464646; }
  .fr-promo-location-adr .btn-link.disabled {
    color: #cccccc;
    pointer-events: none; }
  @media screen and (max-width: 769px) {
    .fr-promo-location-adr {
      padding: 15px 0 8px;
      width: 50%; } }

.fr-promo-title h1 {
  font-family: "Roboto", Helvetica, sans-serif;
  font-weight: 300; }

.items-filter-title {
  color: #464646;
  font-size: 36px; }

.fr-main-tabs {
  font-family: "Roboto", Helvetica, sans-serif;
  cursor: pointer; }
  .fr-main-tabs .datepicker_wrapper {
    margin-top: 13px;
    position: absolute; }
  .fr-main-tabs li {
    height: inherit;
    padding: 0 1px;
    margin-bottom: 0; }
    .fr-main-tabs li select option {
      font-size: 16px; }
    .fr-main-tabs li a, .fr-main-tabs li > div {
      height: inherit; }
      .fr-main-tabs li a:hover, .fr-main-tabs li > div:hover {
        background-color: #e6e6e6; }
    .fr-main-tabs li.active a, .fr-main-tabs li.active > div {
      background-color: #cccccc; }
      .fr-main-tabs li.active a:hover, .fr-main-tabs li.active a:focus, .fr-main-tabs li.active > div:hover, .fr-main-tabs li.active > div:focus {
        background-color: #cccccc; }
    .fr-main-tabs li > a[aria-expanded="true"] {
      background-color: #46aae6; }
      .fr-main-tabs li > a[aria-expanded="true"]:hover, .fr-main-tabs li > a[aria-expanded="true"]:focus {
        background-color: #46aae6;
        border-color: #46aae6; }
    .fr-main-tabs li > a:hover, .fr-main-tabs li > a:focus {
      background-color: #46aae6; }
    .fr-main-tabs li > a .active {
      background-color: #46aae6; }
    @media screen and (max-width: 769px) {
      .fr-main-tabs li {
        padding: 0; } }
  .fr-main-tabs .fr-main-tabs-fixed {
    height: 135px; }

.main-tab:hover .main-tab__item {
  background-color: #e6e6e6; }

.main-tab__item {
  color: #464646;
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0.7);
  margin-top: 2px;
  font-family: "Roboto", Helvetica, sans-serif;
  font-size: 18px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  position: relative;
  display: block;
  padding: 8px 15px 50px;
  outline: none; }
  .main-tab__item.active {
    background-color: #e6e6e6 !important; }

.main-tab .dropdown-menu li a {
  font-size: 16px; }

@media screen and (max-width: 768px) {
  .main-tab__item {
    font-family: "Roboto", Helvetica, sans-serif;
    padding: 6px 15px 6px 15px; } }

@media screen and (max-width: 441px) {
  .main-tab__item {
    font-family: "Roboto Condensed", Cambria, Helvetica, sans-serif;
    font-weight: bold;
    text-align: center;
    padding: 6px 5px 6px 5px; } }

@media (max-width: 991px) {
  .show-only-if-collapsed {
    display: block; }
  .hide-only-if-collapsed {
    display: none; } }

@media (max-width: 991px) {
  .navbar-header {
    float: none; }
  .navbar-toggle {
    display: block;
    margin-right: 0;
    background-color: #e6e6e6; }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1); }
  .navbar-collapse.collapse {
    display: none !important; }
  .navbar-nav {
    float: none !important;
    margin: 0 -15px; }
  .navbar-nav > li {
    float: none; }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px; }
  .navbar-nav > li > button {
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    background-color: #ffffff; }
    .navbar-nav > li > button:hover {
      background-color: #ffffff; }
  .navbar-text {
    float: none;
    margin: 15px 0; }
  /* since 3.1.0 */
  .navbar-collapse.collapse.in {
    display: block !important; }
  .collapsing {
    overflow: hidden !important; } }

#g-recaptcha,
#g-recaptcha-contact,
#g-recaptcha-affiliate {
  transform: scale(0.89);
  -webkit-transform: scale(0.89);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0; }
  @media screen and (max-width: 769px) {
    #g-recaptcha,
    #g-recaptcha-contact,
    #g-recaptcha-affiliate {
      transform: scale(0.8);
      -webkit-transform: scale(0.8); } }

.items-filters {
  margin: 0; }

.fr-filter-header {
  padding: 15px; }

.fr-filter-header:nth-child(1) {
  background-color: #46aae6; }

.fr-filter-header:nth-child(2) {
  background-color: #cccccc; }

.fr-filter-header:nth-child(3) {
  background-color: #cccccc; }

.fr-filter-header:nth-child(4) {
  background-color: #cccccc; }

.fr-filter-panel {
  background-color: #e6e6e6; }
  .fr-filter-panel.services-filter > div:first-of-type > div {
    margin-top: 10px; }
  .fr-filter-panel.services-filter > div:last-of-type > div:last-of-type:not(.services) > div {
    display: inline-block;
    min-width: 135px; }

.fr-violation {
  text-align: left;
  width: 100%;
  margin: 0 0 15px -15px; }

.fr-violation__popover {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  left: 10px;
  width: calc(100% - 20px) !important;
  margin-top: 0; }
  .fr-violation__popover .popover-content {
    text-align: left;
    padding: 15px 5px 0 5px; }

li.fr-error.fr-alert-msg {
  padding: 0;
  margin-right: 0;
  font-size: 14px;
  color: #e64650; }

.fr-grid-violation {
  width: 100%;
  margin: 7px 0 7px -15px; }

.error-message {
  color: #e64650;
  padding: 6px; }

.info-message {
  margin-top: 10px;
  font-weight: bold;
  color: #46e6aa; }

.no-fav-message {
  line-height: 200px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  width: 100%; }

.profile-message {
  padding: 0 15px;
  font-size: 18px; }

.loading-message {
  display: block;
  padding: 15px;
  text-align: center; }

.fr-clr {
  clear: both;
  content: '';
  display: block; }

.fr-capitalize {
  text-transform: capitalize; }

.fr-txt-red {
  color: #e64650; }

.fr-border-red {
  border: 1px solid #e64650; }

.fr-uppercase {
  text-transform: uppercase; }

.fr-inline {
  display: inline; }

.fr-block {
  display: block; }

.fr-mb {
  margin-bottom: 15px; }

.fr-mb2 {
  margin-bottom: 30px; }

.fr-ml {
  margin-left: 15px; }

.fr-mr {
  margin-right: 15px; }

.fr-mt0 {
  margin-top: 0; }

.fr-mt {
  margin-top: 15px; }

.fr-fl {
  float: left; }

.fr-fr {
  float: right; }

.fr-mb-min {
  margin-bottom: 2px; }

.fr-mt-ng {
  margin-top: -15px; }

@media screen and (max-width: 769px) {
  .fr-mt-ng-xs {
    margin-top: -15px; } }

.fr-ml-xs {
  margin-left: 15px; }
  @media screen and (max-width: 768px) {
    .fr-ml-xs {
      margin-left: 0; } }

.fr-pl0 {
  padding-left: 0; }

@media screen and (max-width: 961px) {
  .fr-pl0-xs {
    padding-left: 0; } }

@media screen and (min-width: 992px) {
  .fr-pl0-lg {
    padding-left: 0; } }

.fr-pr0 {
  padding-right: 0; }

.fr-pr-md {
  padding-right: 15px; }
  @media screen and (max-width: 992px) {
    .fr-pr-md {
      padding-right: 0; } }

.fr-pb-xs {
  padding-bottom: 15px; }
  @media screen and (max-width: 768px) {
    .fr-pb-xs {
      padding-bottom: 0; } }

.fr-ml-ng {
  margin-left: -15px; }
  @media screen and (max-width: 768px) {
    .fr-ml-ng {
      margin-left: 0; } }

.fr-spacer {
  height: 33px;
  width: 100%;
  font-size: 0;
  margin: 0;
  padding: 0;
  border: 0;
  display: block; }

.fr-span-message {
  font-size: 16px;
  line-height: 5; }

.fr-text-center {
  text-align: center; }

.fr-text-right {
  text-align: right; }

.fr-text-left {
  text-align: left; }

@media screen and (min-width: 992px) {
  .fr-text-lg-left {
    text-align: left; } }

@media screen and (max-width: 991px) {
  .fr-text-xs-left {
    text-align: left; } }

.fr-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis; }

.fr-max-width {
  max-width: 100%; }

@media (min-width: 480px) {
  html .main-content {
    min-height: 200px; } }

@media (min-width: 768px) {
  html .container {
    width: 100%; }
  html .main-content {
    min-height: 480px; } }

@media (min-width: 992px) {
  html .container {
    width: 100%; }
  html .main-content {
    min-height: 700px; } }

@media (min-width: 1200px) {
  html .container {
    width: 1170px; }
    html .container.full-width {
      width: 100%;
      padding: 0 30px; } }

html .grid.row {
  margin-right: -15px;
  margin-left: -15px; }
  html .grid.row .col-md-3 {
    margin: 1px 0; }
    html .grid.row .col-md-3 .fr-card {
      margin-left: -14px;
      margin-bottom: 0;
      margin-right: -14px;
      border-radius: 3px;
      overflow: hidden; }
      html .grid.row .col-md-3 .fr-card img {
        width: 100%;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s; }
        html .grid.row .col-md-3 .fr-card img:hover {
          transform: scale(1.05); }

html .services-filter {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  max-height: 500px; }
  html .services-filter.hide {
    display: block !important;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    opacity: 1; }

html .alert-wrapper {
  float: left; }
  html .alert-wrapper .alert {
    text-align: center;
    margin: 20px 15px;
    display: inline-block;
    padding: 6px 10px 6px 10px; }
  @media (max-width: 480px) {
    html .alert-wrapper {
      width: 25%; }
      html .alert-wrapper .alert {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
        margin-bottom: 5px;
        margin-top: 7px; } }

html .fr-header-wrap {
  position: static;
  -webkit-transition: all 0.5s ease-in;
  -ms-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in; }
  html .fr-header-wrap.fixed {
    width: 100%;
    left: 0;
    top: 0;
    position: fixed;
    z-index: 101;
    animation: smoothScroll 1s forwards; }

.form-disclaimer {
  color: #333;
  margin-bottom: 10px; }
  .form-disclaimer a {
    color: #46aae6; }

.fr-public {
  position: absolute;
  top: 7px;
  left: 7px; }

.fr-is-public {
  background-color: #46e6aa; }

.fr-not-public {
  background-color: #e8b7d5; }

.fr-rounded {
  border-radius: 4px !important; }

.fr-red-badge {
  height: 25px;
  width: 25px;
  background: url("img/badge-red.svg") no-repeat top left;
  background-size: 25px 25px; }
  .fr-red-badge.small-badge {
    height: 20px;
    width: 20px;
    margin-bottom: 5px;
    background: url("img/badge-red.svg") no-repeat top left;
    background-size: 20px 20px; }
  @media screen and (max-width: 769px) {
    .fr-red-badge {
      height: 20px;
      width: 20px;
      background: url("img/badge-red.svg") no-repeat top left;
      background-size: 20px 20px; } }

.fr-badge__header {
  position: absolute;
  top: 7px;
  left: -10px; }

.fr-orange-badge {
  height: 25px;
  width: 25px;
  background: url("img/badge-orange.svg") no-repeat top left;
  background-size: 25px 25px; }
  .fr-orange-badge.small-badge {
    height: 20px;
    width: 20px;
    margin-bottom: 5px;
    background: url("img/badge-orange.svg") no-repeat top left;
    background-size: 20px 20px; }
  @media screen and (max-width: 769px) {
    .fr-orange-badge {
      height: 20px;
      width: 20px;
      background: url("img/badge-red.svg") no-repeat top left;
      background-size: 20px 20px; } }

.personal-profile__billing-title {
  margin-top: 0;
  font-size: 26px;
  padding-bottom: 10px; }
  @media screen and (max-width: 991px) {
    .personal-profile__billing-title {
      font-size: 22px;
      padding-bottom: 15px; } }

.subscription-profile__section-separator {
  border-bottom: 1px dashed #cccccc;
  padding-bottom: 5px; }

.subscription-profile__plan-title {
  margin-top: 0;
  font-size: 26px;
  padding-bottom: 10px; }
  @media screen and (max-width: 991px) {
    .subscription-profile__plan-title {
      font-size: 22px; } }

.subscription-profile__spinner {
  position: relative;
  top: -13px;
  right: 15px; }
  @media screen and (max-width: 991px) {
    .subscription-profile__spinner {
      left: 165px; } }

.subscription-status, .transaction-status {
  font-size: 16px; }
  .subscription-status__success, .transaction-status__success {
    margin: 0 15px 0 15px;
    background-color: #46e6aa; }
  .subscription-status__danger, .transaction-status__danger {
    margin: 0 15px 0 15px;
    background-color: #e64650; }
  .subscription-status__default, .transaction-status__default {
    background-color: #46e6aa; }

@media screen and (max-width: 768px) {
  .subscription-status__success, .subscription-status__danger {
    margin: 0 0 0 6px; } }

.transaction-status__success {
  margin: 0;
  background-color: #46e6aa; }

.transaction-status__fail {
  margin: 0;
  background-color: #e64650; }

.transaction-status__other {
  margin: 0;
  background-color: #e6aa46; }

.bank-card-profile__container, .my-cards-cnt__container, .subscription-profile__container {
  padding-left: 15px;
  padding-right: 15px;
  margin: 15px -15px 15px -15px; }

.bank-card-profile__payment-sources, .my-cards-cnt__payment-sources, .subscription-profile__payment-sources {
  width: 100%;
  padding-right: 15px; }
  @media screen and (max-width: 992px) {
    .bank-card-profile__payment-sources, .my-cards-cnt__payment-sources, .subscription-profile__payment-sources {
      padding-right: 0; } }
  .bank-card-profile__payment-sources-table, .my-cards-cnt__payment-sources-table, .subscription-profile__payment-sources-table {
    display: flex;
    align-items: center;
    padding-bottom: 4px;
    padding-top: 4px; }

.bank-card-profile__button-toolbar, .my-cards-cnt__button-toolbar, .subscription-profile__button-toolbar {
  float: right; }
  @media screen and (max-width: 480px) {
    .bank-card-profile__button-toolbar, .my-cards-cnt__button-toolbar, .subscription-profile__button-toolbar {
      float: none; } }

.bank-card-profile__banner, .my-cards-cnt__banner, .subscription-profile__banner {
  background-color: #e6e6e6;
  border-radius: 8px;
  padding: 15px; }

.bank-card-profile__btn-subscribe, .my-cards-cnt__btn-subscribe, .subscription-profile__btn-subscribe {
  float: right; }
  @media screen and (max-width: 991px) {
    .bank-card-profile__btn-subscribe, .my-cards-cnt__btn-subscribe, .subscription-profile__btn-subscribe {
      margin-bottom: 15px;
      float: left; } }

.bank-card-profile__btn-upgrade, .my-cards-cnt__btn-upgrade, .subscription-profile__btn-upgrade {
  margin-right: 15px; }
  @media screen and (max-width: 991px) {
    .bank-card-profile__btn-upgrade, .my-cards-cnt__btn-upgrade, .subscription-profile__btn-upgrade {
      margin-bottom: 15px;
      float: left; } }

.bank-card-profile__title, .my-cards-cnt__title, .subscription-profile__title {
  margin-top: 0;
  font-size: 30px;
  font-weight: bold; }
  @media screen and (max-width: 991px) {
    .bank-card-profile__title, .my-cards-cnt__title, .subscription-profile__title {
      font-size: 22px; } }

.bank-card-profile .fr-btn-table, .my-cards-cnt .fr-btn-table, .subscription-profile .fr-btn-table {
  margin-bottom: 5px; }
  @media screen and (max-width: 480px) {
    .bank-card-profile .fr-btn-table, .my-cards-cnt .fr-btn-table, .subscription-profile .fr-btn-table {
      margin-bottom: 0; } }

.bank-card-profile .fr-link-delete, .my-cards-cnt .fr-link-delete, .subscription-profile .fr-link-delete {
  padding-left: 0; }
  @media screen and (max-width: 991px) {
    .bank-card-profile .fr-link-delete, .my-cards-cnt .fr-link-delete, .subscription-profile .fr-link-delete {
      margin-top: 0; } }

.bank-card-profile .expired, .my-cards-cnt .expired, .subscription-profile .expired {
  color: #e64650; }

.bank-card-profile .profile-status__success, .my-cards-cnt .profile-status__success, .subscription-profile .profile-status__success {
  margin: 0 15px 0 15px;
  background-color: #46e6aa; }

.bank-card-profile .profile-status__danger, .my-cards-cnt .profile-status__danger, .subscription-profile .profile-status__danger {
  margin: 0 15px 0 15px;
  background-color: #e64650; }

.bank-card-profile .profile-status__default, .my-cards-cnt .profile-status__default, .subscription-profile .profile-status__default {
  background-color: #46e6aa; }

.bank-card-profile .profile-status__pending, .my-cards-cnt .profile-status__pending, .subscription-profile .profile-status__pending {
  margin: 0 15px 0 15px;
  background-color: #e6aa46; }

.bank-card-profile .bank-card-green, .my-cards-cnt .bank-card-green, .subscription-profile .bank-card-green {
  float: left;
  height: 22px;
  width: 33px;
  background: url("img/card-green.svg") no-repeat top left;
  background-size: 33px 22px; }

.bank-card-profile .bank-card-gray, .my-cards-cnt .bank-card-gray, .subscription-profile .bank-card-gray {
  float: left;
  height: 22px;
  width: 33px;
  background: url("img/card-gray.svg") no-repeat top left;
  background-size: 33px 22px; }

.bank-card-profile .bank-card-red, .my-cards-cnt .bank-card-red, .subscription-profile .bank-card-red {
  float: left;
  height: 22px;
  width: 33px;
  background: url("img/card-red.svg") no-repeat top left;
  background-size: 33px 22px; }

.bank-card-profile .bank-green, .my-cards-cnt .bank-green, .subscription-profile .bank-green {
  float: left;
  height: 22px;
  width: 33px;
  background: url("img/bank-green.svg") no-repeat top left;
  background-size: 33px 22px; }

.bank-card-profile .bank-gray, .my-cards-cnt .bank-gray, .subscription-profile .bank-gray {
  float: left;
  height: 22px;
  width: 33px;
  background: url("img/bank-gray.svg") no-repeat top left;
  background-size: 33px 22px; }

.bank-card-profile .payment-sources, .my-cards-cnt .payment-sources, .subscription-profile .payment-sources {
  padding-right: 15px; }
  @media screen and (max-width: 991px) {
    .bank-card-profile .payment-sources, .my-cards-cnt .payment-sources, .subscription-profile .payment-sources {
      padding-right: 0; } }

.dropdown-default,
.dropdown-default__selected,
.dropdown-default--menu {
  width: 100%;
  text-align: left; }
  .dropdown-default li a,
  .dropdown-default__selected li a,
  .dropdown-default--menu li a {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-size: 16px; }

.dropdown-default__selected,
.dropdown-gallery__selected,
.dropdown-service-form__selected,
.dropdown-book-clients__selected,
.dropdown-gallery-filter__selected,
.dropdown-book-apt__selected {
  padding-right: 15px;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis; }
  .dropdown-default__selected:before,
  .dropdown-gallery__selected:before,
  .dropdown-service-form__selected:before,
  .dropdown-book-clients__selected:before,
  .dropdown-gallery-filter__selected:before,
  .dropdown-book-apt__selected:before {
    content: "\E252";
    font-family: "Glyphicons Halflings";
    color: #777;
    position: absolute;
    right: 7px; }
  .dropdown-default__selected > .caret,
  .dropdown-gallery__selected > .caret,
  .dropdown-service-form__selected > .caret,
  .dropdown-book-clients__selected > .caret,
  .dropdown-gallery-filter__selected > .caret,
  .dropdown-book-apt__selected > .caret {
    display: none; }

.dropdown-default--menu {
  max-height: 40vh;
  overflow-y: scroll;
  text-overflow: ellipsis;
  overflow-x: hidden; }
  @media screen and (max-width: 768px) {
    .dropdown-default--menu {
      max-height: 80vh; } }

.dropdown-menu li a {
  font-size: 16px;
  text-overflow: ellipsis;
  overflow-x: hidden; }

.dropdown-gallery {
  width: 80%;
  margin-bottom: 15px;
  text-align: left; }

.dropdown-gallery__selected,
.dropdown-gallery--menu,
.dropdown-book-apt__selected,
.dropdown-book-apt--menu,
.dropdown-gallery--menu,
.dropdown-service-form__selected,
.dropdown-service-form__disabled,
.dropdown-service-form--menu,
.dropdown-book-clients__selected,
.dropdown-gallery-filter__selected,
.dropdown-gallery-filter--menu,
.dropdown-book-clients--menu {
  width: 100%;
  text-align: left;
  color: #555; }

.dropdown-book-apt,
.dropdown-book-clients {
  width: 100%;
  margin-bottom: 15px;
  text-align: left; }

.dropdown-book-apt--menu,
.dropdown-book-clients--menu {
  height: auto;
  max-height: 200px;
  overflow-x: hidden; }

.dropdown-book-clients--menu {
  position: relative; }

.dropdown-service-form {
  width: 60%;
  text-align: left;
  color: #555; }

.dropdown-gallery-filter {
  width: 108%; }
  @media screen and (max-width: 769px) {
    .dropdown-gallery-filter {
      width: 100%; } }

.dropdown-gallery--menu > li > a {
  overflow: hidden;
  text-overflow: ellipsis; }

.dropdown-brand-selector--menu {
  display: block;
  max-height: 300px;
  overflow: auto;
  margin: -15px 0 15px;
  width: 100%; }
  @media screen and (max-width: 769px) {
    .dropdown-brand-selector--menu {
      margin-top: 0;
      max-height: 150px; } }

.find-client-list {
  display: block;
  width: 100%;
  overflow-x: hidden;
  max-height: 200px;
  position: relative;
  margin-top: -15px;
  margin-bottom: 15px; }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
html .form-group .main-selects, html .form-group .form-control {
  margin-bottom: 10px; }

html .form-group .form-control {
  font-size: 16px; }
  @media only screen and (max-width: 769px) {
    html .form-group .form-control {
      height: 40px;
      font-size: 16px; } }

html .form-group .form-control:disabled {
  -webkit-text-fill-color: #555; }

html .hint-msg {
  margin-top: -12px;
  font-size: 12px; }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/0eC6fl06luXEYWpBSJvXCBJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Fl4y0QdOxyyTHEGMXX8kcRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Roboto Condensed';
  src: url("fonts/roboto-condensed-light/RobotoCondensed-Light.woff") format("woff"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.ttf") format("truetype"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.svg#RobotoCondensed-Light") format("svg");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.eot");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.woff2") format("woff2"), url("fonts/roboto-condensed-light/roboto-condensed.light.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("fonts/glyphicons-halflings-regular.woff") format("woff"), url("fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("fonts/glyphicons-halflings-regular.svg#Glyphicons Halflings") format("svg");
  src: url("fonts/glyphicons-halflings-regular.eot");
  src: url("fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@keyframes fadeInOpacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeInRight {
  0% {
    -webkit-transform: translateX(75%);
    transform: translateX(75%);
    visibility: visible; }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInDown {
  0% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes slideDown {
  0% {
    top: calc(100% - 15px);
    height: 0;
    opacity: 1; }
  100% {
    top: 15px;
    height: calc(100% - 15px);
    opacity: 0; } }

@keyframes slideUp {
  0% {
    height: 15px;
    opacity: 1; }
  100% {
    height: calc(100% - 15px);
    opacity: 0; } }

html .modal {
  z-index: 1090; }

html .modal-open {
  padding-right: 0 !important; }
  @media screen and (max-width: 769px) {
    html .modal-open {
      overflow: hidden;
      position: fixed; } }

html .top-align {
  margin-top: -32px; }

html .image-modal {
  max-height: 98vh; }
  html .image-modal .modal-content {
    max-height: 98vh; }
  html .image-modal .img-responsive {
    max-height: calc(98vh - 90px);
    background: 50%/cover fixed no-repeat;
    background-image: attr(data-img); }
  html .image-modal .img-responsive--cover {
    max-height: calc(98vh - 90px);
    border-bottom: 4px solid #464646;
    background: 50%/cover fixed no-repeat;
    background-image: attr(data-img); }
  html .image-modal .img-slider {
    position: absolute;
    top: 15px;
    height: 5px;
    width: calc(100% - 1px);
    background-color: #f3f3f3;
    display: block;
    opacity: 0; }
  html .image-modal .slider-down {
    -webkit-animation: slideDown 1s ease-out 1;
    -moz-animation: slideDown 1s ease-out 1;
    -ms-animation: slideDown 1s ease-out 1;
    -o-animation: slideDown 1s ease-out 1;
    animation: slideDown 1s ease-out 1; }
  html .image-modal .slider-up {
    -webkit-animation: slideUp 1s ease-out 1;
    -moz-animation: slideUp 1s ease-out 1;
    -ms-animation: slideUp 1s ease-out 1;
    -o-animation: slideUp 1s ease-out 1;
    animation: slideUp 1s ease-out 1; }
  html .image-modal .modal-body .btn-default.fr-btn {
    margin-left: 15px; }
  html .image-modal .fr-card-info, html .image-modal .fr-card-info-detail {
    z-index: 8041; }

html .change-email-modal .modal-body {
  min-height: 110px; }

html .change-email-modal .fr-btn--clear.clear-button, html .change-password-modal .fr-btn--clear.clear-button {
  margin: 15px 0 0; }

html .change-email-modal .fr-btn, html .change-password-modal .fr-btn {
  margin: 15px 0 0; }

html .get-paid .modal-body {
  padding-bottom: 0; }

html .modal-content .btn, html .modal-content .close {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s; }

html .modal-content .datepicker_wrapper {
  top: -2px; }

html .modal-content .datepicker_wrapper.static .ui-corner-all {
  top: 0; }

html .forgot-modal {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

html .modal-header {
  padding-bottom: 0;
  background: #e6e6e6;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: none; }
  html .modal-header h2 {
    margin-top: 0;
    font-weight: 200;
    color: #ffffff; }
  html .modal-header p:last-of-type {
    margin-bottom: 0; }
  html .modal-header .close {
    margin-top: 6px; }
    html .modal-header .close:hover, html .modal-header .close:focus {
      outline: none; }
    html .modal-header .close span {
      font-size: 30px;
      color: rgba(0, 0, 0, 0.5);
      opacity: 1;
      display: block;
      width: 30px;
      height: 30px;
      line-height: 22px;
      text-align: center;
      padding-left: 15px; }
  html .modal-header .legal-header {
    float: left; }
  html .modal-header .btn-proceed {
    float: right; }
  html .modal-header.fr-unseen-apt-header {
    padding-bottom: 0; }
    @media screen and (max-width: 992px) {
      html .modal-header.fr-unseen-apt-header .table-header {
        padding-left: 8px; } }
  html .modal-header.image-fullscreen-modal__header {
    display: none; }
  @media screen and (max-width: 768px) {
    html .modal-header {
      padding: 15px 15px 0 15px; }
      html .modal-header h2 {
        font-size: 32px; }
      html .modal-header .close {
        margin-top: 5px; } }

html .book-modal .modal-header {
  padding-bottom: 10px; }

html .video-modal {
  width: 757px;
  max-width: 90%; }
  html .video-modal .modal-header {
    padding-top: 0; }
  html .video-modal .close-layer {
    position: absolute;
    z-index: 99;
    top: 20px;
    right: 15px;
    margin-right: -30px;
    margin-top: -30px;
    background-color: transparent;
    width: 45px;
    height: 38px;
    cursor: pointer;
    display: inline-block; }
  html .video-modal .close {
    position: absolute;
    top: 30px;
    right: 30px;
    margin-right: -30px;
    margin-top: -30px;
    background-color: #ffffff;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    opacity: 1;
    cursor: pointer;
    color: #999999;
    font-size: 28px;
    font-weight: bold;
    display: inline-block;
    line-height: 0; }
    html .video-modal .close span {
      margin-top: 4px;
      padding-left: 0; }

html .modal-body h2 {
  margin-top: 0; }

html .modal-body .form-control {
  margin-bottom: 15px; }
  html .modal-body .form-control.price-input {
    display: inline-block;
    width: 80%;
    margin-left: 5px; }
  html .modal-body .form-control.tax-input {
    display: inline-block;
    width: 75%;
    margin: -3px 5px 0 0;
    height: 26px;
    padding: 6px; }

html .modal-body input[type=number]::-webkit-inner-spin-button,
html .modal-body input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0; }

html .modal-body input[type=number] {
  -moz-appearance: textfield; }

html .modal-body .form-group {
  margin-bottom: 0; }

html .modal-body .btn {
  margin-left: 15px; }
  html .modal-body .btn.btn-danger {
    color: #ffffff;
    background-color: #e64650;
    border-color: #e64650; }
    html .modal-body .btn.btn-danger:hover {
      background-color: #e50d1c;
      border-color: #e50d1c; }
  html .modal-body .btn.btn-primary:hover {
    color: #ffffff;
    background-color: #286090;
    border-color: #286090; }
  html .modal-body .btn.btn-add {
    color: #ffffff;
    background-color: #46e6aa;
    border-color: #46e6aa; }
    html .modal-body .btn.btn-add:hover {
      background-color: #0de592;
      border-color: #0de592; }

html .modal-body .btn-toolbar > .btn, html .modal-body .btn-toolbar > .btn-group, html .modal-body .btn-toolbar > .input-group {
  margin-left: 0; }

html .modal-body .tip-selector {
  padding-left: 5px; }

html .modal-body .tip-selector-cnt.btn-select {
  color: #464646;
  background: #ffffff;
  margin-bottom: 15px; }
  html .modal-body .tip-selector-cnt.btn-select:active, html .modal-body .tip-selector-cnt.btn-select:focus {
    background-color: #46e6aa !important; }
  html .modal-body .tip-selector-cnt.btn-select:hover {
    background-color: #f3f3f3; }

html .modal-body .tip-selector-cnt.tip-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: 0;
  -moz-appearance: textfield; }
  html .modal-body .tip-selector-cnt.tip-input:focus {
    background-color: #46e6aa !important; }
  html .modal-body .tip-selector-cnt.tip-input:hover {
    background-color: #f3f3f3; }

html .modal-body .tip-selector-cnt.tip-sum-custom {
  display: inline-block;
  padding: 0 12px 0 12px; }
  html .modal-body .tip-selector-cnt.tip-sum-custom:active, html .modal-body .tip-selector-cnt.tip-sum-custom:focus {
    background-color: #46e6aa; }

html .modal-body .tip-selector-cnt .tip-selected {
  background-color: #46e6aa; }

html .modal-body .tip-sum {
  font-size: 16px; }
  html .modal-body .tip-sum__no-tip {
    font-size: 16px; }

html .modal-body .tip-selector-middle {
  display: block;
  min-height: 65px;
  margin-bottom: 10px;
  font-size: 14px; }

html .modal-body .price-span {
  text-align: right;
  padding-right: 0; }

html .modal-body .hr-line {
  border-top: 1px solid #cccccc;
  margin: 0 -15px 0 -15px;
  padding-top: 5px; }

html .modal-body .charges-panel {
  padding: 0; }
  html .modal-body .charges-panel .title {
    font-size: 16px;
    font-weight: 500;
    color: #333; }

html .modal-body .bonus-panel {
  padding: 5px 0 5px 0;
  font-weight: 500;
  color: #333;
  background-color: #f3f3f3; }
  html .modal-body .bonus-panel .title {
    font-weight: bold; }

html .modal-body .panel {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

html .modal-body .panel-group {
  margin-bottom: 5px; }

html .modal-body .panel-title {
  font-weight: bold;
  cursor: pointer; }

html .modal-body .panel-title a {
  display: block;
  padding: 10px 15px;
  margin: -10px -15px;
  text-decoration: none; }
  html .modal-body .panel-title a:after {
    font-family: "Glyphicons Halflings";
    content: "\E260";
    float: right; }
  html .modal-body .panel-title a.collapsed:after {
    font-family: "Glyphicons Halflings";
    content: "\E259";
    float: right; }

html .modal-body .charges-services {
  padding-bottom: 5px; }

html .modal-body .span-sum {
  font-size: 14px; }

html .modal-body .cnt-tip-title {
  position: absolute;
  top: 75px;
  left: 5px;
  font-size: 18px; }

html .modal-body h2 {
  display: flex; }

html .modal-body .checkbox-inline {
  font-size: 14px; }

html .modal-body .forgot-link {
  margin-top: 4px;
  float: right; }

html .modal-body .error-message {
  color: #e64650;
  margin: 10px 0;
  font-weight: bold; }

html .modal-body .btn.btn-default {
  margin-left: 0; }

html .modal-body .paid-services {
  margin-bottom: 0; }
  html .modal-body .paid-services > .row {
    margin-bottom: 5px; }

html .modal-body .services-title {
  margin: 15px 0 15px 0;
  font-weight: 500;
  font-size: 24px; }
  html .modal-body .services-title > .row {
    margin-bottom: 5px; }

html .modal-body .paid-label {
  font-weight: normal; }
  html .modal-body .paid-label--title {
    font-weight: 500; }

html .modal-body .services-artist {
  font-size: 12px; }

html .modal-body .service-total {
  margin-left: -15px;
  margin-right: -15px;
  padding: 15px 15px 15px 15px;
  background-color: lightgray;
  color: black; }
  html .modal-body .service-total > .form-group {
    margin-bottom: 10px; }

html .modal-body .info-message {
  margin: 10px 0;
  font-weight: bold; }

html .modal-body .tip-message {
  padding: 15px 0 0;
  font-size: 12px; }

html .modal-body .image-details {
  min-height: calc(70vh - 102px); }
  html .modal-body .image-details img {
    margin: 0 auto; }

html .modal-body .image-nav {
  position: absolute;
  top: 30px;
  width: 40%;
  height: 93%;
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 8040;
  padding-top: 18%;
  text-align: left; }
  html .modal-body .image-nav:hover div, html .modal-body .image-nav:active div, html .modal-body .image-nav:focus div {
    visibility: visible; }
  html .modal-body .image-nav.image-prev {
    left: 5px;
    padding-left: 20px; }
  html .modal-body .image-nav.image-next {
    right: 0;
    text-align: right;
    padding-right: 20px; }
  html .modal-body .image-nav div {
    position: absolute;
    top: 50%;
    margin-top: -1em;
    cursor: pointer;
    z-index: 8040;
    visibility: hidden; }
  html .modal-body .image-nav .arrow-left {
    width: 0;
    height: 0;
    left: 0;
    border-style: solid;
    border-width: 25px 43.3px 25px 0;
    border-color: transparent #25303b transparent transparent; }
  html .modal-body .image-nav .arrow-right {
    width: 0;
    height: 0;
    right: 0;
    border-style: solid;
    border-width: 25px 0 25px 43.3px;
    border-color: transparent transparent transparent #25303b; }

html .modal-body.payment-approval {
  padding: 15px 15px 0 15px; }

html .modal-body.image-fullscreen-modal__body {
  padding: 15px 1px 15px 1px;
  max-height: 640px; }
  html .modal-body.image-fullscreen-modal__body .close-layer {
    position: absolute;
    top: 20px;
    right: 15px;
    margin-right: -30px;
    margin-top: -30px;
    background-color: transparent;
    width: 45px;
    height: 38px;
    cursor: pointer;
    display: inline-block; }
  html .modal-body.image-fullscreen-modal__body .close {
    position: absolute;
    top: 34px;
    right: 36px;
    margin-right: -30px;
    margin-top: -30px;
    background-color: #ffffff;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    opacity: 1;
    cursor: pointer;
    color: #999999;
    font-size: 28px;
    font-weight: bold;
    display: inline-block;
    line-height: 0; }

html .modal-body.password-reset-modal__body {
  padding-bottom: 0; }

@media screen and (max-width: 768px) {
  html .modal-body h2 {
    font-size: 24px; }
  html .modal-body h3 {
    font-size: 20px; }
  html .modal-body .form-control {
    font-size: 16px; }
  html .modal-body .fr-textarea-as-input {
    height: 80px; } }

html .modal-body .alert-message h3 {
  text-align: center;
  font-weight: normal; }

html .modal-body .alert-message__link {
  text-align: center;
  margin-top: 30px; }

html .modal-body .approval-title {
  padding-right: 0;
  font-size: 18px;
  margin-top: 5px; }
  html .modal-body .approval-title__bold {
    font-weight: bold; }
  html .modal-body .approval-title__error {
    margin-top: 15px;
    color: #e64650;
    font-size: 18px; }
  html .modal-body .approval-title__success {
    color: #46aae6;
    font-size: 18px; }

html .modal-body .promo-code {
  padding: 17px 15px 0 15px;
  background-color: lightgray;
  color: black;
  margin: 15px -15px; }
  html .modal-body .promo-code__error {
    margin-top: -5px;
    margin-bottom: 8px;
    color: #e64650;
    font-size: 14px; }
  html .modal-body .promo-code .input-padding {
    padding-right: 12px; }
  html .modal-body .promo-code__input {
    margin: 0 0 10px 0 !important; }
  html .modal-body .promo-code .form-control {
    margin-bottom: 0; }

html .modal-body.client-modal__body {
  padding-bottom: 0; }

html .modal-body .fr-unseen-apt__body {
  padding: 15px; }

html .modal-body .fr-title {
  margin-top: 5px; }

html .modal-body.service-modal__body .fr-service-title {
  padding-top: 5px;
  font-weight: bold; }

html .modal-body .fr-title__edit-price {
  margin-top: 7px; }

html .modal-body .fr-unseen-apt-row {
  padding: 5px 0 5px;
  border-bottom: 1px dashed #cccccc; }

html .modal-body.fr-unseen-apt__body {
  padding-top: 0; }

html .modal-body .img-handler {
  position: absolute;
  top: 8px;
  left: 0;
  z-index: 8051; }

html .modal-body.affiliate-body {
  padding-bottom: 0; }

html .modal-body.video-modal__body {
  padding: 15px 15px 10px; }
  html .modal-body.video-modal__body .iframe-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 66.66%;
    /* 3:2 Aspect Ratio */ }
    html .modal-body.video-modal__body .iframe-container .iframe-responsive {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border: none; }

html .modal-footer .book-footer-wrapper {
  text-align: center; }

html .modal-footer .book-footer-total {
  float: left; }

html .modal-footer .book-footer-book {
  float: right; }

html .modal-footer .book-footer-violation {
  float: right;
  margin-top: 15px;
  padding-right: 25px; }

html .modal-footer .book-total {
  margin: 15px 0;
  padding: 7px 0; }

html .modal-footer .fr-book-appointment-notification {
  text-align: center; }

html .modal-footer .btn {
  margin: 15px 0;
  padding: 7px 50px; }
  html .modal-footer .btn.btn-book {
    color: #ffffff;
    background-color: #46aae6;
    border-color: #46aae6; }
    html .modal-footer .btn.btn-book:hover {
      background-color: #0d96e5;
      border-color: #0d96e5; }
  html .modal-footer .btn.fr-unseen-apt-row {
    align-items: center;
    margin-bottom: 6px; }
  html .modal-footer .btn.fr-unseen-apt-mt {
    margin-top: -5px; }
    @media screen and (max-width: 769px) {
      html .modal-footer .btn.fr-unseen-apt-mt {
        margin-right: 5px; } }

html .modal-footer.image-details-footer {
  text-align: center; }
  html .modal-footer.image-details-footer .btn {
    padding: 4px 12px;
    margin: 0 5px; }

html .modal-footer.card-add-footer {
  text-align: center;
  padding: 15px 6px; }
  html .modal-footer.card-add-footer .btn {
    padding: 4px 30px;
    margin: 0 5px; }
    html .modal-footer.card-add-footer .btn.btn-accept {
      margin-top: 15px; }
  html .modal-footer.card-add-footer.payment-approval {
    border-top: none;
    padding: 9px 6px; }

html .modal-footer.fr-unseen-apt__footer {
  padding: 0 15px 15px 0;
  border: none; }
  html .modal-footer.fr-unseen-apt__footer .btn {
    margin: 0; }

html .modal-footer.password-reset-modal__footer {
  border-top: none;
  padding-top: 0; }
  html .modal-footer.password-reset-modal__footer .btn {
    margin: 15px 0;
    padding: 6px 25px; }
  html .modal-footer.password-reset-modal__footer .fr-btn-reset {
    color: #ffffff;
    background-color: #46aae6;
    border-color: #46aae6; }
    html .modal-footer.password-reset-modal__footer .fr-btn-reset:hover {
      background-color: #0d96e5;
      border-color: #0d96e5; }

html .modal-footer.client-modal__footer {
  padding-top: 0;
  border-top: none; }
  html .modal-footer.client-modal__footer .fr-btn-client__save {
    padding: 7px 35px;
    float: right;
    background-color: #46aae6;
    border: 3px solid #46aae6;
    color: #ffffff;
    background-color: #46aae6;
    border: 3px solid #46aae6;
    -webkit-transition: background 0.4s linear;
    -moz-transition: background 0.4s linear;
    -o-transition: background 0.4s linear;
    transition: background 0.4s linear;
    color: #ffffff; }
    html .modal-footer.client-modal__footer .fr-btn-client__save:hover, html .modal-footer.client-modal__footer .fr-btn-client__save:focus, html .modal-footer.client-modal__footer .fr-btn-client__save:active, html .modal-footer.client-modal__footer .fr-btn-client__save:active:focus {
      background-color: #0d96e5;
      border: 3px solid #0d96e5;
      color: #ffffff;
      outline: none !important;
      box-shadow: none; }
    html .modal-footer.client-modal__footer .fr-btn-client__save:hover {
      background-color: #0d96e5;
      border: 3px solid #0d96e5;
      color: #ffffff; }
  html .modal-footer.client-modal__footer .fr-btn-client__edit {
    padding: 7px 35px;
    float: right;
    background-color: #46e6aa;
    border: 3px solid #46e6aa;
    color: #ffffff;
    background-color: #46e6aa;
    border: 3px solid #46e6aa;
    -webkit-transition: background 0.4s linear;
    -moz-transition: background 0.4s linear;
    -o-transition: background 0.4s linear;
    transition: background 0.4s linear;
    color: #ffffff; }
    html .modal-footer.client-modal__footer .fr-btn-client__edit:hover, html .modal-footer.client-modal__footer .fr-btn-client__edit:focus, html .modal-footer.client-modal__footer .fr-btn-client__edit:active, html .modal-footer.client-modal__footer .fr-btn-client__edit:active:focus {
      background-color: #0de592;
      border: 3px solid #0de592;
      color: #ffffff;
      outline: none !important;
      box-shadow: none; }
    html .modal-footer.client-modal__footer .fr-btn-client__edit:hover {
      background-color: #0de592;
      border: 3px solid #0de592;
      color: #ffffff; }
  html .modal-footer.client-modal__footer .fr-btn-client__remove {
    padding: 7px 35px;
    float: left;
    background-color: #e64650;
    border: 3px solid #e64650;
    color: #ffffff;
    background-color: #e64650;
    border: 3px solid #e64650;
    -webkit-transition: background 0.4s linear;
    -moz-transition: background 0.4s linear;
    -o-transition: background 0.4s linear;
    transition: background 0.4s linear;
    color: #ffffff; }
    html .modal-footer.client-modal__footer .fr-btn-client__remove:hover, html .modal-footer.client-modal__footer .fr-btn-client__remove:focus, html .modal-footer.client-modal__footer .fr-btn-client__remove:active, html .modal-footer.client-modal__footer .fr-btn-client__remove:active:focus {
      background-color: #e50d1c;
      border: 3px solid #e50d1c;
      color: #ffffff;
      outline: none !important;
      box-shadow: none; }
    html .modal-footer.client-modal__footer .fr-btn-client__remove:hover {
      background-color: #e50d1c;
      border: 3px solid #e50d1c;
      color: #ffffff; }
  html .modal-footer.client-modal__footer .fr-apt-modal__footer {
    text-align: center; }
    html .modal-footer.client-modal__footer .fr-apt-modal__footer.fr-btn__center {
      padding: 7px 35px;
      background-color: #46aae6;
      border: 3px solid #46aae6;
      color: #ffffff;
      background-color: #46aae6;
      border: 3px solid #46aae6;
      -webkit-transition: background 0.4s linear;
      -moz-transition: background 0.4s linear;
      -o-transition: background 0.4s linear;
      transition: background 0.4s linear;
      color: #ffffff; }
      html .modal-footer.client-modal__footer .fr-apt-modal__footer.fr-btn__center:hover, html .modal-footer.client-modal__footer .fr-apt-modal__footer.fr-btn__center:focus, html .modal-footer.client-modal__footer .fr-apt-modal__footer.fr-btn__center:active, html .modal-footer.client-modal__footer .fr-apt-modal__footer.fr-btn__center:active:focus {
        background-color: #0d96e5;
        border: 3px solid #0d96e5;
        color: #ffffff;
        outline: none !important;
        box-shadow: none; }
      html .modal-footer.client-modal__footer .fr-apt-modal__footer.fr-btn__center:hover {
        background-color: #0d96e5;
        border: 3px solid #0d96e5;
        color: #ffffff; }

html .modal-footer.client-comment-modal__footer .fr-btn {
  padding: 7px 35px; }

html .modal-footer.client-comment-modal__footer .btn.pull-left {
  margin-left: 0; }

html .modal-footer .fr-affiliate-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  margin-bottom: 5px; }

html .modal-footer [placeholder]:focus::-webkit-input-placeholder {
  transition: text-indent 0.5s 0.5s ease;
  text-indent: -100%; }

html .modal-footer [placeholder]:focus::-moz-placeholder {
  transition: text-indent 0.5s 0.5s ease;
  text-indent: -100%; }

html .modal-footer [placeholder]:focus:-ms-input-placeholder {
  transition: text-indent 0.5s 0.5s ease;
  text-indent: -100%; }

html .modal-footer.affiliate-footer {
  padding-top: 0;
  border-top: none; }

html .alert-modal {
  top: 15%; }

@media (min-width: 474px) {
  .image-modal {
    width: 460px;
    margin: 30px auto; } }

.alertify-logs {
  z-index: 1999; }

.alertify {
  z-index: 9999; }
  .alertify .dialog {
    width: 338px; }
    @media screen and (max-width: 769px) {
      .alertify .dialog {
        padding: 0 5%;
        width: auto; } }
  .alertify .dialog nav {
    text-align: center;
    margin-top: 20px; }
  .alertify .dialog .msg {
    text-align: center; }
  .alertify .title {
    margin-top: -15px;
    font-size: 24px;
    font-weight: bold; }
  .alertify .title__middle {
    margin-top: -15px;
    font-size: 22px;
    font-weight: bold; }
  .alertify .title__p {
    font-size: 22px; }
  .alertify .glyphicon-map-marker {
    text-align: center;
    font-size: 60px;
    color: #46e6aa; }
  .alertify .cancel {
    background-color: #e64650 !important;
    color: #ffffff !important;
    border-radius: 4px !important; }
    .alertify .cancel:hover {
      background-color: #e50d1c !important;
      border-color: #e50d1c; }
  .alertify .ok {
    background-color: #46e6aa !important;
    border: 3px solid #46e6aa;
    color: #ffffff !important;
    border-radius: 4px !important; }
    .alertify .ok:hover {
      background-color: #0de592 !important;
      border-color: #0de592; }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/0eC6fl06luXEYWpBSJvXCBJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Fl4y0QdOxyyTHEGMXX8kcRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Roboto Condensed';
  src: url("fonts/roboto-condensed-light/RobotoCondensed-Light.woff") format("woff"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.ttf") format("truetype"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.svg#RobotoCondensed-Light") format("svg");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.eot");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.woff2") format("woff2"), url("fonts/roboto-condensed-light/roboto-condensed.light.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("fonts/glyphicons-halflings-regular.woff") format("woff"), url("fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("fonts/glyphicons-halflings-regular.svg#Glyphicons Halflings") format("svg");
  src: url("fonts/glyphicons-halflings-regular.eot");
  src: url("fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@keyframes fadeInOpacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeInRight {
  0% {
    -webkit-transform: translateX(75%);
    transform: translateX(75%);
    visibility: visible; }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInDown {
  0% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes slideDown {
  0% {
    top: calc(100% - 15px);
    height: 0;
    opacity: 1; }
  100% {
    top: 15px;
    height: calc(100% - 15px);
    opacity: 0; } }

@keyframes slideUp {
  0% {
    height: 15px;
    opacity: 1; }
  100% {
    height: calc(100% - 15px);
    opacity: 0; } }

.nav-tabs-dropdown {
  display: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-family: "Roboto Condensed", Cambria, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: bold;
  padding-right: 35px;
  padding-left: 35px; }
  .nav-tabs-dropdown:before {
    content: "\e252";
    font-family: "Glyphicons Halflings";
    position: absolute;
    right: 30px; }

.nav-tabs-dropdown.opened:before {
  content: "\e253"; }

.glyphicon.glyphicon-triangle-bottom {
  right: 30px;
  position: absolute; }

.nav-tabs-horizontal {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 0; }
  @media only screen and (max-width: 768px) {
    .nav-tabs-horizontal {
      padding-bottom: 0; } }

.nav-tabs-horizontal > li > a {
  padding-top: 12px;
  padding-bottom: 10px;
  border: none; }
  .nav-tabs-horizontal > li > a:hover, .nav-tabs-horizontal > li > a:focus {
    padding-bottom: 10px;
    outline: none;
    border-bottom: 4px solid #46aae6;
    background-color: #ffffff; }
    @media screen and (max-width: 769px) {
      .nav-tabs-horizontal > li > a:hover, .nav-tabs-horizontal > li > a:focus {
        background-color: #46aae6;
        border: none; } }

.nav-tabs-horizontal > li.active > a,
.nav-tabs-horizontal > li.active > a:hover,
.nav-tabs-horizontal > li.active > a:focus {
  background-color: #ffffff;
  color: #46aae6;
  border: none;
  border-bottom: 4px solid #46aae6;
  padding-bottom: 10px;
  padding-top: 12px; }
  .nav-tabs-horizontal > li.active > a > .fr-tab-complete, .nav-tabs-horizontal > li.active > a > .fr-tab-fully-incomplete, .nav-tabs-horizontal > li.active > a > .fr-tab-incomplete,
  .nav-tabs-horizontal > li.active > a:hover > .fr-tab-complete,
  .nav-tabs-horizontal > li.active > a:hover > .fr-tab-fully-incomplete,
  .nav-tabs-horizontal > li.active > a:hover > .fr-tab-incomplete,
  .nav-tabs-horizontal > li.active > a:focus > .fr-tab-complete,
  .nav-tabs-horizontal > li.active > a:focus > .fr-tab-fully-incomplete,
  .nav-tabs-horizontal > li.active > a:focus > .fr-tab-incomplete {
    background-color: #0de592; }
  .nav-tabs-horizontal > li.active > a > .fr-tab-fully-incomplete,
  .nav-tabs-horizontal > li.active > a:hover > .fr-tab-fully-incomplete,
  .nav-tabs-horizontal > li.active > a:focus > .fr-tab-fully-incomplete {
    background-color: #e50d1c; }
  .nav-tabs-horizontal > li.active > a > .fr-tab-incomplete,
  .nav-tabs-horizontal > li.active > a:hover > .fr-tab-incomplete,
  .nav-tabs-horizontal > li.active > a:focus > .fr-tab-incomplete {
    background-color: #e5920d; }

@media screen and (min-width: 769px) {
  #nav-tabs-wrapper {
    display: block !important;
    margin-top: 0;
    margin-left: -15px;
    margin-right: -15px; } }

@media screen and (max-width: 768px) {
  .fr-navbar-tabs {
    margin: 0 0 15px 0;
    border-bottom-width: 1px;
    border-color: #cccccc; }
  .nav-tabs-dropdown {
    display: block;
    width: 100%;
    border-radius: 0;
    font-weight: normal;
    font-family: "Roboto", Helvetica, sans-serif;
    font-size: 16px;
    padding-left: 45px; }
  #nav-tabs-wrapper {
    background-color: #ffffff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    text-align: center; }
  .nav-tabs-horizontal {
    min-height: 20px;
    margin-bottom: 20px;
    background-color: #e6e6e6;
    border-color: #e6e6e6;
    border-radius: 4px;
    -webkit-transition: background 0.4s linear;
    -moz-transition: background 0.4s linear;
    -o-transition: background 0.4s linear;
    transition: background 0.4s linear; }
    .nav-tabs-horizontal:hover, .nav-tabs-horizontal:focus, .nav-tabs-horizontal:active {
      background-color: #e6e6e6;
      color: #ffffff !important; }
  .nav-tabs-horizontal > li {
    float: none; }
  .nav-tabs-horizontal > li,
  .nav-tabs-horizontal > li > a {
    background: #e6e6e6;
    width: 100%;
    border-radius: 4px;
    border-color: #e6e6e6;
    -webkit-transition: background 0.4s linear;
    -moz-transition: background 0.4s linear;
    -o-transition: background 0.4s linear;
    transition: background 0.4s linear; }
    .nav-tabs-horizontal > li:hover, .nav-tabs-horizontal > li:focus, .nav-tabs-horizontal > li:focus,
    .nav-tabs-horizontal > li > a:hover,
    .nav-tabs-horizontal > li > a:focus,
    .nav-tabs-horizontal > li > a:focus {
      color: #ffffff; }
  .nav-tabs-horizontal > li.active > a,
  .nav-tabs-horizontal > li.active > a:hover,
  .nav-tabs-horizontal > li.active > a:focus {
    display: none; }
  .fr-mb__login {
    margin-bottom: 15px !important; } }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/0eC6fl06luXEYWpBSJvXCBJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Fl4y0QdOxyyTHEGMXX8kcRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Roboto Condensed';
  src: url("fonts/roboto-condensed-light/RobotoCondensed-Light.woff") format("woff"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.ttf") format("truetype"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.svg#RobotoCondensed-Light") format("svg");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.eot");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.woff2") format("woff2"), url("fonts/roboto-condensed-light/roboto-condensed.light.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("fonts/glyphicons-halflings-regular.woff") format("woff"), url("fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("fonts/glyphicons-halflings-regular.svg#Glyphicons Halflings") format("svg");
  src: url("fonts/glyphicons-halflings-regular.eot");
  src: url("fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

.datepicker_wrapper {
  overflow: hidden;
  position: relative;
  z-index: 900;
  max-height: 0;
  margin-bottom: 15px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }
  .datepicker_wrapper.toggle-picker {
    margin-left: -154px;
    margin-top: 20px;
    width: calc(100% + 170px);
    background: #46aae6; }
  .datepicker_wrapper #ui-datepicker-div {
    position: static !important; }
  .datepicker_wrapper.in {
    max-height: 550px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s; }
  .datepicker_wrapper .ui-datepicker-inline {
    text-align: center;
    background: transparent;
    width: 100% !important;
    border: 0; }
    .datepicker_wrapper .ui-datepicker-inline .ui-state-hover {
      border: 0; }
      .datepicker_wrapper .ui-datepicker-inline .ui-state-hover.ui-datepicker-next {
        right: 2px; }
        .datepicker_wrapper .ui-datepicker-inline .ui-state-hover.ui-datepicker-next:hover {
          -webkit-transform: translateX(5px);
          transform: translateX(5px);
          transition-duration: 1s; }
      .datepicker_wrapper .ui-datepicker-inline .ui-state-hover.ui-datepicker-prev {
        left: 2px; }
        .datepicker_wrapper .ui-datepicker-inline .ui-state-hover.ui-datepicker-prev:hover {
          -webkit-transform: translateX(-5px);
          transform: translateX(-5px);
          transition-duration: 1s; }
  .datepicker_wrapper .ui-datepicker-calendar .ui-datepicker-today .ui-state-highlight {
    background: #B5D5EB;
    border: 1px solid #B5D5EB; }
    .datepicker_wrapper .ui-datepicker-calendar .ui-datepicker-today .ui-state-highlight.ui-state-active {
      border: 1px solid #46aae6; }
  .datepicker_wrapper .ui-datepicker-calendar .ui-state-active {
    background: none;
    color: #464646;
    border: 1px solid #46aae6; }

span.ui-slider-handle.ui-corner-all.ui-state-default {
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  margin: -24px;
  background: #ffffff;
  border-radius: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  outline: none; }

span.ui-slider-handle.ui-corner-all.ui-state-default.ui-state-active {
  color: #464646 !important; }

.datepicker_wrapper .range_wrapper {
  width: 240px;
  margin: auto; }
  .datepicker_wrapper .range_wrapper input {
    display: none; }
  .datepicker_wrapper .range_wrapper span {
    display: block;
    position: absolute; }

.datepicker_wrapper .ui-slider-range + span.ui-slider-handle.ui-corner-all.ui-state-default {
  margin-left: -50px; }
  .datepicker_wrapper .ui-slider-range + span.ui-slider-handle.ui-corner-all.ui-state-default + span.ui-slider-handle.ui-corner-all.ui-state-default {
    margin-right: -50px; }

.datepicker_wrapper .ui-widget.ui-widget-content {
  border: none; }

.datepicker_wrapper .ui-state-default, .datepicker_wrapper .ui-widget-content {
  text-align: center; }

.glyphicon-time {
  width: 34px;
  height: 34px;
  font-size: 34px;
  color: #555; }

.calendar-salon-link {
  margin: 30px 0 10px 20px; }
  @media screen and (max-width: 480px) {
    .calendar-salon-link {
      float: right;
      margin: 14px 0 0 0; } }

.calendar-nav {
  margin: 22px 15px 10px 2px; }
  .calendar-nav .btn-default {
    padding: 6px 20px;
    margin: 0 5px 0 0; }
    .calendar-nav .btn-default:last-child {
      margin: 0; }
  .calendar-nav .btn-default:focus:hover {
    background-color: #e6e6e6;
    border-color: #adadad; }
  .calendar-nav .btn-default:focus {
    background-color: #ffffff;
    border-color: #cccccc; }
  .calendar-nav a {
    display: inline-block;
    vertical-align: top;
    margin-left: 15px; }
  @media (max-width: 784px) {
    .calendar-nav {
      display: flex;
      float: none !important;
      clear: both;
      margin: 0 15px 15px 15px;
      text-align: center;
      justify-content: space-between; }
      .calendar-nav .btn {
        padding: 6px 15px; }
        .calendar-nav .btn:nth-of-type(2), .calendar-nav .btn:nth-of-type(3) {
          width: 300px; }
        .calendar-nav .btn:last-of-type {
          margin-right: 0; } }

.ui-datepicker table {
  table-layout: fixed; }
  .ui-datepicker table td {
    position: relative; }
    .ui-datepicker table td .markers {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
      height: 100%;
      width: 100%;
      box-sizing: border-box; }

.ui-datepicker-calendar td .ui-state-default {
  background-color: transparent;
  border: 1px transparent;
  min-height: 1.5em; }

.ui-state-default, .ui-widget-content .ui-state-default {
  border: 1px transparent;
  position: relative;
  z-index: 2; }

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active {
  background: #46aae6; }

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
  background: #fffa90; }

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
  cursor: pointer; }
  .ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
    background: none; }
    .ui-datepicker .ui-datepicker-prev span:before, .ui-datepicker .ui-datepicker-next span:before {
      font-family: "Glyphicons Halflings";
      text-indent: 0;
      content: "\e079";
      display: block;
      font-size: 15px;
      line-height: 18px; }

.ui-datepicker .ui-datepicker-next span:before {
  content: "\e080"; }

.ui-datepicker .ui-widget-header {
  border-color: #ffffff; }

#tabs-with-dropdown .ui-datepicker .ui-state-default {
  text-align: center;
  border: 0; }

#tabs-with-dropdown .ui-datepicker-multi-3 .ui-datepicker-group, #tabs-with-dropdown .datepicker_wrapper.static .ui-datepicker-group {
  width: 33%;
  display: inline-block;
  vertical-align: top;
  float: none;
  background: #ffffff;
  margin: 0 0.1%;
  padding: 0 2px 0 0; }

#tabs-with-dropdown .ui-datepicker-multi-3 .ui-state-highlight, #tabs-with-dropdown .ui-datepicker-multi-3 .ui-widget-content .ui-state-highlight, #tabs-with-dropdown .ui-datepicker-multi-3 .ui-widget-header .ui-state-highlight, #tabs-with-dropdown .datepicker_wrapper.static .ui-state-highlight, #tabs-with-dropdown .datepicker_wrapper.static .ui-widget-content .ui-state-highlight, #tabs-with-dropdown .datepicker_wrapper.static .ui-widget-header .ui-state-highlight {
  background: transparent;
  border: 1px solid #46aae6; }
  #tabs-with-dropdown .ui-datepicker-multi-3 .ui-state-highlight.ui-state-hover, #tabs-with-dropdown .ui-datepicker-multi-3 .ui-widget-content .ui-state-highlight.ui-state-hover, #tabs-with-dropdown .ui-datepicker-multi-3 .ui-widget-header .ui-state-highlight.ui-state-hover, #tabs-with-dropdown .datepicker_wrapper.static .ui-state-highlight.ui-state-hover, #tabs-with-dropdown .datepicker_wrapper.static .ui-widget-content .ui-state-highlight.ui-state-hover, #tabs-with-dropdown .datepicker_wrapper.static .ui-widget-header .ui-state-highlight.ui-state-hover {
    border: 1px solid #46aae6; }

#tabs-with-dropdown .ui-datepicker-multi-3 .ui-datepicker-prev, #tabs-with-dropdown .datepicker_wrapper.static .ui-datepicker-prev {
  left: 9px; }

#tabs-with-dropdown .ui-datepicker-multi-3 .ui-datepicker-next, #tabs-with-dropdown .datepicker_wrapper.static .ui-datepicker-next {
  right: 9px; }

#tabs-with-dropdown .ui-datepicker-multi-3 .ui-corner-all, #tabs-with-dropdown .datepicker_wrapper.static .ui-corner-all {
  top: 2px;
  font-weight: bold; }

#tabs-with-dropdown .ui-datepicker-multi-2 .ui-datepicker-group {
  width: 49.9%; }
  #tabs-with-dropdown .ui-datepicker-multi-2 .ui-datepicker-group:first-of-type {
    margin-right: 0.1%; }
  #tabs-with-dropdown .ui-datepicker-multi-2 .ui-datepicker-group:first-of-type {
    margin-right: 0.1%; }

#tabs-with-dropdown [role="presentation"] > input, #tabs-with-dropdown [role="presentation"] > select {
  position: absolute;
  bottom: 14px;
  width: 90%;
  left: 0;
  right: 0;
  margin: 0 auto; }

#tabs-with-dropdown [role="presentation"] > a {
  outline: none; }

#tabs-with-dropdown [role="presentation"] .datepicker_wrapper {
  margin-left: -155px; }

#tabs-with-dropdown [role="presentation"] .datepicker {
  margin-bottom: 50px; }
  #tabs-with-dropdown [role="presentation"] .datepicker .ui-datepicker-title {
    font-size: 22px;
    line-height: 25px; }
  #tabs-with-dropdown [role="presentation"] .datepicker .ui-datepicker-header {
    padding: .2em 0 0;
    background-color: transparent;
    border: 0; }
    #tabs-with-dropdown [role="presentation"] .datepicker .ui-datepicker-header .ui-corner-all {
      border-radius: 50px;
      background: #ffffff;
      cursor: pointer;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }
      #tabs-with-dropdown [role="presentation"] .datepicker .ui-datepicker-header .ui-corner-all .ui-icon:before {
        line-height: 16px; }
      #tabs-with-dropdown [role="presentation"] .datepicker .ui-datepicker-header .ui-corner-all.ui-datepicker-prev {
        left: 15px;
        top: 0; }
        #tabs-with-dropdown [role="presentation"] .datepicker .ui-datepicker-header .ui-corner-all.ui-datepicker-prev:hover {
          -webkit-transform: translateX(-5px);
          transform: translateX(-5px);
          transition-duration: 1s; }
      #tabs-with-dropdown [role="presentation"] .datepicker .ui-datepicker-header .ui-corner-all.ui-datepicker-next {
        right: 15px;
        top: 0; }
        #tabs-with-dropdown [role="presentation"] .datepicker .ui-datepicker-header .ui-corner-all.ui-datepicker-next:hover {
          -webkit-transform: translateX(5px);
          transform: translateX(5px);
          transition-duration: 1s; }
      #tabs-with-dropdown [role="presentation"] .datepicker .ui-datepicker-header .ui-corner-all.ui-state-hover {
        top: 0;
        border: 0;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        background: #333; }
        #tabs-with-dropdown [role="presentation"] .datepicker .ui-datepicker-header .ui-corner-all.ui-state-hover.ui-datepicker-next {
          right: 10px;
          top: 0; }
        #tabs-with-dropdown [role="presentation"] .datepicker .ui-datepicker-header .ui-corner-all.ui-state-hover.ui-datepicker-prev {
          left: 10px;
          top: 0; }
        #tabs-with-dropdown [role="presentation"] .datepicker .ui-datepicker-header .ui-corner-all.ui-state-hover .ui-icon {
          color: #ffffff;
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          -ms-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s; }
  #tabs-with-dropdown [role="presentation"] .datepicker .ui-state-disabled > .ui-state-default:not(.ui-slider-handle):hover, #tabs-with-dropdown [role="presentation"] .datepicker .ui-state-disabled > .ui-widget-content .ui-state-default:not(.ui-slider-handle):hover {
    background: none; }

#tabs-with-dropdown [role="presentation"] .datepicker_wrapper.in {
  padding: 30px 15px 60px;
  margin-top: 15px; }

#tabs-with-dropdown [role="presentation"] .ui-datepicker-calendar .ui-state-default {
  background: none;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  line-height: 45px;
  border: 0;
  transition: all 0.3s;
  font-size: 16px; }
  #tabs-with-dropdown [role="presentation"] .ui-datepicker-calendar .ui-state-default:hover {
    background: #ffffff;
    transition: all 0.3s; }

#tabs-with-dropdown [role="presentation"] .ui-datepicker-current-day .ui-state-default.ui-state-active {
  background: #ffffff; }

#tabs-with-dropdown [role="presentation"] #slider-range .ui-state-default, #tabs-with-dropdown [role="presentation"] #slider-range .ui-widget-content .ui-state-default {
  transition: none !important; }

#tabs-with-dropdown [role="presentation"] .ui-state-default.ui-state-highlight {
  background: transparent;
  border: 2px solid #ffffff;
  line-height: 42px; }

#tabs-with-dropdown [role="presentation"] .ui-state-highlight.ui-state-active, #tabs-with-dropdown [role="presentation"] .ui-widget-content .ui-state-highlight.ui-state-active, #tabs-with-dropdown [role="presentation"] .ui-widget-header .ui-state-highlight.ui-state-active {
  background: #ffffff;
  border: 0;
  line-height: 45px; }

.reset.glyphicon {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 17px;
  padding: 9px;
  z-index: 1;
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s; }
  .reset.glyphicon.active {
    opacity: 1;
    -webkit-transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    -ms-transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
    transition: opacity 0.5s; }

.block, .book {
  width: 6px;
  height: 6px;
  border-radius: 50px;
  margin: 1px 0 0; }

.available {
  background-color: #ddffdd;
  height: 100%; }

.block {
  background-color: #999999;
  margin: 5px 5px; }

.book {
  background-color: #46e6aa;
  margin: 5px 5px; }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/0eC6fl06luXEYWpBSJvXCBJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Fl4y0QdOxyyTHEGMXX8kcRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Roboto Condensed';
  src: url("fonts/roboto-condensed-light/RobotoCondensed-Light.woff") format("woff"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.ttf") format("truetype"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.svg#RobotoCondensed-Light") format("svg");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.eot");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.woff2") format("woff2"), url("fonts/roboto-condensed-light/roboto-condensed.light.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("fonts/glyphicons-halflings-regular.woff") format("woff"), url("fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("fonts/glyphicons-halflings-regular.svg#Glyphicons Halflings") format("svg");
  src: url("fonts/glyphicons-halflings-regular.eot");
  src: url("fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

.popover {
  display: block !important;
  max-width: 200px !important;
  padding: 0 2px 2px 0;
  width: 200px;
  margin: 2px; }
  .popover-share {
    border-radius: 10px;
    width: auto;
    padding: 0;
    z-index: 2000; }
    .popover-share__block {
      margin-left: 15px;
      display: inline-block;
      height: 40px; }
      .popover-share__block:first-child {
        margin: 0; }
    .popover-share .popover-content {
      display: flex;
      border-radius: 10px;
      padding: 15px; }
    .popover-share__btn {
      background: none;
      border: none;
      padding: 0; }
    .popover-share__facebook {
      background: transparent url("img/social_big.png") no-repeat 0 0;
      transition: background 0.4s linear;
      width: 40px;
      height: 40px; }
      .popover-share__facebook:hover {
        background: url("img/social_big.png") no-repeat 0 -40px;
        transition: background 0.4s linear; }
    .popover-share__pinterest {
      background: transparent url("img/social_big.png") no-repeat -120px 0;
      transition: background 0.4s linear;
      border: none;
      padding: 0;
      width: 40px;
      height: 40px; }
      .popover-share__pinterest:hover {
        background: url("img/social_big.png") no-repeat -120px -40px;
        transition: background 0.4s linear; }

.popover-title {
  margin: 6px;
  padding: 0 2px 2px 4px;
  font-family: "Roboto", Helvetica, sans-serif;
  font-size: 16px;
  background-color: #ffffff !important; }

.popover-content {
  background: transparent;
  margin-top: 5px;
  padding-bottom: 15px;
  color: #616161;
  font-family: "Roboto", Helvetica, sans-serif;
  font-size: 16px;
  text-align: center; }
  .popover-content .title {
    margin-top: 10px; }
  .popover-content .comment {
    text-align: left; }
  .popover-content .comment-title {
    margin-top: 10px;
    text-align: left; }
  .popover-content .btn-link {
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    color: #46aae6; }
  .popover-content .close {
    position: absolute;
    top: 2px;
    right: 2px;
    height: 25px;
    width: 25px; }
    .popover-content .close:focus, .popover-content .close:hover, .popover-content .close:active {
      outline: none; }
  .popover-content .action-btn {
    margin: 10px 0 15px; }
    .popover-content .action-btn.pull-right .popover-btn {
      margin-right: -2px; }
    .popover-content .action-btn--mb {
      margin: 10px 0 5px; }
  .popover-content .comment-btn {
    margin-left: 5px;
    margin-bottom: 0;
    font-size: 14px;
    padding: 0 3px; }
  .popover-content .btn-container {
    padding: 0 12px 0 15px; }
    .popover-content .btn-container .popover-btn-block {
      margin-left: 5px; }

.popover-txt-sel {
  font-weight: bold; }

.popover-btn {
  display: inline-block;
  padding: 2px 12px;
  margin: 3px 3px 3px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  transition: 0.4s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 3px solid;
  border-radius: 4px;
  outline: none; }

.popover-btn-book, .popover-btn-unblock {
  background-color: #46e6aa;
  border: 3px solid #46e6aa;
  color: #ffffff; }
  .popover-btn-book:hover, .popover-btn-unblock:hover {
    background-color: #0de592;
    border: 3px solid #0de592;
    color: #ffffff; }

.popover-btn-block {
  background-color: #e64650;
  border: 3px solid #e64650;
  color: #ffffff;
  outline: none; }
  .popover-btn-block:hover {
    background-color: #e50d1c;
    border: 3px solid #e50d1c;
    color: #ffffff; }

.popover-btn-edit {
  background-color: #46aae6;
  border: 3px solid #46aae6;
  color: #ffffff;
  outline: none; }
  .popover-btn-edit:hover {
    background-color: #0d96e5;
    border: 3px solid #0d96e5;
    color: #ffffff; }

.popover-btn-cancel {
  background-color: #ffffff;
  border: 0 transparent;
  padding: 5px;
  color: #e64650; }
  .popover-btn-cancel:hover {
    background-color: #ffffff;
    color: #e50d1c; }

.popover-btn-noshow {
  background-color: #8246e6;
  border: 3px solid #8246e6;
  color: #ffffff; }
  .popover-btn-noshow:hover {
    background-color: #5c0de5;
    border: 3px solid #5c0de5;
    color: #ffffff; }

.popover.bottom {
  margin-top: -3px; }

.service-bold {
  font-weight: bold; }

.ml-min {
  margin-left: 5px; }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
.pagination {
  text-align: center; }

.pagination span a {
  display: inline-block;
  padding: 4px 10px;
  margin: 1px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 3px solid #46aae6;
  border-radius: 50px;
  color: #ffffff;
  background-color: #46aae6; }

.pagination span a:hover {
  color: #616161;
  background-color: #ffffff;
  text-decoration: none; }

.pagination span a:active {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

.pagination span.current {
  display: inline-block;
  padding: 4px 10px;
  margin: 1px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 2px solid #46aae6;
  border-radius: 50px;
  color: #46aae6;
  background: #ffffff none; }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/0eC6fl06luXEYWpBSJvXCBJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Fl4y0QdOxyyTHEGMXX8kcRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Roboto Condensed';
  src: url("fonts/roboto-condensed-light/RobotoCondensed-Light.woff") format("woff"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.ttf") format("truetype"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.svg#RobotoCondensed-Light") format("svg");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.eot");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.woff2") format("woff2"), url("fonts/roboto-condensed-light/roboto-condensed.light.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("fonts/glyphicons-halflings-regular.woff") format("woff"), url("fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("fonts/glyphicons-halflings-regular.svg#Glyphicons Halflings") format("svg");
  src: url("fonts/glyphicons-halflings-regular.eot");
  src: url("fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

html #tabs-with-dropdown {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: static;
  top: -100px; }
  html #tabs-with-dropdown.fixed {
    -webkit-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    position: fixed;
    top: -1px;
    width: 1140px;
    margin: auto;
    z-index: 100;
    overflow: visible; }
    html #tabs-with-dropdown.fixed .clearfix, html #tabs-with-dropdown.fixed .tab-content {
      background: #ffffff; }
      html #tabs-with-dropdown.fixed .clearfix.fr-filter-panel, html #tabs-with-dropdown.fixed .tab-content.fr-filter-panel {
        background-color: #46aae6; }
  html #tabs-with-dropdown .grid {
    margin-top: 0; }

html .tab-content {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  overflow: visible;
  position: absolute;
  z-index: 20;
  width: calc(100% + 1px);
  top: 100%;
  margin-top: -2px;
  left: -1px; }
  html .tab-content > .tab-pane {
    display: block; }

html .tab-pane {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  display: block;
  visibility: hidden; }
  html .tab-pane > div {
    margin-top: 2px; }
  html .tab-pane .what-tab-select {
    padding-bottom: 15px; }
    @media screen and (min-width: 767px) {
      html .tab-pane .what-tab-select {
        padding-bottom: 0; } }
  html .tab-pane.active {
    z-index: 333;
    margin-left: 2px;
    padding-right: 1px;
    visibility: visible;
    -webkit-transition: all 0.5s 0.5s;
    -moz-transition: all 0.5s 0.5s;
    -ms-transition: all 0.5s 0.5s;
    -o-transition: all 0.5s 0.5s;
    transition: all 0.5s 0.5s;
    display: block;
    max-height: 500px;
    margin-bottom: 2px; }
    html .tab-pane.active .services-filter.hide {
      -webkit-transition: all 0.5s 0.5s;
      -moz-transition: all 0.5s 0.5s;
      -ms-transition: all 0.5s 0.5s;
      -o-transition: all 0.5s 0.5s;
      transition: all 0.5s 0.5s;
      max-height: 500px; }
    @media screen and (max-width: 1024px) {
      html .tab-pane.active {
        margin-top: 2px; } }

html .attr_name, html .services_head {
  font-size: 24px;
  margin-top: 7px;
  font-family: "Roboto", Helvetica, sans-serif;
  text-transform: capitalize; }

html .services-filter .btn-group label {
  text-transform: capitalize; }

html .toggle_popular > a {
  margin: 10px 0 0 10px;
  display: inline-block; }

html select.placeholder {
  color: #999999; }
  html select.placeholder option {
    color: #464646; }

.who-tab-select {
  width: 25%;
  margin-left: 50%; }
  @media screen and (max-width: 641px) {
    .who-tab-select {
      padding-top: 15px; } }

@media screen and (max-width: 1024px) {
  .who-tab-select {
    padding: 0;
    width: 100%;
    margin-left: 0; }
  .services-filter {
    max-height: 100% !important; }
  html .tab-pane > div {
    margin-top: 0; }
  html .tab-pane > div input.dashboard-who {
    width: calc(100% - 30px);
    margin: 15px 0 15px 15px; }
  html .tab-pane.tab-who > div select {
    width: 100%;
    margin: 0; } }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/0eC6fl06luXEYWpBSJvXCBJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Fl4y0QdOxyyTHEGMXX8kcRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Roboto Condensed';
  src: url("fonts/roboto-condensed-light/RobotoCondensed-Light.woff") format("woff"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.ttf") format("truetype"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.svg#RobotoCondensed-Light") format("svg");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.eot");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.woff2") format("woff2"), url("fonts/roboto-condensed-light/roboto-condensed.light.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("fonts/glyphicons-halflings-regular.woff") format("woff"), url("fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("fonts/glyphicons-halflings-regular.svg#Glyphicons Halflings") format("svg");
  src: url("fonts/glyphicons-halflings-regular.eot");
  src: url("fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@keyframes fadeInOpacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeInRight {
  0% {
    -webkit-transform: translateX(75%);
    transform: translateX(75%);
    visibility: visible; }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInDown {
  0% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes slideDown {
  0% {
    top: calc(100% - 15px);
    height: 0;
    opacity: 1; }
  100% {
    top: 15px;
    height: calc(100% - 15px);
    opacity: 0; } }

@keyframes slideUp {
  0% {
    height: 15px;
    opacity: 1; }
  100% {
    height: calc(100% - 15px);
    opacity: 0; } }

.pricing__banner {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #ffffff; }
  .pricing__banner h1 {
    font-size: 32px;
    font-weight: 200;
    margin: 0; }
    @media screen and (max-width: 1024px) {
      .pricing__banner h1 {
        font-size: 40px; } }
    @media screen and (max-width: 769px) {
      .pricing__banner h1 {
        font-size: 24px; } }

.pricing__title {
  margin-bottom: 15px;
  text-align: center;
  font-size: 50px;
  font-weight: 400; }
  @media screen and (max-width: 992px) {
    .pricing__title {
      margin-top: 15px;
      font-size: 32px; } }

.pricing__period {
  width: 400px;
  margin: 15px auto; }
  @media screen and (max-width: 992px) {
    .pricing__period {
      width: auto; } }

.pricing-plan-select {
  display: none;
  font-size: 18px;
  margin-top: 20px;
  padding: 0;
  background: inherit;
  position: relative; }
  @media screen and (max-width: 992px) {
    .pricing-plan-select {
      display: block;
      text-align: center; } }
  .pricing-plan-select__item {
    display: none;
    cursor: pointer; }
    .pricing-plan-select__item--selected {
      text-align: center;
      padding: 9px 15px 10px 15px;
      color: #ffffff; }
      @media screen and (max-width: 992px) {
        .pricing-plan-select__item--selected {
          background-color: #46aae6 !important;
          border-color: #0d96e5;
          border-radius: 4px;
          -webkit-transition: background 0.4s linear;
          -moz-transition: background 0.4s linear;
          -o-transition: background 0.4s linear;
          transition: background 0.4s linear; }
          .pricing-plan-select__item--selected:hover, .pricing-plan-select__item--selected:focus {
            background-color: #0d96e5 !important;
            border-color: #0d96e5;
            color: #ffffff; } }
      @media screen and (max-width: 769px) {
        .pricing-plan-select__item--selected {
          font-size: 16px; } }
    .pricing-plan-select__item--opened {
      display: block;
      background-color: #e6e6e6;
      border-color: #e6e6e6;
      border-radius: 4px;
      padding: 9px 15px 10px 15px;
      -webkit-transition: background 0.4s linear;
      -moz-transition: background 0.4s linear;
      -o-transition: background 0.4s linear;
      transition: background 0.4s linear; }
      .pricing-plan-select__item--opened:hover, .pricing-plan-select__item--opened:focus {
        background-color: #46aae6;
        border-color: #46aae6;
        color: #ffffff; }
  .pricing-plan-select__trigger {
    width: 40px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer; }
    .pricing-plan-select__trigger:before {
      content: '';
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #ffffff;
      top: 16px;
      left: 10px;
      position: absolute; }

.pricing-plan-item {
  text-align: center; }
  @media screen and (max-width: 992px) {
    .pricing-plan-item {
      margin-top: 15px; } }
  .pricing-plan-item--bottom {
    padding-top: 15px; }
  .pricing-plan-item__name {
    color: rgba(0, 0, 0, 0.5);
    border-bottom: 1px solid #cccccc;
    padding-bottom: 5px; }
  .pricing-plan-item__quality {
    color: rgba(0, 0, 0, 0.5);
    padding: 0; }
    .pricing-plan-item__quality--padding-top {
      padding-top: 15px; }
    .pricing-plan-item__quality--free {
      color: #cccccc; }
    .pricing-plan-item__quality--note {
      margin-top: 7px;
      color: rgba(0, 0, 0, 0.5);
      font-size: 11px; }
    .pricing-plan-item__quality--features {
      padding: 10px 0; }
    .pricing-plan-item__quality.pricing {
      margin-bottom: 35px; }
  .pricing-plan-item__start {
    text-transform: uppercase;
    color: #ffffff;
    padding: 8px 50px;
    border-radius: 3px;
    color: #ffffff;
    background-color: #46e6aa;
    border: 3px solid #46e6aa;
    -webkit-transition: background 0.4s linear;
    -moz-transition: background 0.4s linear;
    -o-transition: background 0.4s linear;
    transition: background 0.4s linear; }
    .pricing-plan-item__start:hover, .pricing-plan-item__start:focus, .pricing-plan-item__start:active, .pricing-plan-item__start:active:focus {
      background-color: #0de592;
      border: 3px solid #0de592;
      color: #ffffff;
      outline: none !important;
      box-shadow: none; }

.pricing-plan__info {
  text-align: center;
  background: #46aae6;
  color: #ffffff;
  padding: 10px;
  margin-top: 15px; }

.pricing--btn {
  font-size: 16px;
  outline: none; }
  @media screen and (max-width: 480px) {
    .pricing--btn {
      font-size: 12px; } }
  .pricing--btn.wide {
    padding: 6px 43px; }
    @media screen and (max-width: 480px) {
      .pricing--btn.wide {
        padding: 6px 36px; } }

.wrapper-3x4 {
  width: 100%;
  margin: 0 auto; }

.mount-3x4 {
  width: 100%;
  position: relative;
  padding-bottom: 133.3333%; }

.image-preview {
  border: 2px solid rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column; }
  .image-preview .button-wrapper {
    margin: 15px auto;
    text-align: center; }
    .image-preview .button-wrapper .btn {
      margin: auto; }

.mount-3x4 > .dropzone,
.mount-3x4 > .image-preview {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%; }

.height-100 {
  height: 100%; }

.width-100 {
  width: 100%; }

.image-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: auto; }

.image-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center; }

.image-wrapper > img {
  margin: auto; }

.button-wrapper {
  margin: auto; }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
html {
  /*
Removed due to FW-886 - hide processing-duration difference on the book

.schedule-element-duration-shadow {
  background: $frz-green-color;
  // background: repeating-linear-gradient(45deg, $frz-green-color, $frz-green-color 1px, $frz-white-color 2px, $frz-white-color 2px);
}
*/ }
  html .noselect {
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  html .fr-artist-checked-block .ui-widget-header {
    border: none; }
  @media (min-width: 992px) {
    html .fr-artist-checked-block {
      width: calc(100% + 15px);
      margin-left: -15px;
      margin-top: 4px; } }
  html .fr-artist-checked-block .fr-artist-header {
    padding: 6px 5px 7px;
    text-align: center; }
  html .fr-artist-checked-block .fr-artist-body {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    max-height: 168px;
    overflow: auto; }
    html .fr-artist-checked-block .fr-artist-body .checkbox {
      padding: 4px 7px;
      margin-top: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0; }
      html .fr-artist-checked-block .fr-artist-body .checkbox:nth-child(even) {
        background-color: #f3f3f3; }
  html .scheduler-component table td {
    border: solid #cccccc thin;
    border-collapse: collapse;
    position: relative;
    background-clip: padding-box; }
  html .scheduler-component .schedule-element {
    position: absolute;
    width: 100%;
    overflow: hidden; }
  html .scheduler-component .duration-shadow-element {
    position: absolute;
    width: 100%;
    overflow: hidden;
    background-color: #46e6aa;
    display: none; }
  html .scheduler-component .schedule-element.schedule-element-dirty {
    border: dotted #ff00ff; }
  html .scheduler-component .schedule-element.schedule-element-layout-minimal .schedule-element-service-name {
    display: none; }
  html .scheduler-component .schedule-element .resize-anchor {
    position: absolute;
    bottom: 0;
    height: 5px;
    width: 100%;
    background-color: #0de592;
    border-top: 1px solid #ffffff;
    cursor: s-resize; }
  html .schedule-td-dnd-highlight {
    opacity: 0.4;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute; }
  html .schedule-td-dnd-highlight-good {
    background-color: #adff2f; }
  html .schedule-td-dnd-highlight-wrong {
    background-color: #ff7f50; }
  html .schedule-td-past {
    background-color: #a9a9a9; }
  html .schedule-td-default {
    background-color: #d3d3d3; }
  html .schedule-td-wh-set {
    background-color: #ffffff; }
  html .schedule-td-dnd-shadow-good {
    border: solid thick #006400 !important; }
  html .schedule-td-dnd-shadow-wrong {
    border: solid thick #8b0000 !important; }
  html .schedule-td-past.schedule-td-dnd-shadow {
    border: solid thick #8b0000 !important; }
  html .schedule-td-default.schedule-td-dnd-shadow {
    border: solid thick #006400 !important; }
  html .schedule-td-wh-set.schedule-td-dnd-shadow {
    border: solid thick #006400 !important; }
  html div.schedule-element.element-status-in_progress {
    background-color: #46aae6 !important; }
  html div.schedule-element.element-status-upcoming {
    background-color: #46e6aa !important; }
  html div.schedule-element.element-status-no_show {
    background-color: #8246e6 !important; }
  html div.schedule-element.element-status-past {
    background-color: #7f7f7f !important; }
  html div.schedule-element.element-status-suspense {
    background-color: #76e29f !important; }
  html .scheduler-component {
    padding: 1em 0;
    width: calc(100% + 15px); }
    html .scheduler-component .inner {
      display: flex; }
      html .scheduler-component .inner .sticky-wrap:first-of-type {
        overflow: hidden; }
      html .scheduler-component .inner .sticky-wrap:last-of-type {
        flex-grow: 100; }
      html .scheduler-component .inner .sticky-wrap.table-time {
        width: 115px;
        min-width: 115px; }
        html .scheduler-component .inner .sticky-wrap.table-time table {
          width: 100%; }
        html .scheduler-component .inner .sticky-wrap.table-time th, html .scheduler-component .inner .sticky-wrap.table-time td {
          min-width: 95px;
          width: 95px;
          text-align: center;
          border-right: none; }
        html .scheduler-component .inner .sticky-wrap.table-time th:first-child {
          border-left: 2px solid #e6e6e6; }
      html .scheduler-component .inner table {
        width: 99%; }
  html .sticky-wrap {
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    display: inline-block;
    vertical-align: top; }
  html .schedule-element {
    box-sizing: border-box;
    background-color: #e0ffff; }
  html .schedule-element .element-body {
    position: relative;
    padding: 3px 12px; }
    html .schedule-element .element-body .notification-badge {
      position: absolute;
      top: 2px;
      right: 2px;
      z-index: 100; }
  html .schedule-element-appointment-info {
    padding-right: 15px;
    overflow: hidden;
    text-overflow: ellipsis; }
  html .sticky-thead {
    left: 0;
    top: 0; }
    html .sticky-thead th {
      background: #777; }
  html .sticky-thead th, html .sticky-enabled th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: #e6e6e6;
    border: 1px solid #ffffff;
    border-bottom: none;
    opacity: 0.95; }
    html .sticky-thead th:after, html .sticky-enabled th:after {
      content: "";
      margin-left: -12px;
      padding-bottom: 12px;
      border-bottom: 3px #46aae6 solid;
      display: table-caption;
      width: 100vw; }
  html .table-events tr th {
    width: 200px; }
    html .table-events tr th:last-child {
      border-right: 2px solid #e6e6e6; }
  html .resize-anchor {
    left: 0; }
  html .sticky-wrap .sticky-thead,
  html .sticky-wrap .sticky-col,
  html .sticky-wrap .sticky-intersect {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .125s ease-in-out;
    z-index: 50;
    width: auto; }
  html .sticky-wrap .sticky-thead {
    z-index: 100;
    width: 100%; }
    html .sticky-wrap .sticky-thead.fixed {
      opacity: 1;
      z-index: 1050; }
  html .sticky-wrap .sticky-intersect {
    opacity: 1;
    z-index: 150; }
  html .sticky-wrap .sticky-intersect th {
    background-color: #777;
    color: #e6e6e6; }
  html .sticky-wrap td,
  html .sticky-wrap th {
    box-sizing: border-box;
    max-width: 100px;
    width: 100px;
    padding: 0 12px;
    height: 26px; }
  html .sticky-wrap th {
    height: 50px;
    text-align: center;
    vertical-align: bottom;
    color: #333;
    font-weight: bold; }
  html td.user-name {
    text-transform: capitalize; }
  html .sticky-wrap.overflow-y {
    overflow-y: auto;
    max-height: 50vh; }

table.inline {
  float: left; }

table span.noselect {
  font-weight: normal; }
  table span.noselect.bold {
    font-weight: bold; }
  table span.noselect.small {
    font-weight: 300; }

.scroll-wrapper {
  display: none; }

.double-scroll {
  overflow: auto;
  height: 16px; }
  .double-scroll .scroll {
    height: 16px; }

html .toggle-calendar.active .toggle-picker {
  max-height: 500px;
  padding: 30px 15px 60px;
  margin-top: 15px; }

html.mobile .form-control, html.tablet .form-control {
  height: 40px; }

html.mobile .glyphicon-remove, html.tablet .glyphicon-remove {
  display: none; }

@media all and (max-width: 1170px) {
  html #tabs-with-dropdown.fixed {
    width: 100%;
    left: 0;
    top: 0; }
    html #tabs-with-dropdown.fixed .grid.row {
      margin: 0; } }

@media all and (max-width: 768px) {
  html #tabs-with-dropdown.fixed {
    top: 0; } }

@media all and (max-width: 767px) and (min-width: 450px) {
  html .attr_name {
    display: inline-block;
    margin-right: 15px;
    min-width: 50px; } }

@media screen and (max-width: 767px) {
  html .glyphicon-remove {
    display: none; }
  html .datepicker_wrapper.toggle-picker {
    width: 320px;
    right: -6px; }
    html .datepicker_wrapper.toggle-picker .range_wrapper {
      max-width: 60%; }
  html .fr-header .fr-navbar {
    margin-bottom: 0; }
  html .fr-header {
    padding-top: 0; }
  html .fr-header-logo {
    height: 34px;
    width: 148px;
    -webkit-background-size: contain;
    background-size: contain;
    margin-top: 8px; }
  html #tabs-with-dropdown.fixed ul.nav-tabs[role="tablist"] {
    margin: 0 -8px;
    padding: 0;
    width: calc(100% + 16px); }
  html .tab-pane.active {
    overflow: visible;
    max-height: 80vh;
    margin-top: 2px; }
  html .tab-pane form {
    margin-bottom: 10px;
    padding: 0 15px; }
  html .tab-pane > div {
    padding-top: 15px; }
    html .tab-pane > div input.dashboard-when {
      width: calc(100% - 30px);
      margin: 0 15px; }
  html .nav-tabs [role="presentation"] > a {
    padding: 5px 30px 10px 45px; }
  html [role="presentation"] > a {
    padding-bottom: 10px; }
  html [role="presentation"] .ui-datepicker-calendar .ui-state-default {
    width: 30px;
    height: 30px;
    font-size: 14px;
    line-height: 25px; }
    html [role="presentation"] .ui-datepicker-calendar .ui-state-default.ui-state-highlight {
      line-height: 22px; } }

@media screen and (max-width: 767px) {
  html .items-filter-title {
    font-size: 24px; } }

@media (max-width: 500px) {
  html .items-filter-title {
    font-size: 24px; } }

.apt-image-link:hover .apt-image {
  display: block; }

.apt-image {
  display: none; }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
.fr-clients__btn-add {
  vertical-align: text-bottom;
  margin-bottom: 0;
  margin-left: 15px;
  border: 2px solid #f3f3f3; }
  .fr-clients__btn-add:hover, .fr-clients__btn-add:focus, .fr-clients__btn-add:active {
    border: 2px solid #0de592; }
  @media only screen and (max-width: 769px) {
    .fr-clients__btn-add {
      float: right; } }

.fr-clients-header {
  margin: 15px -15px 2px 0;
  background-color: #e6e6e6;
  padding: 4px 2px; }
  @media only screen and (max-width: 991px) {
    .fr-clients-header {
      display: none; } }

.fr-table-clients-content {
  background-color: #ffffff;
  line-height: 2;
  margin-left: 0; }

.fr-table__border-bottom {
  border-bottom: 1px dashed #e6e6e6; }

.fr-table-clients__btn {
  margin: 5px 0 5px 0; }

.fr-clients__filter {
  float: right;
  display: inline-block;
  padding: 5px 5px 0 5px;
  margin-top: 17px;
  opacity: 0.99; }
  @media only screen and (max-width: 769px) {
    .fr-clients__filter {
      float: none !important;
      margin-top: 0; } }
  .fr-clients__filter span.glyphicon-search {
    margin-top: -3px;
    color: #ffffff; }
  .fr-clients__filter .input-group-btn {
    z-index: 100; }
    .fr-clients__filter .input-group-btn .fr-clients__reset {
      position: absolute;
      right: 37px;
      border-radius: 0 3px 3px 0 !important;
      padding: 3px 6px 2px 6px;
      z-index: 100; }
      .fr-clients__filter .input-group-btn .fr-clients__reset .glyphicon-remove {
        display: inline-block; }
      .fr-clients__filter .input-group-btn .fr-clients__reset .glyphicon-remove {
        top: 2px; }
      @media only screen and (max-width: 769px) {
        .fr-clients__filter .input-group-btn .fr-clients__reset {
          padding: 3px 6px 2px; } }

.fr-btn-search {
  margin-bottom: 10px;
  margin-left: 5px; }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/0eC6fl06luXEYWpBSJvXCBJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Fl4y0QdOxyyTHEGMXX8kcRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Roboto Condensed';
  src: url("fonts/roboto-condensed-light/RobotoCondensed-Light.woff") format("woff"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.ttf") format("truetype"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.svg#RobotoCondensed-Light") format("svg");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.eot");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.woff2") format("woff2"), url("fonts/roboto-condensed-light/roboto-condensed.light.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("fonts/glyphicons-halflings-regular.woff") format("woff"), url("fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("fonts/glyphicons-halflings-regular.svg#Glyphicons Halflings") format("svg");
  src: url("fonts/glyphicons-halflings-regular.eot");
  src: url("fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

.fr-favorite {
  position: absolute;
  top: 7px;
  right: 7px;
  z-index: 10; }

.fr-favorite-disabled {
  cursor: none;
  pointer-events: none; }

.fr-favorite-large {
  top: 30px;
  right: 15px;
  z-index: 8050; }

.fr-favorite button {
  display: block;
  height: 27px;
  width: 30px;
  border: none;
  outline: none; }

.fr-favorite-marked {
  background: url("img/favorite-marked.svg"), center, no-repeat;
  background-size: 27px 30px;
  -webkit-background-size: 30px 27px;
  -moz-background-size: 30px 27px;
  -o-background-size: 30px 27px;
  padding: 0; }

.fr-favorite-not-marked {
  background: url("img/favorite-not-marked.svg"), center, no-repeat;
  background-size: 30px 27px;
  -webkit-background-size: 30px 27px;
  -moz-background-size: 30px 27px;
  -o-background-size: 30px 27px;
  padding: 0; }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/0eC6fl06luXEYWpBSJvXCBJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Fl4y0QdOxyyTHEGMXX8kcRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Roboto Condensed';
  src: url("fonts/roboto-condensed-light/RobotoCondensed-Light.woff") format("woff"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.ttf") format("truetype"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.svg#RobotoCondensed-Light") format("svg");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.eot");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.woff2") format("woff2"), url("fonts/roboto-condensed-light/roboto-condensed.light.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("fonts/glyphicons-halflings-regular.woff") format("woff"), url("fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("fonts/glyphicons-halflings-regular.svg#Glyphicons Halflings") format("svg");
  src: url("fonts/glyphicons-halflings-regular.eot");
  src: url("fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

.dashboard-gallery__separator {
  font-weight: normal;
  font-size: 1.8vmax;
  padding: 10px 15px; }

.dashboard-what__filter {
  width: 100%;
  background-color: transparent; }
  .dashboard-what__filter-wrap {
    position: absolute;
    bottom: 14px;
    width: 90%;
    left: 0;
    right: 0;
    margin: 0 auto; }
    @media screen and (max-width: 768px) {
      .dashboard-what__filter-wrap {
        position: relative;
        left: auto;
        right: auto;
        bottom: auto;
        width: calc(100% - 30px); } }
  .dashboard-what__filter-dropdown-toggle {
    padding-right: 15px;
    overflow: hidden;
    text-overflow: ellipsis; }
    .dashboard-what__filter-dropdown-toggle:before {
      content: "\E252";
      font-family: "Glyphicons Halflings";
      color: #777;
      position: absolute;
      right: 7px; }
    .dashboard-what__filter-dropdown-toggle:hover, .dashboard-what__filter-dropdown-toggle:focus {
      background-color: #ffffff !important;
      border-color: #cccccc; }
    .dashboard-what__filter-dropdown-toggle > .caret {
      display: none; }
  .dashboard-what__filter-dropdown-toggle-selected {
    padding-right: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #ffffff !important; }
    .dashboard-what__filter-dropdown-toggle-selected:before {
      content: "\E252";
      font-family: "Glyphicons Halflings";
      color: #777;
      position: absolute;
      right: 40px; }
    .dashboard-what__filter-dropdown-toggle-selected > .caret {
      display: none; }

.dashboard-what__menu {
  background: #ffffff;
  border-radius: 7px;
  width: 100%;
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    .dashboard-what__menu {
      width: 100%; } }

.dashboard-what__item {
  background: #ffffff; }
  .dashboard-what__item.active a {
    color: grey; }
    .dashboard-what__item.active a:hover {
      color: black;
      border-left: 2px solid #464646;
      border-right: 2px solid #464646;
      background: #d3d3d3 !important; }
  .dashboard-what__item a {
    padding: 0 0 0 10px !important;
    background: #ffffff !important; }
    .dashboard-what__item a:hover {
      background: #46aae6 !important; }
  .dashboard-what__item:hover {
    background: grey; }

.dashboard-what__close-layer {
  position: absolute;
  top: 10px;
  right: 15px;
  background-color: transparent;
  cursor: pointer;
  display: inline-block; }
  .dashboard-what__close-layer .close {
    font-size: 28px;
    font-weight: bold; }

.dashboard-what__services {
  -webkit-column-width: 100px;
  -moz-column-width: 100px;
  column-width: 100px;
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
  -webkit-column-rule: 0;
  -moz-column-rule: 0;
  column-rule: 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  margin-top: 7px;
  margin-bottom: 10px; }
  .dashboard-what__services label {
    vertical-align: middle; }
  .dashboard-what__services input[type=checkbox] {
    display: none; }
    .dashboard-what__services input[type=checkbox] + label {
      display: inline-flex;
      font-weight: normal;
      cursor: pointer; }
      .dashboard-what__services input[type=checkbox] + label:before {
        background: #ffffff;
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        max-width: 16px;
        min-width: 16px;
        height: 16px;
        max-height: 16px;
        min-height: 16px;
        content: '';
        margin-right: 4px;
        margin-top: 1px;
        border: 1px solid #464646;
        border-radius: 1px;
        line-height: 14px;
        font-size: 11px;
        text-align: center; }
    .dashboard-what__services input[type=checkbox]:checked + label:before {
      content: "\e013";
      font-family: "Glyphicons Halflings";
      color: #464646; }
  .dashboard-what__services input[type=radio] {
    display: none; }

.dashboard-what__service_all {
  cursor: pointer;
  margin-left: 5px;
  position: absolute;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  left: -110%; }
  .dashboard-what__service_all.visible {
    position: relative;
    top: 4px;
    left: 0;
    font-size: 16px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s; }
    .dashboard-what__service_all.visible + button {
      left: 110%;
      position: absolute;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }
  .dashboard-what__service_all + button {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    left: 0;
    position: relative;
    margin-left: 5px;
    cursor: pointer; }

.dashboard-where {
  position: absolute;
  bottom: 14px;
  width: calc(100% - 2px);
  padding: 0 5%;
  margin: 0 auto; }
  @media screen and (max-width: 768px) {
    .dashboard-where {
      top: 0;
      left: auto;
      width: auto;
      right: auto;
      bottom: auto;
      margin: 15px 15px 15px 15px;
      padding-bottom: 15px; } }
  .dashboard-where__autocomplete {
    border: 1px solid #cccccc;
    border-top: none;
    position: absolute;
    width: 100%;
    z-index: 200 !important; }
  .dashboard-where__btns {
    display: table-cell;
    position: absolute;
    vertical-align: middle;
    z-index: 2;
    right: 5%;
    top: 0; }
    .dashboard-where__btns .dashboard-btn__reset {
      position: relative;
      padding: 4px 6px 3px; }
  .dashboard-where__block {
    position: absolute;
    bottom: 14px;
    width: 100%;
    padding: 0 5%;
    margin: 0 auto; }
    @media screen and (max-width: 768px) {
      .dashboard-where__block {
        position: static; } }
  .dashboard-where__map {
    border-radius: 3px 0 0 3px;
    border: 3px solid #46e6aa;
    padding: 4px 6px 3px;
    margin-bottom: 0;
    background: #46e6aa;
    color: #ffffff;
    -webkit-transition: background 0.4s linear;
    -moz-transition: background 0.4s linear;
    -o-transition: background 0.4s linear;
    transition: background 0.4s linear; }
    .dashboard-where__map:hover, .dashboard-where__map:focus, .dashboard-where__map:active {
      outline: none;
      background: #0de592;
      border-color: #0de592 !important;
      color: #ffffff !important; }
  .dashboard-where__nearest {
    position: absolute;
    z-index: 100;
    background: #e6e6e6;
    width: 100%;
    left: 0;
    padding: 5px 5% 12px; }
    .dashboard-where__nearest label {
      font-size: 16px; }
    .dashboard-where__nearest input[type=checkbox] {
      display: none; }
      .dashboard-where__nearest input[type=checkbox] + label {
        cursor: pointer;
        margin: 7px 0 0 2px;
        display: flex;
        font-weight: normal; }
        @media screen and (max-width: 769px) {
          .dashboard-where__nearest input[type=checkbox] + label {
            margin: 7px 0 0 5px; } }
        .dashboard-where__nearest input[type=checkbox] + label:before {
          background: #ffffff;
          display: inline-block;
          vertical-align: middle;
          width: 16px;
          max-width: 16px;
          min-width: 16px;
          height: 16px;
          max-height: 16px;
          min-height: 16px;
          content: '';
          margin-right: 4px;
          margin-top: 1px;
          border: 1px solid #464646;
          border-radius: 1px;
          line-height: 14px;
          font-size: 11px;
          text-align: center; }
      .dashboard-where__nearest input[type=checkbox]:checked + label:before {
        content: "\e013";
        font-family: "Glyphicons Halflings";
        color: #464646; }
    .dashboard-where__nearest input[type=radio] {
      display: none; }
    @media screen and (max-width: 768px) {
      .dashboard-where__nearest {
        width: calc(100% - 3px);
        left: 2px;
        padding: 5px 17px 15px 17px; } }
    .dashboard-where__nearest-wrapper {
      width: 48%; }
  .dashboard-where__input {
    width: 100%; }
    @media screen and (max-width: 768px) {
      .dashboard-where__input {
        margin: 0;
        width: 100%; } }
    .dashboard-where__input__selected {
      width: 100%;
      text-align: left; }
    .dashboard-where__input--menu {
      width: 100%;
      max-height: 300px;
      overflow: auto;
      display: block; }
      .dashboard-where__input--menu li {
        height: auto; }
        .dashboard-where__input--menu li.active {
          color: #46aae6; }
          .dashboard-where__input--menu li.active a {
            background-color: #cccccc; }
            .dashboard-where__input--menu li.active a:focus, .dashboard-where__input--menu li.active a:hover {
              background-color: #cccccc; }
        .dashboard-where__input--menu li a {
          padding: 0 0 0 10px !important;
          background-color: #ffffff; }
      .dashboard-where__input--menu__disabled {
        cursor: not-allowed;
        opacity: 0.6; }
        .dashboard-where__input--menu__disabled a {
          pointer-events: none; }
    .dashboard-where__input:hover, .dashboard-where__input:focus {
      border-radius: 4px; }
  .dashboard-where .dropdown-menu {
    left: 15px;
    width: 90%; }
    @media screen and (max-width: 768px) {
      .dashboard-where .dropdown-menu {
        position: inherit;
        top: 1px;
        left: 0;
        border-top: none;
        width: calc(100% - 30px); } }
    @media screen and (max-width: 480px) {
      .dashboard-where .dropdown-menu {
        position: relative;
        top: 1px;
        left: 0;
        border-top: none;
        width: 100%; } }
  @media screen and (max-width: 768px) {
    .dashboard-where.form-horizontal {
      position: relative;
      margin: 0 auto;
      bottom: 14px;
      width: 100%; } }

.dashboard-when {
  position: absolute;
  bottom: 14px;
  width: 90%;
  left: 0;
  right: 0;
  margin: 0 auto; }
  @media screen and (max-width: 768px) {
    .dashboard-when {
      position: relative;
      left: auto;
      width: auto;
      right: auto;
      bottom: auto; } }
  .dashboard-when .form-control {
    background-color: #ffffff; }
    .dashboard-when .form-control:focus, .dashboard-when .form-control:hover {
      border-radius: 4px; }
  @media screen and (max-width: 768px) {
    .dashboard-when {
      padding: 0 15px; } }
  @media screen and (max-width: 768px) {
    .dashboard-when .dashboard-btn__reset {
      right: 16px;
      top: 1px; } }
  .dashboard-when .dashboard-btn__reset {
    padding: 3px 6px; }

.dashboard-calendar {
  position: relative; }
  .dashboard-calendar__direction {
    cursor: pointer;
    position: absolute;
    top: 14px;
    background: #e6e6e6;
    height: 20px;
    width: 20px;
    border-radius: 50%; }
    .dashboard-calendar__direction:hover {
      background: #e6e6e6; }
    .dashboard-calendar__direction--prev {
      left: 0; }
      .dashboard-calendar__direction--prev:before {
        padding: 2px 2px;
        font-family: "Glyphicons Halflings";
        text-indent: 0;
        content: "\E079";
        display: block;
        font-size: 15px;
        line-height: 18px; }
      .dashboard-calendar__direction--prev:hover {
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px);
        transition-duration: 1s; }
    .dashboard-calendar__direction--next {
      right: 0; }
      .dashboard-calendar__direction--next:before {
        padding: 2px 2px;
        font-family: "Glyphicons Halflings";
        text-indent: 0;
        content: "\E080";
        display: block;
        font-size: 15px;
        line-height: 18px; }
      .dashboard-calendar__direction--next:hover {
        -webkit-transform: translateX(5px);
        transform: translateX(5px);
        transition-duration: 1s; }
    .dashboard-calendar__direction--disabled {
      display: none;
      cursor: default;
      color: #ffffff; }
      .dashboard-calendar__direction--disabled:hover {
        transform: none; }

.dashboard-slider {
  font-weight: normal;
  text-align: center; }

.dashboard-slider__text {
  font-weight: bold;
  margin-bottom: 0; }

.dashboard-who {
  position: absolute;
  bottom: 14px;
  width: 90%;
  left: 0;
  right: 0;
  margin: 0 auto; }
  @media screen and (max-width: 768px) {
    .dashboard-who {
      position: relative;
      left: auto;
      width: auto;
      right: auto;
      bottom: auto;
      margin: 0 15px 15px; } }
  .dashboard-who__input {
    width: 100%; }
    @media screen and (max-width: 768px) {
      .dashboard-who__input {
        margin: 0; } }
    @media screen and (max-width: 640px) {
      .dashboard-who__input {
        margin: 0; } }
    .dashboard-who__input__selected {
      width: 100%;
      text-align: left; }
    .dashboard-who__input--menu {
      width: 100%;
      max-height: 300px;
      overflow: auto;
      display: block; }
      .dashboard-who__input--menu li {
        height: auto; }
        .dashboard-who__input--menu li.active {
          color: grey; }
        .dashboard-who__input--menu li a {
          padding: 0 0 0 10px !important;
          background-color: #ffffff; }
      .dashboard-who__input--menu__disabled {
        cursor: not-allowed;
        opacity: 0.6; }
        .dashboard-who__input--menu__disabled button {
          padding-left: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #333;
          pointer-events: none; }
    .dashboard-who__input:hover, .dashboard-who__input:focus {
      border-radius: 4px; }
  .dashboard-who__list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%; }
    @media screen and (max-width: 768px) {
      .dashboard-who__list {
        position: static; } }
  .dashboard-who .dashboard-btn__reset {
    padding: 3px 6px; }

.dashboard-filter {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 105;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }
  @media screen and (max-width: 768px) {
    .dashboard-filter-what__attrs {
      padding-top: 34px; } }

.dashboard-fix-filter {
  position: fixed;
  top: -2px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 999;
  padding-top: 0; }

.dashboard-btn__reset {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 3px 3px 0;
  padding: 4px 6px;
  z-index: 100; }
  .dashboard-btn__reset:hover, .dashboard-btn__reset:focus, .dashboard-btn__reset:active {
    outline: none;
    background: #0d96e5 !important;
    border-color: #0d96e5 !important;
    color: #ffffff !important; }
  @media screen and (max-width: 768px) {
    .dashboard-btn__reset .glyphicon-remove {
      display: inline-block; } }
  .dashboard-btn__reset .glyphicon {
    top: 2px; }
  .dashboard-btn__reset.brand-select {
    padding: 3px 6px; }

.react-calendar {
  display: flex;
  flex-direction: column;
  border: none;
  background-color: #e6e6e6;
  max-width: 350px; }
  @media screen and (max-width: 768px) {
    .react-calendar {
      max-width: 280px; } }

.react-calendar__navigation {
  margin-bottom: 0; }
  .react-calendar__navigation button:disabled {
    visibility: hidden;
    cursor: default;
    color: #ffffff; }
  .react-calendar__navigation__arrow {
    font-size: 28px;
    font-weight: bold; }
  .react-calendar__navigation__prev-button:hover {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
    transition-duration: 1s; }
  .react-calendar__navigation__next-button:hover {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
    transition-duration: 1s; }
  .react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button {
    display: none; }
  .react-calendar__navigation__label {
    padding: 10px 0;
    text-align: center;
    font-size: 22px;
    cursor: default;
    pointer-events: none; }
    .react-calendar__navigation__label:hover {
      background-color: #e6e6e6; }

.react-calendar__month-view {
  display: flex;
  flex-direction: column;
  border: none;
  background-color: #e6e6e6; }
  .react-calendar__month-view-header {
    padding: 10px 0;
    text-align: center;
    font-size: 22px; }
  .react-calendar__month-view__weekdays {
    font-size: 16px;
    font-weight: 700;
    text-transform: none; }
    .react-calendar__month-view__weekdays__weekday {
      display: inline-block;
      width: 50px;
      text-align: center;
      font-weight: 700;
      justify-content: space-around;
      padding: 10px 0; }
      @media screen and (max-width: 769px) {
        .react-calendar__month-view__weekdays__weekday {
          width: 40px;
          padding: 6px 0; } }

.abbr[data-original-title], abbr[title] {
  border-bottom: none;
  cursor: none;
  text-decoration: none; }

.react-calendar__month-view__days__day {
  display: inline-block;
  width: 50px;
  text-align: center;
  justify-content: space-around;
  padding: 14px 0;
  font-size: 16px;
  border: 2px solid transparent;
  border-radius: 50%;
  -webkit-transition: background 0.4s linear;
  -moz-transition: background 0.4s linear;
  -o-transition: background 0.4s linear;
  transition: background 0.4s linear; }
  .react-calendar__month-view__days__day:hover {
    cursor: pointer;
    background: #ffffff; }
  .react-calendar__month-view__days__day--outside {
    visibility: hidden; }
  .react-calendar__month-view__days__day--current {
    border-radius: 50%;
    background: #ffffff; }
    .react-calendar__month-view__days__day--current.rc-Day--selected {
      border: 2px solid #46aae6; }
  .react-calendar__month-view__days__day--disabled {
    color: #ffffff; }
    .react-calendar__month-view__days__day--disabled:hover {
      background: none;
      cursor: default; }
  .react-calendar__month-view__days__day--selected {
    border: 2px solid #46aae6;
    border-radius: 50%; }
  @media screen and (max-width: 769px) {
    .react-calendar__month-view__days__day {
      width: 40px;
      padding: 8px 0; } }

.react-calendar__tile--active {
  color: #464646;
  background-color: #ffffff;
  border: 2px solid #46aae6 !important;
  border-radius: 50%; }
  .react-calendar__tile--active:focus, .react-calendar__tile--active:enabled:focus {
    background-color: #ffffff; }

.react-calendar__tile--now {
  color: #464646;
  background-color: #ffffff; }

.react-calendar__tile:hover, .react-calendar__tile:enabled:hover {
  background-color: #ffffff; }

.react-calendar__tile:disabled {
  color: #ffffff;
  background-color: #e6e6e6;
  cursor: default; }

.where-map-marker__label {
  position: relative; }
  .where-map-marker__label::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    border-top: 10px solid #ffffff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent; }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/0eC6fl06luXEYWpBSJvXCBJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Fl4y0QdOxyyTHEGMXX8kcRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Roboto Condensed';
  src: url("fonts/roboto-condensed-light/RobotoCondensed-Light.woff") format("woff"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.ttf") format("truetype"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.svg#RobotoCondensed-Light") format("svg");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.eot");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.woff2") format("woff2"), url("fonts/roboto-condensed-light/roboto-condensed.light.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("fonts/glyphicons-halflings-regular.woff") format("woff"), url("fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("fonts/glyphicons-halflings-regular.svg#Glyphicons Halflings") format("svg");
  src: url("fonts/glyphicons-halflings-regular.eot");
  src: url("fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

.fr-landing {
  position: relative;
  left: 0;
  top: -5px;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  width: 100vw; }
  @media screen and (max-width: 992px) {
    .fr-landing {
      top: 5px; } }
  @media screen and (max-width: 992px) {
    .fr-landing .container {
      padding-right: 0;
      padding-left: 0; } }

.row-flex {
  display: flex;
  padding: 15px 0; }
  @media screen and (max-width: 992px) {
    .row-flex {
      display: block; } }
  .row-flex .col-md-6, .row-flex .col-xs-12 {
    margin-top: auto;
    margin-bottom: auto; }
  .row-flex.black {
    background-color: #464646;
    color: #ffffff; }

.fr-landing-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  border-radius: 3px;
  padding: 0 0 5px; }
  .fr-landing-flex__banner {
    margin-top: 18px;
    margin-bottom: 20px;
    padding-right: 8px; }
    .fr-landing-flex__banner h1 {
      margin-top: 15px;
      margin-bottom: 15px;
      font-size: 48px;
      font-weight: 200;
      color: #ffffff; }
      @media screen and (max-width: 769px) {
        .fr-landing-flex__banner h1 {
          font-size: 32px;
          margin-top: 5px;
          margin-bottom: 0; } }
    @media screen and (max-width: 769px) {
      .fr-landing-flex__banner {
        margin-top: 8px;
        margin-bottom: 7px; } }
  .fr-landing-flex__item {
    margin: 15px;
    text-align: center;
    font-size: 24px; }
    @media screen and (max-width: 769px) {
      .fr-landing-flex__item {
        margin: 10px 15px 30px; } }
    .fr-landing-flex__item-title {
      font-size: 48px;
      font-weight: 200;
      color: #464646; }
      @media screen and (max-width: 769px) {
        .fr-landing-flex__item-title {
          padding: 0 10px 5px;
          font-size: 36px;
          line-height: 1; }
          .fr-landing-flex__item-title.top-offset {
            margin-top: 15vh; } }
      .fr-landing-flex__item-title.map {
        padding: 45px 15px; }
      .fr-landing-flex__item-title.inverse {
        color: #ffffff; }
      @media screen and (max-width: 769px) {
        .fr-landing-flex__item-title .first {
          padding: 50px 10px 5px; } }
    .fr-landing-flex__item.align-lg-left {
      float: none;
      text-align: left; }
      @media screen and (max-width: 769px) {
        .fr-landing-flex__item.align-lg-left {
          text-align: center; }
          .fr-landing-flex__item.align-lg-left .img-lg-left {
            float: none; } }
  .fr-landing-flex__item-wide {
    flex: 1 1 calc(100% - 20px);
    margin: 0 -10px;
    box-sizing: border-box;
    min-width: 260px;
    padding-top: 0;
    text-align: center;
    font-size: 24px;
    align-self: center; }
    .fr-landing-flex__item-wide-title {
      font-size: 48px; }
      @media screen and (max-width: 769px) {
        .fr-landing-flex__item-wide-title {
          font-size: 36px; } }
    .fr-landing-flex__item-wide.map {
      padding: 0 15px; }
    .fr-landing-flex__item-wide.blue {
      background-color: #46aae6;
      color: #ffffff; }
    .fr-landing-flex__item-wide.banner-box {
      margin-top: 20vh; }
  .fr-landing-flex img {
    display: block;
    margin: 30px auto;
    height: 90vh;
    width: auto; }
    @media screen and (max-width: 769px) {
      .fr-landing-flex img {
        height: 70vh; } }
  .fr-landing-flex .brand-logo {
    display: block;
    margin: 30px auto;
    height: 10vh;
    width: auto; }
    @media screen and (max-width: 769px) {
      .fr-landing-flex .brand-logo {
        margin: 30px auto;
        height: 15vh; } }

.fr-title-centred {
  text-align: center;
  padding-top: 15px; }
  .fr-title-centred.inverse {
    color: #ffffff; }
  .fr-title-centred.bottom {
    margin-bottom: 15px; }
    @media screen and (max-width: 769px) {
      .fr-title-centred.bottom {
        margin-bottom: 15px; } }

.fr-landing--content-box {
  height: 83vh;
  padding: 20vh 20px 15px;
  text-align: center; }
  @media screen and (max-width: 769px) {
    .fr-landing--content-box {
      height: 75vh;
      padding: 20px 15px; } }
  .fr-landing--content-box.photo-background {
    position: relative;
    background: url("img/pro-landing-1.jpg") center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; }
  .fr-landing--content-box.product-background {
    position: relative;
    background: url("img/frizo-pro-products.jpg") center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; }

.fr-landing--apps {
  margin-top: 50px; }

.fr-link-bottom {
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  bottom: 15vh; }
  @media screen and (max-width: 769px) {
    .fr-link-bottom {
      bottom: 50px; } }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
.pricing__info {
  margin: 0 15px 30px 15px;
  text-align: center;
  background: #46aae6;
  color: #ffffff;
  padding: 10px; }

.fr-pricing-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -15px 0 -15px;
  padding-bottom: 5px; }
  .fr-pricing-flex__item {
    flex: 1 1 290px;
    margin: 10px 15px 10px 15px;
    box-sizing: border-box;
    min-width: 260px;
    padding-top: 5px;
    text-align: center;
    font-size: 14px;
    transition: all 2s ease-out;
    -webkit-transition: all 2s ease-out; }
    @media screen and (max-width: 992px) {
      .fr-pricing-flex__item {
        padding: 15px; } }
    @media screen and (max-width: 769px) {
      .fr-pricing-flex__item.bordered {
        border: 1px solid #cccccc;
        border-radius: 10px; } }
    .fr-pricing-flex__item-title {
      font-size: 24px;
      font-weight: 500; }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
.fr-artist-promo-header {
  position: static;
  top: 0;
  left: 0;
  width: 100%;
  margin-bottom: 0;
  color: #ffffff;
  z-index: 105;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.fr-artist-promo-header--fixed {
  position: fixed;
  top: -2px;
  margin-bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  color: #464646;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 999;
  padding-top: 0; }

.fr-promo--book {
  margin-top: 17px;
  float: right;
  padding: 6px 50px;
  font-size: 18px;
  font-weight: 200; }
  @media screen and (max-width: 769px) {
    .fr-promo--book {
      margin: 5px 0 5px 5px;
      float: right;
      font-size: 14px;
      padding: 6px 12px; } }

.fr-promo-image--cropper {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 72px;
  height: 72px;
  overflow: hidden;
  border-radius: 50%; }
  .fr-promo-image--cropper .avatar {
    position: absolute;
    top: -12px;
    width: 100%; }

.fr-promo-image--glyphicon {
  display: inline-block;
  margin-bottom: 7px;
  color: inherit;
  font-size: 45px; }
  .fr-promo-image--glyphicon .glyphicon {
    margin-right: 10px; }

.fr-artist-promo--error {
  margin-bottom: 6px;
  text-align: center;
  font-size: 48px;
  font-weight: 200; }
  @media screen and (max-width: 641px) {
    .fr-artist-promo--error {
      margin: 0;
      padding: 2px 0 6px 0;
      font-size: 28px;
      color: inherit; } }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/0eC6fl06luXEYWpBSJvXCBJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Fl4y0QdOxyyTHEGMXX8kcRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Roboto Condensed';
  src: url("fonts/roboto-condensed-light/RobotoCondensed-Light.woff") format("woff"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.ttf") format("truetype"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.svg#RobotoCondensed-Light") format("svg");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.eot");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.woff2") format("woff2"), url("fonts/roboto-condensed-light/roboto-condensed.light.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("fonts/glyphicons-halflings-regular.woff") format("woff"), url("fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("fonts/glyphicons-halflings-regular.svg#Glyphicons Halflings") format("svg");
  src: url("fonts/glyphicons-halflings-regular.eot");
  src: url("fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

.fr-invitation {
  position: relative;
  left: 0;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  width: 101vw; }

.fr-invitation-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: calc(100vh - 80px);
  border-radius: 3px;
  padding-bottom: 5px;
  background: center;
  background-size: cover; }
  @media screen and (max-width: 690px) {
    .fr-invitation-flex {
      height: calc(100vh - 50px); }
      .fr-invitation-flex > :nth-child(1) {
        align-self: flex-end; }
      .fr-invitation-flex > :nth-child(3) {
        min-height: calc(100% / 3); } }
  .fr-invitation-flex__item {
    flex: 1 1 320px;
    margin: 10px 15px 10px 15px;
    box-sizing: border-box;
    padding: 5px;
    text-align: center;
    font-size: 18px;
    align-self: flex-start;
    color: #ffffff; }
    .fr-invitation-flex__item-title {
      margin-top: 15px;
      font-size: 18px;
      font-weight: 200; }
      .fr-invitation-flex__item-title .note {
        padding-top: 5px; }
      .fr-invitation-flex__item-title.right {
        float: right;
        padding-right: 30px; }
        @media screen and (max-width: 690px) {
          .fr-invitation-flex__item-title.right {
            padding-right: 0;
            float: none; } }
      .fr-invitation-flex__item-title.left {
        float: left;
        padding-left: 30px; }
        @media screen and (max-width: 690px) {
          .fr-invitation-flex__item-title.left {
            padding-left: 0;
            float: none; } }
      @media screen and (max-width: 690px) {
        .fr-invitation-flex__item-title {
          margin-top: 15px;
          font-size: 18px;
          font-weight: 200; } }
  .fr-invitation-flex__item-wide {
    flex: 1 1 calc(100% - 60px);
    box-sizing: border-box;
    min-width: 260px;
    padding-top: 5px;
    text-align: center;
    font-size: 18px;
    align-self: flex-end;
    color: #ffffff; }
    .fr-invitation-flex__item-wide-title {
      margin-bottom: 40px;
      font-size: 48px;
      font-weight: 200; }
      @media screen and (max-width: 690px) {
        .fr-invitation-flex__item-wide-title {
          margin-bottom: 15px; } }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/0eC6fl06luXEYWpBSJvXCBJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Fl4y0QdOxyyTHEGMXX8kcRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Roboto Condensed';
  src: url("fonts/roboto-condensed-light/RobotoCondensed-Light.woff") format("woff"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.ttf") format("truetype"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.svg#RobotoCondensed-Light") format("svg");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.eot");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.woff2") format("woff2"), url("fonts/roboto-condensed-light/roboto-condensed.light.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("fonts/glyphicons-halflings-regular.woff") format("woff"), url("fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("fonts/glyphicons-halflings-regular.svg#Glyphicons Halflings") format("svg");
  src: url("fonts/glyphicons-halflings-regular.eot");
  src: url("fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

.fr-affiliate {
  position: relative;
  left: 0;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  width: 101vw; }

.fr-affiliate-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-radius: 3px;
  padding-bottom: 5px;
  background: center;
  background-size: cover; }
  .fr-affiliate-flex__item {
    flex: 1 1 calc(100% - 60px);
    margin: 0;
    box-sizing: border-box;
    padding: 5px 15px;
    font-size: 18px;
    align-self: center;
    color: rgba(0, 0, 0, 0.5); }
    .fr-affiliate-flex__item-title {
      margin: 0 15px;
      font-size: 36px;
      font-weight: 200;
      color: #cccccc; }
      @media screen and (max-width: 690px) {
        .fr-affiliate-flex__item-title {
          margin-top: 15px;
          font-size: 24px;
          font-weight: 200; } }
    .fr-affiliate-flex__item.shadow {
      background-color: #464646;
      color: #ffffff;
      align-self: center; }
    .fr-affiliate-flex__item.blue {
      background-color: #0d96e5;
      color: #ffffff;
      align-self: center; }
      .fr-affiliate-flex__item.blue a {
        color: #ffffff; }
      .fr-affiliate-flex__item.blue .content-text {
        padding: 60px 0 80px;
        text-align: center;
        font-size: 32px;
        font-weight: 200; }
        @media screen and (max-width: 690px) {
          .fr-affiliate-flex__item.blue .content-text {
            padding: 30px 15px 40px; } }
  .fr-affiliate-flex__item--reasons {
    flex: 1 1 555px;
    margin: 0;
    box-sizing: border-box;
    min-width: 260px;
    padding-top: 15px;
    text-align: center;
    font-size: 18px;
    align-self: center;
    background-color: #e6e6e6; }
    .fr-affiliate-flex__item--reasons:nth-of-type(3) {
      padding-left: 75px; }
    .fr-affiliate-flex__item--reasons:nth-of-type(4) {
      padding-right: 75px; }
    @media screen and (max-width: 991px) {
      .fr-affiliate-flex__item--reasons {
        padding: 0 30px; } }

@media screen and (max-width: 991px) {
  .fr-affiliate-flex__item--reasons:nth-of-type(3) {
    padding: 0 30px; }
  .fr-affiliate-flex__item--reasons:nth-of-type(4) {
    padding: 0 30px; } }

.fr-item--shadow-title {
  margin: 15px;
  font-size: 56px;
  font-weight: 200; }
  @media screen and (max-width: 690px) {
    .fr-item--shadow-title {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 32px;
      font-weight: 500; } }

.mail-box {
  margin: 24px auto;
  width: 54%;
  border: 1px solid white;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 0 6px #cccccc;
  color: rgba(0, 0, 0, 0.5); }
  @media screen and (max-width: 690px) {
    .mail-box {
      width: 90%; } }
  .mail-box b {
    font-size: 32px;
    font-weight: 200;
    color: #464646; }
  .mail-box .signature {
    text-align: right;
    padding: 8px 15px 0; }

.content-box {
  margin: 0;
  padding: 15px;
  color: #464646;
  text-align: center; }
  @media screen and (max-width: 690px) {
    .content-box {
      margin: 0 5px 0 5px;
      padding: 15px 10px; } }
  .content-box .bold {
    font-weight: 500; }
  .content-box--block {
    text-align: center;
    padding: 30px 0 70px; }
  .content-box.left {
    width: 54%;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
    text-align: left; }
    @media screen and (max-width: 690px) {
      .content-box.left {
        width: 80%; } }

.reasons-box {
  max-width: 1070px;
  margin: 0 auto;
  padding: 0 5px; }
  @media screen and (max-width: 690px) {
    .reasons-box {
      margin: 15px 0 0 5px;
      padding: 0 0 15px 0; } }

.reasons-list {
  text-align: left;
  list-style-type: none; }
  .reasons-list.list-group {
    margin-bottom: 0;
    padding-right: 15px; }
  .reasons-list--center {
    text-align: center; }
    @media screen and (max-width: 991px) {
      .reasons-list--center {
        text-align: inherit;
        padding-left: 25px;
        padding-bottom: 15px; } }

.list-inline-block {
  display: inline-block;
  margin-left: -15px; }
  .list-inline-block b {
    font-weight: 200;
    font-size: 32px; }
  @media screen and (max-width: 690px) {
    .list-inline-block {
      margin-left: 10px; }
      .list-inline-block b {
        line-height: 1.1; } }

.list-group-badge {
  margin: 6px 5px 48px 0;
  font-size: 48px;
  background-color: #46aae6;
  border: 1px solid #ffffff; }
  @media screen and (max-width: 690px) {
    .list-group-badge {
      margin: 8px 5px 35px 0;
      font-size: 48px; } }
  .list-group-badge.title {
    margin-bottom: 10px;
    font-size: 32px; }
    @media screen and (max-width: 690px) {
      .list-group-badge.title {
        margin-bottom: 5px;
        font-size: 32px; } }

.avatar {
  float: left;
  width: 96px;
  height: 96px;
  overflow: hidden;
  margin: 0 15px 35px 0;
  border-radius: 50px; }

.basic-form {
  margin-top: 25px;
  margin-bottom: 15px; }
  .basic-form.fr-btn-more {
    margin-top: 25px;
    padding: 6px 45px;
    font-size: 36px; }

.affiliate-radio {
  display: block;
  margin-top: 0;
  margin-left: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 200; }
  .affiliate-radio.other {
    display: inline-block; }

.radio.affiliate label {
  font-size: 16px;
  font-weight: 200; }

.radio.affiliate input[type=radio] {
  margin-top: 4px; }
  @media screen and (max-width: 690px) {
    .radio.affiliate input[type=radio] {
      margin-top: 2px; } }

.radio.affiliate:first-child {
  margin-top: 25px; }

.affiliate-other {
  display: inline-block;
  width: 93%; }
  @media screen and (max-width: 690px) {
    .affiliate-other {
      width: 92%; } }
  @media screen and (min-width: 1200px) {
    .affiliate-other {
      width: 92%; } }

.recommendation {
  margin: 50px 15px 15px; }
  .recommendation h1 {
    text-align: center;
    font-size: 28px;
    font-weight: 200; }
  .recommendation__msg {
    margin: 50px 15px 15px; }
    .recommendation__msg a {
      font-size: 16px; }

/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/0eC6fl06luXEYWpBSJvXCBJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Fl4y0QdOxyyTHEGMXX8kcRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Roboto Condensed';
  src: url("fonts/roboto-condensed-light/RobotoCondensed-Light.woff") format("woff"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.ttf") format("truetype"), url("fonts/roboto-condensed-light/RobotoCondensed-Light.svg#RobotoCondensed-Light") format("svg");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.eot");
  src: url("fonts/roboto-condensed-light/roboto-condensed.light.woff2") format("woff2"), url("fonts/roboto-condensed-light/roboto-condensed.light.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("fonts/glyphicons-halflings-regular.woff") format("woff"), url("fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("fonts/glyphicons-halflings-regular.svg#Glyphicons Halflings") format("svg");
  src: url("fonts/glyphicons-halflings-regular.eot");
  src: url("fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

.fr-certificate {
  position: absolute;
  top: 15px;
  left: 15px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  color: #ffffff;
  z-index: 99; }
  .fr-certificate a {
    display: inline-block;
    width: 100%;
    height: 100%;
    color: #ffffff; }
  .fr-certificate.stage-10 {
    background: url("img/airtouch-stage-10.svg"), center, no-repeat;
    background-size: 45px 45px; }
  .fr-certificate.stage-20 {
    background: url("img/airtouch-stage-20.svg"), center, no-repeat;
    background-size: 45px 45px; }
  .fr-certificate.stage-30 {
    background: url("img/airtouch-stage-30.svg");
    background-size: 45px 45px; }
  .fr-certificate.stage-40 {
    background: url("img/airtouch-stage-40.svg"), center, no-repeat;
    background-size: 45px 45px; }
  .fr-certificate.stage-99 {
    background: url("img/airtouch-stage-99.svg"), center, no-repeat;
    background-size: 45px 45px; }

.image-modal .fr-certificate {
  top: 25px;
  z-index: 8050; }
