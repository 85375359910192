@import "vars/_colors";
@import "vars/_assets";

.dashboard {
  &-gallery {
    &__separator {
      font-weight: normal;
      font-size: 1.8vmax;
      padding: 10px 15px;
    }
  }
  &-what {
    &__filter {
      width: 100%;
      background-color: transparent;
      &-wrap {
        position: absolute;
        bottom: 14px;
        width: 90%;
        left: 0;
        right: 0;
        margin: 0 auto;
        @media #{$mobile} {
          position: relative;
          left: auto;
          right: auto;
          bottom: auto;
          width: calc(100% - 30px);
        }
      }
      &-dropdown-toggle {
        padding-right: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        &:before {
          content: "\E252";
          font-family: $font-glyphicon;
          color: $logo-primary-color;
          position: absolute;
          right: 7px;
        }
        &:hover, &:focus {
          background-color: $frz-white-color !important;
          border-color: $frz-semi-light-gray-color;
        }
        > .caret {
          display: none;
        }
      }
      &-dropdown-toggle-selected {
        padding-right: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: $frz-white-color !important;
        &:before {
          content: "\E252";
          font-family: $font-glyphicon;
          color: $logo-primary-color;
          position: absolute;
          right: 40px;
        }
        > .caret {
          display: none;
        }
      }
    }
    &__menu {
      background: $frz-white-color;
      border-radius: 7px;
      width: 100%;
      overflow: hidden;
      @media #{$mobile} {
        width: 100%;
      }
    }
    &__item {
      background: $frz-white-color;

      &.active a {
        color: grey;
        &:hover {
          color: black;
          border-left: 2px solid $frz-black-color;
          border-right: 2px solid $frz-black-color;
          background: $schedule-td-default-color !important;
        }
      }
      a {
        padding: 0 0 0 10px !important;
        background: $frz-white-color !important;
        &:hover {
          background: $frz-blue-color !important;
        }
      }
      &:hover {
        background: grey;
      }
    }
    &__close-layer {
      position: absolute;
      top: 10px;
      right: 15px;
      background-color: transparent;
      cursor: pointer;
      display: inline-block;
      .close {
        font-size: 28px;
        font-weight: bold;
      }
    }
    &__services {
      -webkit-column-width: 100px;
      -moz-column-width: 100px;
      column-width: 100px;
      -webkit-column-count: 4;
      -moz-column-count: 4;
      column-count: 4;
      -webkit-column-gap: 30px;
      -moz-column-gap: 30px;
      column-gap: 30px;
      -webkit-column-rule: 0;
      -moz-column-rule: 0;
      column-rule: 0;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      -ms-transition: all 0.5s;
      -o-transition: all 0.5s;
      transition: all 0.5s;
      margin-top: 7px;
      margin-bottom: 10px;
      label {
        vertical-align: middle;
      }
      input {
        &[type=checkbox] {
          display: none;
          + label {
            display: inline-flex;
            font-weight: normal;
            cursor: pointer;
            &:before {
              background: $frz-white-color;
              display: inline-block;
              vertical-align: middle;
              width: 16px;
              max-width: 16px;
              min-width: 16px;
              height: 16px;
              max-height: 16px;
              min-height: 16px;
              content: '';
              margin-right: 4px;
              margin-top: 1px;
              border: 1px solid $basic-gray-color;
              border-radius: 1px;
              line-height: 14px;
              font-size: 11px;
              text-align: center;
            }
          }
          &:checked {
            + label {
              &:before {
                content: "\e013";
                font-family: $font-glyphicon;
                color: $basic-gray-color;
              }
            }
          }
        }
        &[type=radio] {
          display: none;
        }
      }
    }
    &__service_all {
      cursor: pointer;
      margin-left: 5px;
      position: absolute;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      left: -110%;
      &.visible {
        position: relative;
        top: 4px;
        left: 0;
        font-size: $main-font-size;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        + button {
          left: 110%;
          position: absolute;
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          -ms-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s;
        }
      }
      + button {
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        left: 0;
        position: relative;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
  &-where {
    position: absolute;
    bottom: 14px;
    width: calc(100% - 2px);
    padding: 0 5%;
    margin: 0 auto;
    @media #{$mobile} {
      top: 0;
      left: auto;
      width: auto;
      right: auto;
      bottom: auto;
      margin: 15px 15px 15px 15px;
      padding-bottom: 15px;
    }
    &__autocomplete {
      border: 1px solid $frz-semi-light-gray-color;
      border-top: none;
      position: absolute;
      width: 100%;
      z-index: 200 !important;
    }
    &__btns {
      display: table-cell;
      position: absolute;
      vertical-align: middle;
      z-index: 2;
      right: 5%;
      top: 0;
      .dashboard-btn__reset {
        position: relative;
        padding: 4px 6px 3px;
      }
    }
    &__block {
      position: absolute;
      bottom: 14px;
      width: 100%;
      padding: 0 5%;
      margin: 0 auto;
      @media #{$mobile} {
        position: static;
      }
    }
    &__map {
      border-radius: 3px 0 0 3px;
      border: 3px solid $frz-green-color;
      padding: 4px 6px 3px;
      margin-bottom: 0;
      background: $frz-green-color;
      color: $frz-white-color;
      -webkit-transition: background 0.4s linear;
      -moz-transition: background 0.4s linear;
      -o-transition: background 0.4s linear;
      transition: background 0.4s linear;
      &:hover, &:focus, &:active {
        outline: none;
        background: $frz-green-over-color;
        border-color: $frz-green-over-color !important;
        color: $frz-white-color !important;
      }
    }
    &__nearest {
      position: absolute;
      z-index: 100;
      background: $frz-light-gray-color;
      width: 100%;
      left: 0;
      padding: 5px 5% 12px;
      label {
        font-size: $main-font-size;
      }
      input {
        &[type=checkbox] {
          display: none;
          + label {
            cursor: pointer;
            margin: 7px 0 0 2px;
            display: flex;
            font-weight: normal;
            @media screen and (max-width: 769px) {
              margin: 7px 0 0 5px;
            }
            &:before {
              background: $frz-white-color;
              display: inline-block;
              vertical-align: middle;
              width: 16px;
              max-width: 16px;
              min-width: 16px;
              height: 16px;
              max-height: 16px;
              min-height: 16px;
              content: '';
              margin-right: 4px;
              margin-top: 1px;
              border: 1px solid $basic-gray-color;
              border-radius: 1px;
              line-height: 14px;
              font-size: 11px;
              text-align: center;
            }
          }
          &:checked {
            + label {
              &:before {
                content: "\e013";
                font-family: $font-glyphicon;
                color: $basic-gray-color;
              }
            }
          }
        }
        &[type=radio] {
          display: none;
        }
      }
      @media #{$mobile} {
        width: calc(100% - 3px);
        left: 2px;
        padding: 5px 17px 15px 17px;
      }
      &-wrapper {
        width: 48%;
      }
    }
    &__input {
      width: 100%;

      @media #{$mobile} {
        margin: 0;
        width: 100%;
      }
      &__selected {
        width: 100%;
        text-align: left;
      }
      &--menu {
        width: 100%;
        max-height: 300px;
        overflow: auto;
        display: block;
        & li {
          height: auto;
          &.active {
            color: $frz-blue-color;
            a {
              background-color: $frz-semi-light-gray-color;
              &:focus, &:hover {
                background-color: $frz-semi-light-gray-color;
              }
            }
          }
          & a {
            padding: 0 0 0 10px !important;
            background-color: $frz-white-color;
          }
        }
        &__disabled {
          cursor: not-allowed;
          opacity: 0.6;
          a {
            pointer-events: none;
          }
        }
      }
      &:hover, &:focus {
        border-radius: 4px;
      }
    }
    .dropdown-menu {
      left: 15px;
      width: 90%;
      @media screen and (max-width: 768px) {
        position: inherit;
        top: 1px;
        left: 0;
        border-top: none;
        width: calc(100% - 30px);
      }
      @media screen and (max-width: 480px) {
        position: relative;
        top: 1px;
        left: 0;
        border-top: none;
        width: 100%;
      }
    }
    &.form-horizontal {
      @media screen and (max-width: 768px) {
        position: relative;
        margin: 0 auto;
        bottom: 14px;
        width: 100%;
      }
    }
  }
  &-when {
    position: absolute;
    bottom: 14px;
    width: 90%;
    left: 0;
    right: 0;
    margin: 0 auto;
    @media #{$mobile} {
      position: relative;
      left: auto;
      width: auto;
      right: auto;
      bottom: auto;
    }
    .form-control {
      background-color: $frz-white-color;
      &:focus, &:hover {
        border-radius: 4px;
      }
    }
    @media #{$mobile} {
      padding: 0 15px;
    }
    .dashboard-btn__reset {
      @media #{$mobile} {
        right: 16px;
        top: 1px;
      }
    }
    .dashboard-btn__reset {
      padding: 3px 6px;
    }
  }
  &-calendar {
    position: relative;
    &__direction {
      cursor: pointer;
      position: absolute;
      top: 14px;
      background: $frz-light-gray-color;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      &:hover {
        background: $frz-light-gray-color;
      }

      &--prev {
        left: 0;
        &:before {
          padding: 2px 2px;
          font-family: $font-glyphicon;
          text-indent: 0;
          content: "\E079";
          display: block;
          font-size: 15px;
          line-height: 18px;
        }
        &:hover {
          -webkit-transform: translateX(-5px);
          transform: translateX(-5px);
          transition-duration: 1s;
        }
      }
      &--next {
        right: 0;
        &:before {
          padding: 2px 2px;
          font-family: $font-glyphicon;
          text-indent: 0;
          content: "\E080";
          display: block;
          font-size: 15px;
          line-height: 18px;
        }
        &:hover {
          -webkit-transform: translateX(5px);
          transform: translateX(5px);
          transition-duration: 1s;
        }
      }
      &--disabled {
        display: none;
        cursor: default;
        color: $frz-white-color;
        &:hover {
          transform: none;
        }
      }
    }

  }
  &-slider {
    font-weight: normal;
    text-align: center;
  }
  &-slider__text {
    font-weight: bold;
    margin-bottom: 0;
  }
  &-who {
    position: absolute;
    bottom: 14px;
    width: 90%;
    left: 0;
    right: 0;
    margin: 0 auto;
    @media #{$mobile} {
      position: relative;
      left: auto;
      width: auto;
      right: auto;
      bottom: auto;
      margin: 0 15px 15px;
    }
    &__input {
      width: 100%;
      @media #{$mobile} {
        margin: 0;
        //width: 100%;
      }
      @media screen and (max-width: 640px) {
        margin: 0;
      }
      &__selected {
        width: 100%;
        text-align: left;
      }
      &--menu {
        width: 100%;
        max-height: 300px;
        overflow: auto;
        display: block;
        & li {
          height: auto;
          &.active {
            color: grey;
          }
          & a {
            padding: 0 0 0 10px !important;
            background-color: $frz-white-color;

          }
        }
        &__disabled {
          cursor: not-allowed;
          opacity: 0.6;
          button {
            padding-left: 10px;
            font-size: 16px;
            font-weight: 400;
            color: $gray20-color;
            pointer-events: none;
          }
        }
      }
      &:hover, &:focus {
        border-radius: 4px;
      }
    }
    &__list {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      @media #{$mobile} {
        position: static;
      }
    }
    .dashboard-btn__reset {
      padding: 3px 6px;
    }
  }
  &-filter {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: $white-transparent-color;
    z-index: 105;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    &-what {
      &__attrs {
        @media #{$mobile} {
          padding-top: 34px;
        }
      }
    }
  }
  &-fix-filter {
    position: fixed;
    top: -2px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 999;
    padding-top: 0;
  }

  &-btn__reset {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 3px 3px 0;
    padding: 4px 6px;
    z-index: 100;
    &:hover, &:focus, &:active {
      outline: none;
      background: $frz-blue-over-color !important;
      border-color: $frz-blue-over-color !important;
      color: $frz-white-color !important;
    }
    @media #{$mobile} {
      .glyphicon-remove {
        display: inline-block;
      }
    }
    .glyphicon {
      top: 2px;
    }
    &.brand-select {
      padding: 3px 6px;
    }
  }
}

// new Calendar
.react-calendar {
  display: flex;
  flex-direction: column;
  border: none;
  background-color: $frz-light-gray-color;
  max-width: 350px;
  @media screen and (max-width: 768px) {
    max-width: 280px;
  }
}

.react-calendar__navigation {
  margin-bottom: 0;
  button {
    &:disabled {
      visibility: hidden;
      cursor: default;
      color: $frz-white-color;
    }
  }
  &__arrow {
    font-size: 28px;
    font-weight: bold;
  }
  &__prev-button {
    &:hover {
      -webkit-transform: translateX(-5px);
      transform: translateX(-5px);
      transition-duration: 1s;
    }
  }
  &__next-button {
    &:hover {
      -webkit-transform: translateX(5px);
      transform: translateX(5px);
      transition-duration: 1s;
    }
  }
  &__next2-button, &__prev2-button {
    display: none;
  }
  &__label {
    padding: 10px 0;
    text-align: center;
    font-size: 22px;
    cursor: default;
    pointer-events: none;
    &:hover {
      background-color: $frz-light-gray-color;
    }
  }
}

.react-calendar__month-view {
  display: flex;
  flex-direction: column;
  border: none;
  background-color: $frz-light-gray-color;
  &-header {
    padding: 10px 0;
    text-align: center;
    font-size: 22px;
  }
  &__weekdays {
    font-size: 16px;
    font-weight: 700;
    text-transform: none;
    &__weekday {
      display: inline-block;
      width: 50px;
      text-align: center;
      font-weight: 700;
      justify-content: space-around;
      padding: 10px 0;
      @media screen and (max-width: 769px) {
        width: 40px;
        padding: 6px 0;
      }
    }
  }
}

.abbr[data-original-title], abbr[title] {
  border-bottom: none;
  cursor: none;
  text-decoration: none;
}

.react-calendar__month-view__days__day {
  display: inline-block;
  width: 50px;
  text-align: center;
  justify-content: space-around;
  padding: 14px 0;
  font-size: 16px;
  border: 2px solid transparent;
  border-radius: 50%;
  -webkit-transition: background 0.4s linear;
  -moz-transition: background 0.4s linear;
  -o-transition: background 0.4s linear;
  transition: background 0.4s linear;
  &:hover {
    cursor: pointer;
    background: $frz-white-color;
  }
  &--outside {
    visibility: hidden;
  }
  &--current {
    border-radius: 50%;
    background: $frz-white-color;
    &.rc-Day--selected {
      border: 2px solid $frz-blue-color;
    }
  }
  &--disabled {
    color: $frz-white-color;
    &:hover {
      background: none;
      cursor: default;
    }
  }
  &--selected {
    border: 2px solid $frz-blue-color;
    border-radius: 50%;
  }
  @media screen and (max-width: 769px) {
    width: 40px;
    padding: 8px 0;
  }
}

.react-calendar__tile {
  &--active {
    color: $basic-gray-color;
    background-color: $frz-white-color;
    border: 2px solid $frz-blue-color !important;
    border-radius: 50%;
    &:focus, &:enabled:focus {
      background-color: $frz-white-color;
    }
  }
  &--now {
    color: $basic-gray-color;
    background-color: $frz-white-color;
  }
  &:hover, &:enabled:hover {
    background-color: $frz-white-color;
  }
  &:disabled {
    color: $frz-white-color;
    background-color: $frz-light-gray-color;
    cursor: default;
  }
}

.where-map-marker__label {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    border-top: 10px solid $frz-white-color;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
}