@import "./../vars/colors";
@import './../vars/assets';

.datepicker_wrapper {
  overflow: hidden;
  position: relative;
  z-index: 900;
  max-height: 0;
  margin-bottom: 15px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  &.toggle-picker {
    margin-left: -154px;
    margin-top: 20px;
    width: calc(100% + 170px);
    background: $neutral-color;
  }
  #ui-datepicker-div {
    position: static !important;
  }
  &.in {
    max-height: 550px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ui-datepicker-inline {
    text-align: center;
    background: transparent;
    width: 100% !important;
    border: 0;
    .ui-state-hover {
      border: 0;
      &.ui-datepicker-next {
        right: 2px;
        &:hover {
          -webkit-transform: translateX(5px);
          transform: translateX(5px);
          transition-duration: 1s;
        }
      }
      &.ui-datepicker-prev {
        left: 2px;
        &:hover {
          -webkit-transform: translateX(-5px);
          transform: translateX(-5px);
          transition-duration: 1s;
        }
      }
    }
  }
  .ui-datepicker-calendar {
    .ui-datepicker-today {
      .ui-state-highlight {
        background: $calendar-background-color;
        border: 1px solid $calendar-background-color;
        &.ui-state-active {
          border: 1px solid $header-blue-color;
        }
      }
    }
    .ui-state-active {
      background: none;
      color: $calendar-active-color;
      border: 1px solid $header-blue-color;
    }
  }
}

span {
  &.ui-slider-handle.ui-corner-all.ui-state-default {
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    margin: -24px;
    background: $white-default-color;
    border-radius: 50px;
    box-shadow: 0 0 10px $semi-dark-color;
    outline: none;
  }
  &.ui-slider-handle.ui-corner-all.ui-state-default.ui-state-active {
    color: $calendar-active-color !important;
  }
}

.datepicker_wrapper {
  .range_wrapper {
    // все стили времени - сюда
    width: 240px;
    margin: auto;
    input {
      display: none;
    }
    span {
      display: block;
      position: absolute;
    }
  }
  .ui-slider-range {
    + span.ui-slider-handle.ui-corner-all.ui-state-default {
      margin-left: -50px;
      + span.ui-slider-handle.ui-corner-all.ui-state-default {
        margin-right: -50px;
      }
    }
  }
  .ui-widget.ui-widget-content {
    border: none;
  }
  .ui-state-default, .ui-widget-content {
    text-align: center;
  }
}

.glyphicon-time {
  width: 34px;
  height: 34px;
  font-size: 34px;
  color: $form-control-input-color;
}

.calendar-salon-link {
  margin: 30px 0 10px 20px;
  @media screen and (max-width: 480px) {
    float: right;
    margin: 14px 0 0 0;
  }
}

.calendar-nav {
  margin: 22px 15px 10px 2px;
  .btn-default {
    padding: 6px 20px;
    margin: 0 5px 0 0;
    &:last-child {
      margin: 0;
    }
  }
  .btn-default:focus:hover {
    background-color: $light-bg-color;
    border-color: $calendar-btn-hover-color;
  }
  .btn-default:focus {
    background-color: $white-default-color;
    border-color: $light-gray-color;
  }
  a {
    display: inline-block;
    vertical-align: top;
    margin-left: 15px;
  }
  @media (max-width: 784px) {
    display: flex;
    float: none !important;
    clear: both;
    margin: 0 15px 15px 15px;
    text-align: center;
    justify-content: space-between;
    .btn {
      padding: 6px 15px;
      &:nth-of-type(2), &:nth-of-type(3) {
        width: 300px;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.ui-datepicker {
  table {
    table-layout: fixed;
    td {
      position: relative;
      .markers {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
}

.ui-datepicker-calendar {
  td .ui-state-default {
    background-color: transparent;
    border: 1px transparent;
    min-height: 1.5em;
  }
}

.ui-state-default, .ui-widget-content .ui-state-default {
  border: 1px transparent;
  position: relative;
  z-index: 2;
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active {
  background: $calendar-highlight-color;
}

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
  background: $ui-state-highlight-color;
}

.ui-datepicker {
  .ui-datepicker-prev, .ui-datepicker-next {
    cursor: pointer;
    span {
      background: none;
      &:before {
        font-family: $font-glyphicon;
        text-indent: 0;
        content: "\e079";
        display: block;
        font-size: 15px;
        line-height: 18px;
      }
    }
  }
  .ui-datepicker-next {
    span {
      &:before {
        content: "\e080";
      }
    }
  }
  .ui-widget-header {
    border-color: $white-default-color;
  }
}

#tabs-with-dropdown {
  .ui-datepicker {
    .ui-state-default {
      text-align: center;
      border: 0;
    }
  }
  .ui-datepicker-multi-3, .datepicker_wrapper.static {
    .ui-datepicker-group {
      width: 33%;
      display: inline-block;
      vertical-align: top;
      float: none;
      background: $white-default-color;
      margin: 0 0.1%;
      padding: 0 2px 0 0;
    }
    .ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
      background: transparent;
      border: 1px solid $calendar-highlight-color;
      &.ui-state-hover {
        border: 1px solid $calendar-highlight-color;
      }
    }
    .ui-datepicker-prev {
      left: 9px;
    }
    .ui-datepicker-next {
      right: 9px;
    }
    .ui-corner-all {
      top: 2px;
      font-weight: bold;
      //@include transition(all, 0.3s);
    }
  }
  .ui-datepicker-multi-2 {
    .ui-datepicker-group {
      width: 49.9%;
      &:first-of-type {
        margin-right: 0.1%;
      }
      &:first-of-type {
        margin-right: 0.1%;
      }
    }
  }
  [role="presentation"] {
    > input, > select {
      position: absolute;
      bottom: 14px;
      width: 90%;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    > a {
      outline: none;
    }
    .datepicker_wrapper {
      margin-left: -155px;
    }
    .datepicker {
      margin-bottom: 50px;
      .ui-datepicker-title {
        font-size: 22px;
        line-height: 25px;
      }
      .ui-datepicker-header {
        padding: .2em 0 0;
        background-color: transparent;
        border: 0;
        .ui-corner-all {
          border-radius: 50px;
          background: $white-default-color;
          cursor: pointer;
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          -ms-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s;
          .ui-icon {
            &:before {
              line-height: 16px;
            }
          }
          &.ui-datepicker-prev {
            left: 15px;
            top: 0;
            &:hover {
              -webkit-transform: translateX(-5px);
              transform: translateX(-5px);
              transition-duration: 1s;
            }
          }
          &.ui-datepicker-next {
            right: 15px;
            top: 0;
            &:hover {
              -webkit-transform: translateX(5px);
              transform: translateX(5px);
              transition-duration: 1s;
            }
          }
          &.ui-state-hover {
            &.ui-datepicker-next {
              right: 10px;
              top: 0;
            }
            &.ui-datepicker-prev {
              left: 10px;
              top: 0;
            }
            top: 0;
            border: 0;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            -ms-transition: all 0.3s;
            -o-transition: all 0.3s;
            transition: all 0.3s;
            background: $gray20-color;
            .ui-icon {
              color: $white-default-color;
              -webkit-transition: all 0.3s;
              -moz-transition: all 0.3s;
              -ms-transition: all 0.3s;
              -o-transition: all 0.3s;
              transition: all 0.3s;
            }
          }
        }
      }

      .ui-state-disabled {
        > .ui-state-default:not(.ui-slider-handle), > .ui-widget-content .ui-state-default:not(.ui-slider-handle) {
          &:hover {
            background: none;
          }
        }
      }
    }
    .datepicker_wrapper.in {
      padding: 30px 15px 60px;
      margin-top: 15px;
    }
    .ui-datepicker-calendar .ui-state-default {
      background: none;
      text-align: center;
      color: $semi-dark-color;
      font-weight: bold;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      line-height: 45px;
      border: 0;
      transition: all 0.3s;
      font-size: 16px;
      &:hover {
        background: $white-default-color;
        transition: all 0.3s;
      }
    }
    .ui-datepicker-current-day {
      .ui-state-default.ui-state-active {
        background: $white-default-color;
      }
    }
    #slider-range {
      .ui-state-default, .ui-widget-content .ui-state-default {
        transition: none !important;
      }
    }
    .ui-state-default.ui-state-highlight {
      background: transparent;
      border: 2px solid $white-default-color;
      line-height: 42px;
    }
    .ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
      &.ui-state-active {
        background: $white-default-color;
        border: 0;
        line-height: 45px;
      }
    }

  }
}

.reset {
  &.glyphicon {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 17px;
    padding: 9px;
    z-index: 1;
    opacity: 0;
    -webkit-transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    -ms-transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
    transition: opacity 0.5s;
    &.active {
      opacity: 1;
      -webkit-transition: opacity 0.5s;
      -moz-transition: opacity 0.5s;
      -ms-transition: opacity 0.5s;
      -o-transition: opacity 0.5s;
      transition: opacity 0.5s;
    }
  }
}

.block, .book {
  width: 6px;
  height: 6px;
  border-radius: 50px;
  margin: 1px 0 0;
}

.available {
  background-color: $available-color;
  height: 100%;
}

.block {
  background-color: $block-color;
  margin: 5px 5px;
}

.book {
  background-color: $green-default-color;
  margin: 5px 5px;
}
