/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */

// ##COLORS
// Frizo colors, Over colors: HSV, S+20
$frz-blue-color: #46aae6;        // #b6d5e9; // frizo blue // Logo light blue
$frz-blue-over-color: #0d96e5;   // #30A1E8;
$frz-green-color: #46e6aa;       // #76E29F;
$frz-green-over-color: #0de592;  // #30E575;
$frz-red-color: #e64650;
$frz-red-over-color: #e50d1c;
$frz-orange-color: #e6aa46;      // #F7B84F;
$frz-orange-over-color: #e5920d; // #f6a623;
$frz-yellow-color: #e6d246;
$frz-yellow-over-color: #e5c80d;
$frz-purple-color: #8246e6;      // #573280;
$frz-purple-over-color: #5c0de5;
$frz-facebook-color: #4267B2;
$frz-facebook-over-color: #1649AF;

$frz-lightest-blue-color: #F2F7F9;
$frz-lightest-green-color: #F2F9F7;
$frz-lightest-red-color: #F9F2F2;
$frz-lightest-orange-color: #F9F7F2;
$frz-lightest-yellow-color: #F9F8F2;
$frz-lightest-purple-color: #F5F2F9;

$frz-white-color: #ffffff; // main site bg
$frz-black-color: #464646;
$frz-black-over-color: #2D2D2D; // RGB-25

$frz-gray-color: #999999;
$frz-semi-light-gray-color: #cccccc;
$frz-light-gray-color: #e6e6e6;
$frz-lightest-gray-color: #f3f3f3;

// brand colors
$logo-primary-color: #777; // Logo dark grey
$basic-gray-color: $frz-black-color; //#616161;

// text-colors
$blue-emphasis-color: $frz-blue-color; // #74BBE8;
$form-control-input-color: #555;

// background-colors
$semi-dark-color: rgba(0, 0, 0, 0.5); //ex. above map

$danger-color: #e8b7d5; // frizo red
$gray20-color: #333;

$white-transparent-color: rgba(255, 255, 255, 0.7);
$apt-done-color: #d8d8d8;

// Calendar component // TODO refactor
$calendar-background-color: #B5D5EB;
$calendar-btn-hover-color: #adadad;
$light-bg-color: $frz-light-gray-color;
$light-gray-color: $frz-semi-light-gray-color;
$calendar-highlight-color: $frz-blue-color;
$block-color: $frz-gray-color;
$neutral-color: $frz-blue-color;
$header-blue-color: $frz-blue-color;
$calendar-active-color: $frz-black-color;
$white-default-color: $frz-white-color;
$green-default-color: $frz-green-color;

$schedule-element-bg-color: #e0ffff;
$schedule-component-dirty-color: #ff00ff;
$schedule-td-highlight-good-color: #adff2f;
$schedule-td-highlight-wrong-color: #ff7f50;
$schedule-td-past-bg-color: #a9a9a9;
$schedule-td-default-color: #d3d3d3;
$schedule-td-shadow-good-color: #006400;
$schedule-td-past-shadow-color: #8b0000;
$schedule-element-status-past-color: #7f7f7f;
$schedule-element-status-suspense-color: #76e29f; //  TJ #f08080;

$clock-color: #b6d4e9;
$clock-after-color: #b6d4ff;
$available-color: #ddffdd;
$ui-state-highlight-color: #fffa90;
