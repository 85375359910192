@import "../vars/colors";
@import "../vars/assets";

.fr-landing {
  position: relative;
  left: 0;
  top: -5px;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  width: 100vw;
  @media screen and (max-width: 992px) {
    top: 5px;
  }
  .container {
    @media screen and (max-width: 992px) {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.row-flex {
  display: flex;
  padding: 15px 0;
  @media screen and (max-width: 992px) {
    display: block;
  }
  .col-md-6, .col-xs-12 {
    margin-top: auto;
    margin-bottom: auto;
  }
  &.black {
    background-color: $frz-black-color;
    color: $frz-white-color;
  }
}

.fr-landing-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  border-radius: 3px;
  padding: 0 0 5px;
  &__banner {
    margin-top: 18px;
    margin-bottom: 20px;
    padding-right: 8px;
    h1 {
      margin-top: 15px;
      margin-bottom: 15px;
      font-size: 48px;
      font-weight: 200;
      color: $frz-white-color;
      @media screen and (max-width: 769px) {
        font-size: 32px;
        margin-top: 5px;
        margin-bottom: 0;
      }
    }
    @media screen and (max-width: 769px) {
      margin-top: 8px;
      margin-bottom: 7px;
    }
  }
  &__item {
    margin: 15px;
    text-align: center;
    font-size: 24px;
    @media screen and (max-width: 769px) {
      margin: 10px 15px 30px;
    }
    &-title {
      font-size: 48px;
      font-weight: 200;
      color: $frz-black-color;
      @media screen and (max-width: 769px) {
        padding: 0 10px 5px;
        font-size: 36px;
        line-height: 1;
        &.top-offset {
          margin-top: 15vh;
        }
      }
      &.map {
        padding: 45px 15px;
      }
      &.inverse {
        color: $frz-white-color;
      }
      .first {
        @media screen and (max-width: 769px) {
          padding: 50px 10px 5px;
        }
      }
    }
    &.align-lg-left {
      float: none;
      text-align: left;
      .img-lg-left {
      }
      @media screen and(max-width: 769px) {
        text-align: center;
        .img-lg-left {
          float: none;
        }
      }
    }
  }
  &__item-wide {
    flex: 1 1 calc(100% - 20px);
    margin: 0 -10px;
    box-sizing: border-box;
    min-width: 260px;
    padding-top: 0;
    text-align: center;
    font-size: 24px;
    align-self: center;
    &-title {
      font-size: 48px;
      @media screen and (max-width: 769px) {
        font-size: 36px;
      }
    }
    &.map {
      padding: 0 15px;
    }
    &.blue {
      background-color: $frz-blue-color;
      color: $frz-white-color
    }

    &.banner-box {
      margin-top: 20vh;
    }
  }
  img {
    display: block;
    margin: 30px auto;
    height: 90vh;
    width: auto;
    @media screen and (max-width: 769px) {
      height: 70vh;
    }
  }
  .brand-logo {
    display: block;
    margin: 30px auto;
    height: 10vh;
    width: auto;
    @media screen and (max-width: 769px) {
      margin: 30px auto;
      height: 15vh;
    }
  }
}

.fr-title-centred {
  text-align: center;
  padding-top: 15px;
  &.inverse {
    color: $frz-white-color;
  }
  &.bottom {
    margin-bottom: 15px;
    @media screen and (max-width: 769px) {
      margin-bottom: 15px;
    }
  }
}

.fr-landing--content-box {
  height: 83vh;
  padding: 20vh 20px 15px;
  text-align: center;
  @media screen and (max-width: 769px) {
    height: 75vh;
    padding: 20px 15px;
  }
  &.photo-background {
    position: relative;
    background: $pro-landing-1 center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  &.product-background {
    position: relative;
    background: $pro-product-background center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

.fr-landing--apps {
  margin-top: 50px;
}

.fr-link-bottom {
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  bottom: 15vh;
  @media screen and(max-width: 769px) {
    bottom: 50px;
  }
}