@import 'vars/_colors';
@import 'vars/_assets.scss';

html {
  #tabs-with-dropdown {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    position: static;
    top: -100px;
    &.fixed {
      -webkit-transition: all 0.5s ease-in-out;
      -ms-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      position: fixed;
      top: -1px;
      width: 1140px;
      margin: auto;
      z-index: 100;
      overflow: visible;
      .clearfix, .tab-content {
        background: $frz-white-color;
        &.fr-filter-panel {
          background-color: $frz-blue-color;
        }
      }
    }
    .grid {
      margin-top: 0;
    }
  }
  .tab-content {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    overflow: visible;
    position: absolute;
    z-index: 20;
    width: calc(100% + 1px);
    top: 100%;
    margin-top: -2px;
    left: -1px;
    > .tab-pane {
      display: block;
    }
  }
  .tab-pane {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    display: block;
    visibility: hidden;
    > div {
      margin-top: 2px;
    }
    .what-tab-select {
      padding-bottom: 15px;
      @media screen and (min-width: 767px) {
        padding-bottom: 0;
      }
    }
    &.active {
      z-index: 333;
      margin-left: 2px;
      padding-right: 1px;
      visibility: visible;
      -webkit-transition: all 0.5s 0.5s;
      -moz-transition: all 0.5s 0.5s;
      -ms-transition: all 0.5s 0.5s;
      -o-transition: all 0.5s 0.5s;
      transition: all 0.5s 0.5s;
      display: block;
      max-height: 500px;
      margin-bottom: 2px;
      .services-filter {
        &.hide {
          -webkit-transition: all 0.5s 0.5s;
          -moz-transition: all 0.5s 0.5s;
          -ms-transition: all 0.5s 0.5s;
          -o-transition: all 0.5s 0.5s;
          transition: all 0.5s 0.5s;
          max-height: 500px;
        }
      }
      @media screen and (max-width: 1024px) {
        margin-top: 2px;
      }
    }
  }
  .attr_name, .services_head {
    font-size: 24px;
    margin-top: 7px;
    //font-weight: bold;
    font-family: $font-main;
    text-transform: capitalize;
  }
  .services-filter {
    .btn-group {
      label {
        text-transform: capitalize;
      }
    }
  }
  .toggle_popular {
    > a {
      margin: 10px 0 0 10px;
      display: inline-block;
    }
  }
  select {
    &.placeholder {
      color: $frz-gray-color;
      option {
        color: $basic-gray-color;
      }
    }
  }
}

.who-tab-select {
  width: 25%;
  margin-left: 50%;
  @media screen and (max-width: 641px) {
    padding-top: 15px;
  }
}

@media screen and (max-width: 1024px) {
  .who-tab-select {
    // width: 50%;
    padding: 0;
    width: 100%;
    margin-left: 0;
  }
  .services-filter {
    max-height: 100% !important;
  }
  html .tab-pane > div {
    margin-top: 0;
  }

  html .tab-pane > div input.dashboard-who {
    width: calc(100% - 30px);
    margin: 15px 0 15px 15px;
  }
  html .tab-pane.tab-who > div select {
    width: 100%;
    margin: 0;
  }

}
