@import "../vars/_colors";
.gallery-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  color: $basic-gray-color;
  &__block {
    font-size: 30px;
    align-self: flex-start;
  }
}
.gallery-end-message {
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: $frz-semi-light-gray-color;
}

.screen-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  margin-top: 80px;
  &__block {
    align-self: flex-start;
    color: $basic-gray-color;
    &-content {
      font-size: 30px;
      font-weight: 200;
      text-align: center;
    }
  }
}
