@import "./../vars/colors";
html {
  .form-group {
    .main-selects, .form-control {
      margin-bottom: 10px;
    }
    .form-control {
      font-size: 16px;
      @media only screen and (max-width: 769px) {
        height: 40px;
        font-size: 16px;
      }
    }
    .form-control:disabled {
        -webkit-text-fill-color: $form-control-input-color;
      }
  }
  .hint-msg {
    margin-top: -12px;
    font-size: 12px;
  }
}