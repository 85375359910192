@import "../vars/colors";

.pricing__info {
  margin: 0 15px 30px 15px;
  text-align: center;
  background: $frz-blue-color;
  color: $frz-white-color;
  padding: 10px;
}

.fr-pricing-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -15px 0 -15px;
  padding-bottom: 5px;
  &__item {
    flex: 1 1 290px;
    margin: 10px 15px 10px 15px;
    box-sizing: border-box;
    min-width: 260px;
    padding-top: 5px;
    text-align: center;
    font-size: 14px;
    transition: all 2s ease-out;
    -webkit-transition: all 2s ease-out;
    @media screen and (max-width: 992px) {
      //display: none;
      padding: 15px;
    }
    &.bordered {
      @media screen and (max-width: 769px) {
        border: 1px solid $frz-semi-light-gray-color;
        border-radius: 10px;
      }
    }
    &-title {
      font-size: 24px;
      font-weight: 500;
    }
  }
}
