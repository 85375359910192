@import  '../vars/assets';
.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  &__gif {
    position: absolute;
    top: 8px;
    right: 110px;
    opacity: 0.5;
    background: $spinner-gif no-repeat top left;
    background-size: 50px 50px;
    height: 50px;
    width: 50px;
  }
}
