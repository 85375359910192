@import './vars/colors';

html {
  .noselect {
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .fr-artist-checked-block {
    .ui-widget-header {
      //border-radius: 3px;
      border: none;
    }
    @media (min-width: 992px) {
      width: calc(100% + 15px);
      margin-left: -15px;
      margin-top: 4px;
    }
    .fr-artist-header {
      //border-radius: 3px;
      padding: 6px 5px 7px;
      text-align: center;
    }
    .fr-artist-body {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0;
      max-height: 168px;
      overflow: auto;
      .checkbox {
        padding: 4px 7px;
        margin-top: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
        &:nth-child(even) {
          background-color: $frz-lightest-gray-color;
        }
      }
    }
  }
  //start journal
  .scheduler-component table td {
    border: solid $frz-semi-light-gray-color thin;
    border-collapse: collapse;
    position: relative;
    background-clip: padding-box;
  }

  .scheduler-component .schedule-element {
    position: absolute;
    width: 100%;
    overflow: hidden;
  }

  .scheduler-component .duration-shadow-element {
    position: absolute;
    width: 100%;
    overflow: hidden;
    background-color: $frz-green-color;
    display: none;
  }

  .scheduler-component .schedule-element.schedule-element-dirty {
    border: dotted $schedule-component-dirty-color;
  }

  .scheduler-component .schedule-element.schedule-element-layout-minimal .schedule-element-service-name {
    display: none;
  }

  .scheduler-component .schedule-element .resize-anchor {
    position: absolute;
    bottom: 0;
    height: 5px;
    width: 100%;
    background-color: $frz-green-over-color;
    border-top: 1px solid $frz-white-color;
    cursor: s-resize;
  }

  .schedule-td-dnd-highlight {
    opacity: 0.4;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .schedule-td-dnd-highlight-good {
    background-color: $schedule-td-highlight-good-color;
  }

  .schedule-td-dnd-highlight-wrong {
    background-color: $schedule-td-highlight-wrong-color;
  }

  .schedule-td-past {
    background-color: $schedule-td-past-bg-color;
  }

  .schedule-td-default {
    background-color: $schedule-td-default-color;
  }

  .schedule-td-wh-set {
    background-color: $frz-white-color;
  }

  .schedule-td-dnd-shadow-good {
    border: solid thick $schedule-td-shadow-good-color !important;
  }

  .schedule-td-dnd-shadow-wrong {
    border: solid thick $schedule-td-past-shadow-color !important;
  }

  .schedule-td-past.schedule-td-dnd-shadow {
    border: solid thick $schedule-td-past-shadow-color !important;
  }

  .schedule-td-default.schedule-td-dnd-shadow {
    border: solid thick $schedule-td-shadow-good-color !important;
  }

  .schedule-td-wh-set.schedule-td-dnd-shadow {
    border: solid thick $schedule-td-shadow-good-color !important;
  }

  /*
Removed due to FW-886 - hide processing-duration difference on the book

.schedule-element-duration-shadow {
  background: $frz-green-color;
  // background: repeating-linear-gradient(45deg, $frz-green-color, $frz-green-color 1px, $frz-white-color 2px, $frz-white-color 2px);
}
*/

  div.schedule-element.element-status-in_progress {
    background-color: $frz-blue-color !important;
  }

  div.schedule-element.element-status-upcoming {
    background-color: $frz-green-color !important;
  }

  div.schedule-element.element-status-no_show {
    background-color: $frz-purple-color !important;
  }

  div.schedule-element.element-status-past {
    background-color: $schedule-element-status-past-color !important;
  }

  div.schedule-element.element-status-suspense {
    background-color: $schedule-element-status-suspense-color !important;
  }

  //end journal
  .scheduler-component {
    padding: 1em 0;
    width: calc(100% + 15px);
    //max-height: calc(100vh - 300px);
    //overflow-y: auto;
    .inner {
      display: flex;
      .sticky-wrap {
        &:first-of-type {
          overflow: hidden;
        }
        &:last-of-type {
          flex-grow: 100;
        }
        &.table-time {
          width: 115px;
          min-width: 115px;
          table {
            width: 100%;
          }
          th, td {
            min-width: 95px;
            width: 95px;
            text-align: center;
            border-right: none;
          }
          th:first-child {
            border-left: 2px solid $frz-light-gray-color;
          }
        }
      }
      table {
        width: 99%;
      }
    }
  }
  .sticky-wrap {
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    display: inline-block;
    vertical-align: top;
  }
  .schedule-element {
    box-sizing: border-box;
    background-color: $schedule-element-bg-color;
  }
  .schedule-element .element-body {
    position: relative;
    padding: 3px 12px;
    .notification-badge {
      position: absolute;
      top: 2px;
      right: 2px;
      z-index: 100;
    }
  }
  .schedule-element-appointment-info {
    padding-right: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .sticky-thead {
    left: 0;
    top: 0;
    th {
      background: $logo-primary-color;
    }
  }
  .sticky-thead, .sticky-enabled {
    th {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background: $frz-light-gray-color;
      border: 1px solid $frz-white-color;
      border-bottom: none;
      opacity: 0.95;
      //border-bottom: 3px $frz-blue-color solid;
      &:after { //pseudo border-bottom
        content: "";
        margin-left: -12px;
        padding-bottom: 12px;
        border-bottom: 3px $frz-blue-color solid;
        display: table-caption;
        width: 100vw;
      }
    }
  }
  .table-events {
    tr {
      th {
        width: 200px;
        &:last-child {
          border-right: 2px solid $frz-light-gray-color;
        }
      }
    }
  }
  .resize-anchor {
    left: 0;
  }
  .sticky-wrap .sticky-thead,
  .sticky-wrap .sticky-col,
  .sticky-wrap .sticky-intersect {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .125s ease-in-out;
    z-index: 50;
    width: auto;
  }
  .sticky-wrap .sticky-thead {
    //box-shadow: 0 0.25em 0.1em -0.1em rgba(0,0,0,.125);
    z-index: 100;
    width: 100%;
    &.fixed {
      opacity: 1;
      z-index: 1050;
    }
  }
  .sticky-wrap .sticky-intersect {
    opacity: 1;
    z-index: 150;

  }
  .sticky-wrap .sticky-intersect th {
    background-color: $logo-primary-color; //TODO candidate for removal
    color: $frz-light-gray-color; //TODO candidate for removal
  }
  .sticky-wrap td,
  .sticky-wrap th {
    box-sizing: border-box;
    max-width: 100px;
    width: 100px;
    padding: 0 12px;
    height: 26px;
  }
  .sticky-wrap th {
    height: 50px;
    text-align: center;
    vertical-align: bottom;
    color: $gray20-color;
    font-weight: bold;
  }
  td.user-name {
    text-transform: capitalize;
  }
  .sticky-wrap.overflow-y {
    overflow-y: auto;
    max-height: 50vh;
  }
}

table {
  &.inline {
    float: left;
  }
  span {
    &.noselect {
      font-weight: normal;
      &.bold {
        font-weight: bold;
      }
      &.small {
        font-weight: 300;
      }
    }
  }
}

.scroll-wrapper {
  display: none;
}

.double-scroll {
  overflow: auto;
  height: 16px;
  .scroll {
    height: 16px;
  }
}
