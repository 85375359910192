@import "./../vars/colors";
@import "./../vars/mixins";
// ##BUTTONS

.btn {
  font-size: $main-font-size;
  &.active {
    &:focus {
      outline: none;
    }
  }
}

.fr-btn-more {
  margin: 30px 0 10px;
  text-transform: uppercase;
  @include button-bg($frz-green-color, $frz-green-over-color);
  background-color: $frz-green-color; //USE fr-btn
  border: 3px solid $frz-green-color; //USE fr-btn
  color: $frz-white-color; //USE fr-btn
  &.hidden {
    display: block !important;
    visibility: hidden !important;
  }
  &:hover {
    background-color: $frz-green-over-color;
    border-color: $frz-green-over-color;
    color: $frz-white-color;
  }
}

.fr-btn { // DEFAULT BUTTON
  //@extend .btn;
  background-color: $frz-blue-color;
  border: 3px solid $frz-blue-color;
  color: $frz-white-color;
  padding: 4px 12px;
  margin-bottom: 10px;
  @include button-bg($frz-blue-color, $frz-blue-over-color);
  &:hover, &:active, &:focus {
    background-color: $frz-blue-over-color;
    border: 3px solid $frz-blue-over-color;
    color: $frz-white-color;
  }
  &:disabled:hover {
    background-color: $frz-white-color;
    border: 3px solid $frz-blue-color;
    color: $frz-blue-color;
  }
  &-small {
    padding: 3px 6px;
  }
}

.btn-link {
  &:focus, &:active {
    outline: 0 !important;
    text-decoration: none !important;
    background-color: inherit !important;
    border-color: transparent !important;
  }
  &.appointment-filter {
    padding-top: 0;
  }
  &.no-card {
    padding-right: 0;
    padding-left: 0;
    font-weight: bold;
  }
  &.full-width {
    width: 100%;
  }
}

.fr-btn-danger {
  background-color: $frz-red-color !important;
  border: 3px solid $frz-red-color;
  &:hover, &:active, &:focus {
    background-color: $frz-red-over-color !important;
    border-color: $frz-red-over-color;
    outline: 0;
  }
}

.fr-btn-remove {
  background-color: $frz-red-color;
  border: 3px solid $frz-red-color;
  @include button-bg($frz-red-color, $frz-red-over-color);
  color: $frz-white-color;
  &:hover {
    background-color: $frz-red-over-color;
    border: 3px solid $frz-red-over-color;
    color: $frz-white-color;
  }
}

.fr-btn-edit {
  @include button-bg($frz-blue-color, $frz-blue-over-color);
}

.fr-btn-book, .fr-btn-add {
  @include button-bg($frz-green-color, $frz-green-over-color);
  &:disabled, &:disabled:hover {
    background-color: $frz-semi-light-gray-color;
    border: 1px solid $frz-semi-light-gray-color;
    color: $gray20-color;
    outline: none !important;
  }
}

.fr-link {
  color: $frz-white-color;
  &:hover {
    color: $frz-white-color;
    cursor: pointer;
  }
  &:visited, &:active, &:focus {
    color: $frz-white-color;
  }
}

.fr-btn-serv-save {
  background-color: $frz-blue-color;
  border-color: $frz-blue-color;
  color: $frz-white-color;
  @include button-bg($frz-blue-color, $frz-blue-over-color);
  &:hover {
    background-color: $frz-blue-over-color !important;
    border-color: $frz-blue-over-color;
    color: $frz-white-color;
  }
}

.fr-btn-dismiss {
  &:hover {
    background-color: $frz-blue-over-color !important;
    border-color: $frz-blue-over-color;
    color: $frz-white-color;
  }
}

.fr-btn-serv-edit {
  &:hover {
    background-color: $frz-blue-over-color !important;
    border-color: $frz-blue-over-color;
    color: $frz-white-color;
  }
}

.fr-btn-serv-remove {
  color: $frz-red-color;
  &:hover {
    color: $frz-red-over-color;
  }
}

.fr-btn-onoff .btn-default:first-child {
  //background-color:green;
  &:active {
    color: $frz-white-color;
    background-color: $frz-green-color;
  }
  &.active {
    color: $frz-white-color;
    background-color: $frz-green-color;
  }
  &:hover {
    background-color: $frz-green-over-color;
  }
}

.fr-btn-onoff .btn-default:last-child {
  //background-color:red;
  &:active {
    background-color: $frz-red-color;
    color: $frz-white-color;
  }
  &.active {
    background-color: $frz-red-color;
    color: $frz-white-color;
  }
  &:hover {
    background-color: $frz-red-over-color;
    color: $frz-white-color;
  }
}

.fr-btn-routed .btn-default {
  &:active {
    background-color: $frz-blue-color;
  }
  &:active {
    background-color: $frz-blue-color;
  }
}

// buttons inside input
.fr-btn-with-input .input-group-btn > .btn {
  border-left-width: 0;
  left: -2px;
  padding-bottom: 2px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.fr-btn-with-input .form-control:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
  border-color: $frz-semi-light-gray-color;
}

.fr-btn-with-input {
  margin-bottom: 15px;
}

.fr-uploader-remove-btn, .fr-link-delete {
  @include bg-transition;
  color: $frz-red-color;
  &:hover {
    color: $frz-red-over-color;
  }
}

.fr-big-button {
  padding: 7px 50px;
}

.fr-btn-update, .fr-btn-apply {
  color: $frz-white-color;
  background-color: $frz-blue-color;
  @include bg-transition;
  &:hover {
    background-color: $frz-blue-over-color;
    color: $frz-white-color;
  }
  &.address {
    padding: 3px 12px 3px !important;
    border: 3px solid $frz-blue-color;
    box-shadow: none !important;
    &:hover, &:active, &:focus {
      color: $frz-white-color;
      background-color: $frz-blue-over-color;
      border: 3px solid $frz-blue-over-color;
      border-left-width: 0;
    }
    @media screen and (max-width: 769px) {
      padding-top: 3px !important;
    }
  }
}

.fr-btn-apply {
  padding: 4px 38px;
  @media only screen and (max-width: 769px) {
    //padding: 9px 12px;
  }
}

.fr-btn-dropdown {
  text-align: center;
  @include bg-transition;
  color: $frz-white-color;
  background-color: $frz-blue-color !important;
  border: 3px solid $frz-blue-color;
  border-radius: 4px !important;
  padding: 6px 15px 7px 15px;
  &:hover, &:focus {
    background-color: $frz-blue-over-color !important;
    border-color: $frz-blue-over-color !important;
    color: $frz-white-color !important;
    border: 3px solid $frz-blue-over-color;
    text-decoration: none;
  }
  > .fr-span-complete {
    margin-right: 5px;
    height: 25px;
    width: 25px;
  }
  > .fr-span-fully-incomplete {
    margin-right: 5px;
    height: 25px;
    width: 25px;
    background: $badge-red no-repeat top left;
    background-size: 25px 25px;
  }
  > .fr-span-incomplete {
    margin-right: 5px;
    height: 25px;
    width: 25px;
    background: $badge-orange no-repeat top left;
    background-size: 25px 25px;
  }
}

.fr-btn-big__green {
  @include button-bg($frz-green-color, $frz-green-over-color);
  font-size: 18px;
  &.client {
    padding-left: 55px;
    padding-right: 55px;
  }
  &.professional {
    padding-left: 21px;
    padding-right: 21px;
  }
  &.landing {
    font-size: 24px;
  }
}

.fr-btn-address-remove {
  padding: 4px 12px;
  @include button-bg($frz-red-color, $frz-red-over-color);
  color: $frz-white-color;
}

.fr-btn-go-up {
  position: fixed;
  bottom: 50px;
  right: 10px;
  opacity: 0;
  border: 3px solid $frz-blue-color;
  color: $frz-white-color;
  background-color: $frz-blue-color;
  padding: 4px 10px;
  visibility: hidden;
  overflow: hidden;
  text-align: center;
  z-index: 11;
  -webkit-box-shadow: 3px 3px 8px 2px $frz-gray-color;
  -moz-box-shadow: 3px 3px 8px 2px $frz-gray-color;
  box-shadow: 3px 3px 8px 2px $frz-gray-color;
  transform: translate3d(0, 0, 0);
  @include button-bg($frz-blue-color, $frz-blue-over-color);
  &:active, &:focus, &:hover {
    border: 3px solid $frz-blue-over-color;
    color: $frz-white-color;
    background-color: $frz-blue-over-color;
  }
}

.fr-btn-go-up.show {
  visibility: visible;
  cursor: pointer;
  opacity: 1;
}

.fr-serv-action-btn {
  padding: 3px 6px;
  float: right;
  background-color: $frz-blue-color;
  border: 3px solid $frz-blue-color;
  color: $frz-white-color;
  @include button-bg($frz-blue-color, $frz-blue-over-color);
  @media screen and (max-width: 769px) {
    margin: 5px 5px 5px 0;
  }
}

.btn-social {
  border: none;
}

.fr-btn-assign {
  margin-bottom: 10px;
  @include button-bg($frz-green-color, $frz-green-over-color);
  &:disabled, &:disabled:hover {
    background-color: $frz-semi-light-gray-color;
    border: 1px solid $frz-semi-light-gray-color;
    color: $gray20-color;
    outline: none !important;
  }
}

.btn-success {
  color: $frz-white-color;
  background-color: $frz-green-color;
  border-color: $frz-green-color;
  @include button-bg($frz-green-color, $frz-green-over-color);
}

.btn-danger {
  color: $frz-white-color;
  background-color: $frz-red-color;
  border-color: $frz-red-color;
  @include button-bg($frz-red-color, $frz-red-over-color);
}

.btn-reset {
  position: absolute;
  right: 15px;
  top: 0;
  border-radius: 0 3px 3px 0;
  padding: 4px 6px;
  z-index: 100;
  @media screen and (max-width: 769px) {
    padding: 7px 8px;
    .glyphicon-remove {
      display: inline-block;
    }
  }
  &.address {
    padding: 5px 6px 1px;
    @media screen and (max-width: 769px) {
      padding: 8px 6px 4px;
    }
  }
}

.fr-btn-double-photo {
  &-before {
    display: block;
    height: 76px;
    width: 76px;
    cursor: pointer;
    background: $double-photo-before center no-repeat;
    background-size: 76px 76px;

  }
  &-after {
    display: block;
    height: 76px;
    width: 76px;
    cursor: pointer;
    background: $double-photo-after center no-repeat;
    background-size: 76px 76px;
  }
}

.fr-gallery-btn-add {
  padding: 4px 40px;
  margin-bottom: 10px;
  border: 3px solid $frz-green-color;
  border-radius: 4px;
  color: $frz-white-color;
  background-color: $frz-green-color;
  @include button-bg($frz-green-color, $frz-green-over-color);
  text-decoration: none;
  @media screen and (max-width: 769px) {
    padding-right: 16px;
    padding-left: 16px;
  }
  @media screen and (max-width: 667px) {
    padding-right: 6px;
    padding-left: 6px;
  }
  &.fr-mr {
    @media screen and (max-width: 480px) {
      margin-right: 0;
    }
  }
}

.fr-btn--inverse {
  background-color: $frz-blue-color;
  color: $frz-white-color;
  padding: 3px 10px 1px 10px;
  font-size: 18px;
  text-transform: uppercase;
  outline: none;
  &:hover, &:active, &:focus {
    outline: none;
    background-color: $frz-blue-over-color; //$frz-blue-color;
    color: $frz-white-color;
  }
  &:active:focus {
    outline: none;
  }
}

.fr-btn--clear {
  background-color: $frz-white-color;
  border: 2px solid $frz-blue-color;
  color: $frz-blue-color;
  padding: 5px 12px;
  &:hover, &:active, &:focus {
    border: 2px solid $frz-blue-over-color;
    color: $frz-blue-over-color;
  }
}