@import "../vars/colors";

.loader-wrapper {
  display: block;
  height: 45px;
  margin-top: 30px;
}

.loader {
  background: white;
  position: absolute;
  left: 50%;
  margin-left: -70px;
  width: 140px;
  border: 7px solid $frz-light-gray-color;
  border-radius: 11px;
  padding: 4px;
  zoom: 1;
  animation: loader-border 2.3s ease-in infinite;
  -o-animation: loader-border 2.3s ease-in infinite;
  -ms-animation: loader-border 2.3s ease-in infinite;
  -webkit-animation: loader-border 2.3s ease-in infinite;
  -moz-animation: loader-border 2.3s ease-in infinite;
}

.loader-span {
  display: block;
  position: relative;
  width: 0;
  height: 14px;
  background: $frz-blue-color;
  animation: loader-slide 2.3s ease-in infinite;
  -o-animation: loader-slide 2.3s ease-in infinite;
  -ms-animation: loader-slide 2.3s ease-in infinite;
  -webkit-animation: loader-slide 2.3s ease-in infinite;
  -moz-animation: loader-slide 2.3s ease-in infinite;
}

.loader:after {
  display: block;
  position: absolute;
  top: 4px;
  left: 66%;
  height: 14px;
  width: 4px;
  background: white;
  content: '';
  clear: both;
  z-index: 10;
}

.loader:before {
  position: absolute;
  display: block;
  width: 4px;
  left: 33%;
  height: 14px;
  background: white;
  z-index: 10;
  content: '';
}

@keyframes loader-slide {
  0% {
    width: 0;
    margin-left: 0;
    opacity: 0;
  }
  0% {
    width: 0;
    margin-left: 0;
    opacity: 1;
  }
  50% {
    width: 0;
    margin-left: 0;
  }
  75% {
    width: 100%;
    margin-left: 0;
  }
  90% {
    width: 0;
    margin-left: 100%;
    opacity: 1;
  }
  100% {
    width: 0;
    margin-left: 100%;
    opacity: 0;
  }
}

.loader-msg {
  &-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: nowrap;
    margin: 0 -15px 0 -15px;
    align-items: center;
    min-height: 260px;
    @media screen and (max-width: 769px) {
      min-height: 180px;
    }
    &--full-height {
      min-height: 490px;
      @media screen and (max-width: 769px) {
        min-height: 200px;
      }
    }
    &--item {
      flex: 1 1 auto;
      margin: 10px 15px 10px 15px;
      align-self: center;
      text-align: center;
      font-size: 16px;
    }
  }
  &-block {
    display: block;
    padding-bottom: 50px;
    height: auto;
  }
}