@import "./vars/colors";

.fr-artist-promo-header {
  position: static;
  top: 0;
  left: 0;
  width: 100%;
  margin-bottom: 0;
  color: $frz-white-color;
  z-index: 105;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.fr-artist-promo-header--fixed {
  position: fixed;
  top: -2px;
  margin-bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  color: $frz-black-color;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 999;
  padding-top: 0;
}

.fr-promo--book {
  margin-top: 17px;
  float: right;
  padding: 6px 50px;
  font-size: 18px;
  font-weight: 200;
  @media screen and (max-width: 769px) {
    margin: 5px 0 5px 5px;
    float: right;
    font-size: 14px;
    padding: 6px 12px;
  }
}

.fr-promo-image {
  &--cropper {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 72px;
    height: 72px;
    overflow: hidden;
    border-radius: 50%;
    .avatar {
      position: absolute;
      top: -12px;
      width: 100%;
    }
  }
  &--glyphicon {
    display: inline-block;
    margin-bottom: 7px;
    color: inherit;
    font-size: 45px;
    .glyphicon {
      margin-right: 10px;
    }
  }
}

.fr-artist-promo--error {
  margin-bottom: 6px;
  text-align: center;
  font-size: 48px;
  font-weight: 200;
  @media screen and (max-width: 641px) {
    margin: 0;
    padding: 2px 0 6px 0;
    font-size: 28px;
    color: inherit;
  }
}
