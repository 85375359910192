@import "./../vars/colors";
@import "./../vars/mixins";

html {
  .modal {
    z-index: 1090;
  }
  .modal-open {
    padding-right: 0 !important;
    @media screen and (max-width: 769px) {
      overflow: hidden;
      position: fixed;
    }
  }
  .top-align {
    margin-top: -32px;
  }
  .image-modal {
    max-height: 98vh;
    .modal-content {
      max-height: 98vh;
    }
    .img-responsive {
      max-height: calc(98vh - 90px);
      background: 50%/cover fixed no-repeat;
      background-image: attr(data-img);
    }
    .img-responsive--cover {
      max-height: calc(98vh - 90px);
      border-bottom: 4px solid $frz-black-color;
      background: 50%/cover fixed no-repeat;
      background-image: attr(data-img);
    }
    .img-slider {
      position: absolute;
      top: 15px;
      height: 5px;
      width: calc(100% - 1px);
      background-color: $frz-lightest-gray-color;
      display: block;
      opacity: 0;
    }
    .slider-down {
      -webkit-animation: slideDown 1s ease-out 1;
      -moz-animation: slideDown 1s ease-out 1;
      -ms-animation: slideDown 1s ease-out 1;
      -o-animation: slideDown 1s ease-out 1;
      animation: slideDown 1s ease-out 1;
    }
    .slider-up {
      -webkit-animation: slideUp 1s ease-out 1;
      -moz-animation: slideUp 1s ease-out 1;
      -ms-animation: slideUp 1s ease-out 1;
      -o-animation: slideUp 1s ease-out 1;
      animation: slideUp 1s ease-out 1;
    }
    .modal-body {
      .btn-default.fr-btn {
        margin-left: 15px;
      }
    }
    .fr-card-info {
      z-index: 8041;
    }
  }
  .change-email-modal {
    .modal-body {
      min-height: 110px;
    }
  }
  .change-email-modal, .change-password-modal {
    .fr-btn--clear.clear-button {
      margin: 15px 0 0;
    }
    .fr-btn {
      margin: 15px 0 0;
    }
  }
  .get-paid {
    .modal-body {
      padding-bottom: 0;
    }
  }
  .modal-content {
    .btn, .close {
      -webkit-transition: all 0.4s;
      -moz-transition: all 0.4s;
      -ms-transition: all 0.4s;
      -o-transition: all 0.4s;
      transition: all 0.4s;
    }
    .datepicker_wrapper {
      top: -2px
    }
    .datepicker_wrapper.static .ui-corner-all {
      top: 0;
    }
  }
  .forgot-modal {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  .modal-header {
    padding-bottom: 0;
    background: $frz-light-gray-color;
    //color: $semi-dark-color;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: none;
    h2 {
      margin-top: 0;
      font-weight: 200;
      color: $frz-white-color;
    }
    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .close {
      margin-top: 6px;
      &:hover, &:focus {
        outline: none;
      }
      span {
        font-size: 30px;
        color: $semi-dark-color;
        opacity: 1;
        //border: 2px solid $frz-black-color;
        //border-radius: 90px;
        display: block;
        width: 30px;
        height: 30px;
        line-height: 22px;
        text-align: center;
        padding-left: 15px;
      }
    }
    .legal-header {
      float: left;
    }
    .btn-proceed {
      float: right;
    }
    &.fr-unseen-apt-header {
      padding-bottom: 0;
      .table-header {
        @media screen and (max-width: 992px) {
          padding-left: 8px;
        }
      }
    }
    &.image-fullscreen-modal__header {
      display: none;
    }
    @media screen and (max-width: 768px) {
      padding: 15px 15px 0 15px;
      h2 {
        font-size: 32px;
      }
      .close {
        margin-top: 5px;
      }
    }
  }
  .book-modal {
    .modal-header {
      padding-bottom: 10px;
    }
  }
  .video-modal {
    width: 757px;
    max-width: 90%;
    .modal-header {
      padding-top: 0;
    }
    .close-layer {
      position: absolute;
      z-index: 99;
      top: 20px;
      right: 15px;
      margin-right: -30px;
      margin-top: -30px;
      background-color: transparent;
      width: 45px;
      height: 38px;
      cursor: pointer;
      display: inline-block;
    }
    .close {
      position: absolute;
      top: 30px;
      right: 30px;
      margin-right: -30px;
      margin-top: -30px;
      background-color: $frz-white-color;
      border-radius: 15px;
      width: 30px;
      height: 30px;
      opacity: 1;
      cursor: pointer;
      color: $frz-gray-color;
      font-size: 28px;
      font-weight: bold;
      display: inline-block;
      line-height: 0;
      span {
        margin-top: 4px;
        padding-left: 0;
      }
    }
  }
  .modal-body {
    h2 {
      margin-top: 0;
    }
    .form-control {
      margin-bottom: 15px;
      &.price-input {
        display: inline-block;
        width: 80%;
        margin-left: 5px;
      }
      &.tax-input {
        display: inline-block;
        width: 75%;
        margin: -3px 5px 0 0;
        height: 26px;
        padding: 6px;
      }
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      margin: 0;
    }
    input[type=number] {
      -moz-appearance: textfield;
    }
    .form-group {
      margin-bottom: 0;
    }
    .btn {
      margin-left: 15px;
      &.btn-danger {
        color: $frz-white-color;
        background-color: $frz-red-color;
        border-color: $frz-red-color;
        &:hover {
          background-color: $frz-red-over-color;
          border-color: $frz-red-over-color;
        }
      }
      &.btn-primary {
        &:hover {
          color: $frz-white-color;
          background-color: #286090;
          border-color: #286090;
        }
      }
      &.btn-add {
        color: $frz-white-color;
        background-color: $frz-green-color;
        border-color: $frz-green-color;
        &:hover {
          background-color: $frz-green-over-color;
          border-color: $frz-green-over-color;
        }
      }
    }
    .btn-toolbar > .btn, .btn-toolbar > .btn-group, .btn-toolbar > .input-group {
      margin-left: 0;
    }
    .tip-selector {
      padding-left: 5px;
    }
    .tip-selector-cnt {
      &.btn-select {
        color: $basic-gray-color;
        background: $frz-white-color;
        margin-bottom: 15px;
        &:active, &:focus {
          background-color: $frz-green-color !important;
        }
        &:hover {
          background-color: $frz-lightest-gray-color;
        }
      }
      &.tip-input {
        //width: 50%;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-bottom: 0;
        -moz-appearance: textfield;
        &:focus {
          background-color: $frz-green-color !important;
        }
        &:hover {
          background-color: $frz-lightest-gray-color;
        }
      }
      &.tip-sum-custom {
        display: inline-block;
        padding: 0 12px 0 12px;
        &:active, &:focus {
          background-color: $frz-green-color;
        }
      }
      .tip-selected {
        background-color: $frz-green-color;
      }
    }
    .tip-sum {
      font-size: 16px;

      &__no-tip {
        font-size: 16px;
      }
    }
    .tip-selector-middle {
      display: block;
      min-height: 65px;
      margin-bottom: 10px;
      font-size: 14px;
    }
    .price-span {
      text-align: right;
      padding-right: 0;
    }
    .hr-line {
      border-top: 1px solid $frz-semi-light-gray-color;
      margin: 0 -15px 0 -15px;
      padding-top: 5px;
    }
    .charges-panel {
      padding: 0;
      .title {
        font-size: 16px;
        font-weight: 500;
        color: $gray20-color;
      }
    }
    .bonus-panel {
      padding: 5px 0 5px 0;
      font-weight: 500;
      color: $gray20-color;
      background-color: $frz-lightest-gray-color;
      .title {
        font-weight: bold;
      }
    }
    .panel {
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    .panel-group {
      margin-bottom: 5px;
    }
    .panel-title {
      font-weight: bold;
      cursor: pointer;
    }
    .panel-title a {
      display: block;
      padding: 10px 15px;
      margin: -10px -15px;
      text-decoration: none;
      &:after {
        font-family: $font-glyphicon;
        content: "\E260";
        float: right;
      }
      &.collapsed:after {
        font-family: $font-glyphicon;
        content: "\E259";
        float: right;
      }
    }
    .charges-services {
      padding-bottom: 5px;
    }
    .span-sum {
      font-size: 14px;
    }
    .cnt-tip-title {
      position: absolute;
      top: 75px;
      left: 5px;
      font-size: 18px;
    }
    h2 {
      display: flex;
    }
    .checkbox-inline {
      font-size: 14px;
    }
    .forgot-link {
      margin-top: 4px;
      float: right;
    }
    .error-message {
      color: $frz-red-color; // originally $frz-red-over-color;
      margin: 10px 0;
      font-weight: bold;
    }
    .btn.btn-default {
      margin-left: 0;
    }
    .paid-services {
      margin-bottom: 0;
      > .row {
        margin-bottom: 5px;
      }
    }
    .services-title {
      margin: 15px 0 15px 0;
      font-weight: 500;
      font-size: 24px;
      > .row {
        margin-bottom: 5px;
      }
    }
    .paid-label {
      font-weight: normal;
      &--title {
        font-weight: 500;
      }
    }
    .services-artist {
      font-size: 12px;
    }
    .service-total {
      margin-left: -15px;
      margin-right: -15px;
      padding: 15px 15px 15px 15px;
      background-color: lightgray;
      color: black;
      > .form-group {
        margin-bottom: 10px;
      }
    }
    .info-message {
      margin: 10px 0;
      font-weight: bold;
    }
    .tip-message {
      padding: 15px 0 0;
      font-size: 12px;
    }
    .image-details {
      min-height: calc(70vh - 102px);
      img {
        margin: 0 auto;
      }
    }
    .image-nav {
      position: absolute;
      top: 30px;
      width: 40%;
      height: 93%;
      cursor: pointer;
      text-decoration: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      z-index: 8040;
      padding-top: 18%; //30vh;
      text-align: left;
      &:hover, &:active, &:focus {
        div {
          visibility: visible;
        }
      }
      &.image-prev {
        left: 5px;
        padding-left: 20px;
      }
      &.image-next {
        right: 0;
        text-align: right;
        padding-right: 20px;
      }
      div {
        position: absolute;
        top: 50%;
        margin-top: -1em;
        cursor: pointer;
        z-index: 8040;
        visibility: hidden;
      }
      .arrow-left {
        width: 0;
        height: 0;
        left: 0;
        border-style: solid;
        border-width: 25px 43.3px 25px 0;
        border-color: transparent #25303b transparent transparent;
      }
      .arrow-right {
        width: 0;
        height: 0;
        right: 0;
        border-style: solid;
        border-width: 25px 0 25px 43.3px;
        border-color: transparent transparent transparent #25303b;
      }
    }
    &.payment-approval {
      padding: 15px 15px 0 15px;
    }
    &.image-fullscreen-modal__body {
      padding: 15px 1px 15px 1px;
      max-height: 640px;
      .close-layer {
        position: absolute;
        top: 20px;
        right: 15px;
        margin-right: -30px;
        margin-top: -30px;
        background-color: transparent;
        width: 45px;
        height: 38px;
        cursor: pointer;
        display: inline-block;
      }
      .close {
        position: absolute;
        top: 34px;
        right: 36px;
        margin-right: -30px;
        margin-top: -30px;
        background-color: $frz-white-color;
        border-radius: 15px;
        width: 30px;
        height: 30px;
        opacity: 1;
        cursor: pointer;
        color: $frz-gray-color;
        font-size: 28px;
        font-weight: bold;
        display: inline-block;
        line-height: 0;
      }
    }
    &.password-reset-modal {
      &__body {
        padding-bottom: 0;
      }
      &__footer {

      }
    }
    @media screen and (max-width: 768px) {
      h2 {
        font-size: 24px;
      }
      h3 {
        font-size: 20px;
      }
      .form-control {
        font-size: 16px;
      }
      .fr-textarea-as-input {
        height: 80px;
      }
    }
    .alert-message {
      h3 {
        text-align: center;
        font-weight: normal;
      }
      &__link {
        text-align: center;
        margin-top: 30px;
      }
    }
    .approval-title {
      padding-right: 0;
      font-size: 18px;
      margin-top: 5px;
      &__bold {
        font-weight: bold;
      }
      &__error {
        margin-top: 15px;
        color: $frz-red-color;
        font-size: 18px;
      }
      &__success {
        color: $frz-blue-color;
        font-size: 18px;
      }
    }
    .promo-code {
      padding: 17px 15px 0 15px;
      background-color: lightgray;
      color: black;
      margin: 15px -15px;
      &__error {
        margin-top: -5px;
        margin-bottom: 8px;
        color: $frz-red-color;
        font-size: 14px;
      }
      .input-padding {
        padding-right: 12px;
      }
      &__input {
        margin: 0 0 10px 0 !important;
      }
      .form-control {
        margin-bottom: 0;
      }
    }
    &.client-modal__body {
      padding-bottom: 0;
    }
    .fr-unseen-apt__body {
      padding: 15px;
    }
    .fr-title {
      margin-top: 5px;
    }
    &.service-modal__body {
      .fr-service-title {
        padding-top: 5px;
        font-weight: bold;
      }
    }
    .fr-title__edit-price {
      margin-top: 7px;
    }
    .fr-unseen-apt-row {
      padding: 5px 0 5px;
      border-bottom: 1px dashed $frz-semi-light-gray-color;
    }
    &.fr-unseen-apt__body {
      padding-top: 0;
    }

    .img-handler {
      position: absolute;
      top: 8px;
      left: 0;
      z-index: 8051;
    }
    &.affiliate-body {
      padding-bottom: 0;
    }
    &.video-modal__body {
      padding: 15px 15px 10px;
      .iframe-container {
        position: relative;
        width: 100%;
        overflow: hidden;
        padding-top: 66.66%; /* 3:2 Aspect Ratio */

        .iframe-responsive {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          border: none;
        }
      }
    }
  }

  .modal-footer {
    .book-footer-wrapper {
      text-align: center;
    }

    .book-footer-total {
      float: left;
    }

    .book-footer-book {
      float: right;
    }

    .book-footer-violation {
      float: right;
      margin-top: 15px;
      padding-right: 25px;
    }

    .book-total {
      margin: 15px 0;
      padding: 7px 0;
    }
    .fr-book-appointment-notification {
      text-align: center;
    }
    .btn {
      margin: 15px 0;
      padding: 7px 50px;
      &.btn-book {
        color: $frz-white-color;
        background-color: $frz-blue-color;
        border-color: $frz-blue-color;
        &:hover {
          background-color: $frz-blue-over-color;
          border-color: $frz-blue-over-color;
        }
      }
      &.fr-unseen-apt-row {
        align-items: center;
        margin-bottom: 6px;
      }
      &.fr-unseen-apt-mt {
        margin-top: -5px;
        @media screen and (max-width: 769px) {
          margin-right: 5px;
        }
      }
    }
    &.image-details-footer {
      text-align: center;
      .btn {
        padding: 4px 12px;
        margin: 0 5px;
      }
    }
    &.card-add-footer {
      text-align: center;
      padding: 15px 6px;
      .btn {
        padding: 4px 30px;
        margin: 0 5px;
        &.btn-accept {
          margin-top: 15px;
        }
      }
      &.payment-approval {
        border-top: none;
        padding: 9px 6px;
      }
    }
    &.fr-unseen-apt__footer {
      padding: 0 15px 15px 0;
      border: none;
      .btn {
        margin: 0;
      }
    }
    &.password-reset-modal__footer {
      border-top: none;
      padding-top: 0;
      .btn {
        margin: 15px 0;
        padding: 6px 25px;
      }
      .fr-btn-reset {
        color: $frz-white-color;
        background-color: $frz-blue-color;
        border-color: $frz-blue-color;
        &:hover {
          background-color: $frz-blue-over-color;
          border-color: $frz-blue-over-color;
        }
      }
    }
    &.client-modal__footer {
      padding-top: 0;
      border-top: none;
      .fr-btn-client {
        &__save {
          padding: 7px 35px;
          float: right;
          background-color: $frz-blue-color;
          border: 3px solid $frz-blue-color;
          @include button-bg($frz-blue-color, $frz-blue-over-color);
          color: $frz-white-color;
          &:hover {
            background-color: $frz-blue-over-color;
            border: 3px solid $frz-blue-over-color;
            color: $frz-white-color;
          }
        }
        &__edit {
          padding: 7px 35px;
          float: right;
          background-color: $frz-green-color;
          border: 3px solid $frz-green-color;
          @include button-bg($frz-green-color, $frz-green-over-color);
          color: $frz-white-color;
          &:hover {
            background-color: $frz-green-over-color;
            border: 3px solid $frz-green-over-color;
            color: $frz-white-color;
          }
        }
        &__remove {
          padding: 7px 35px;
          float: left;
          background-color: $frz-red-color;
          border: 3px solid $frz-red-color;
          @include button-bg($frz-red-color, $frz-red-over-color);
          color: $frz-white-color;
          &:hover {
            background-color: $frz-red-over-color;
            border: 3px solid $frz-red-over-color;
            color: $frz-white-color;
          }
        }
      }
      .fr-apt-modal__footer {
        text-align: center;
        &.fr-btn__center {
          padding: 7px 35px;
          background-color: $frz-blue-color;
          border: 3px solid $frz-blue-color;
          @include button-bg($frz-blue-color, $frz-blue-over-color);
          color: $frz-white-color;
          &:hover {
            background-color: $frz-blue-over-color;
            border: 3px solid $frz-blue-over-color;
            color: $frz-white-color;
          }
        }
      }
    }
    &.client-comment-modal__footer {
      .fr-btn {
        padding: 7px 35px;
      }
      .btn {
        &.pull-left {
          margin-left: 0;
        }
      }
    }
    .fr-affiliate-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 1;
      text-align: center;
      margin-bottom: 5px;
    }
    [placeholder]:focus::-webkit-input-placeholder {
      transition: text-indent 0.5s 0.5s ease;
      text-indent: -100%;
    }
    [placeholder]:focus::-moz-placeholder {
      transition: text-indent 0.5s 0.5s ease;
      text-indent: -100%;
    }
    [placeholder]:focus:-ms-input-placeholder {
      transition: text-indent 0.5s 0.5s ease;
      text-indent: -100%;
    }
    &.affiliate-footer {
      padding-top: 0;
      border-top: none;
    }
  }

  .alert-modal {
    top: 15%;
  }

}

@media (min-width: 474px) {
  .image-modal {
    width: 460px;
    margin: 30px auto;
  }

}

.alertify-logs {
  z-index: 1999;
}

.alertify {
  z-index: 9999;
  .dialog {
    width: 338px;
    @media screen and (max-width: 769px) {
      padding: 0 5%;
      width: auto;
    }
  }
  .dialog nav {
    text-align: center;
    margin-top: 20px;
  }
  .dialog .msg {
    text-align: center;
  }
  .title {
    margin-top: -15px;
    font-size: 24px;
    font-weight: bold;
  }
  .title__middle {
    margin-top: -15px;
    font-size: 22px;
    font-weight: bold;
  }
  .title__p {
    font-size: 22px;
  }
  .glyphicon-map-marker {
    text-align: center;
    font-size: 60px;
    color: $frz-green-color;
  }
  .cancel {
    background-color: $frz-red-color !important;
    color: $frz-white-color !important;
    border-radius: 4px !important;
    &:hover {
      background-color: $frz-red-over-color !important;
      border-color: $frz-red-over-color;
    }
  }
  .ok {
    background-color: $frz-green-color !important;
    border: 3px solid $frz-green-color;
    color: $frz-white-color !important;
    border-radius: 4px !important;
    &:hover {
      background-color: $frz-green-over-color !important;
      border-color: $frz-green-over-color;
    }
  }
}

