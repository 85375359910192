@import "./../vars/colors";
.pagination {
  text-align: center;
}
.pagination span a {
  display: inline-block;
  padding: 4px 10px;
  margin: 1px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 3px solid $frz-blue-color;
  border-radius: 50px;
  color: $frz-white-color;
  background-color: $frz-blue-color;
}
.pagination span a:hover {
  color: #616161;
  background-color: $frz-white-color;
  text-decoration: none;
}
.pagination span a:active {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.pagination span.current {
  display: inline-block;
  padding: 4px 10px;
  margin: 1px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 2px solid $blue-emphasis-color;
  border-radius: 50px;
  color: $blue-emphasis-color;
  background: $frz-white-color none;
}
