.recommendation {
  margin: 50px 15px 15px;
  h1 {
    text-align: center;
    font-size: 28px;
    font-weight: 200;
  }

  &__msg {
    margin: 50px 15px 15px;
   a {
      font-size: 16px;
    }
  }
}