/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
@import "colors";
@import "assets";
// ##MIXINS

@mixin bg-transition($sec:0.4s) {
  -webkit-transition: background $sec linear;
  -moz-transition: background $sec linear;
  -o-transition: background $sec linear;
  transition: background $sec linear;
}

@mixin sliding-icon($top,$left) {
  background: transparent $social-icons no-repeat $top $left;
  @include bg-transition;
  &:hover {
    background: transparent $social-icons no-repeat $top ($left - 40px);
  }
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin rounded($topleft:3px, $topright: $topleft, $bottomright: $topright, $bottomleft: $topleft) {
  -moz-border-radius: $topleft $topright $bottomright $bottomleft;
  -webkit-border-radius: $topleft $topright $bottomright $bottomleft;
  border-radius: $topleft $topright $bottomright $bottomleft;
  //to round one side (left or right) use 2 values > rounded(3px,0); < rounded right side
}

@mixin transition($elem, $sec) {
  -webkit-transition: $elem $sec;
  -moz-transition: $elem $sec;
  -o-transition: $elem $sec;
  transition: $elem $sec;
}

@mixin button-bg($bg,$over) {
  color: $frz-white-color;
  background-color: $bg;
  border: 3px solid $bg;
  @include bg-transition;
  &:hover, &:focus, &:active, &:active:focus {
    background-color: $over;
    border: 3px solid $over;
    color: $frz-white-color;
    outline: none !important;
    box-shadow: none;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInRight {
  0% {
    -webkit-transform: translateX(75%);
    transform: translateX(75%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeInDown {
  0% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideDown {
  0% {
    top: calc(100% - 15px);
    height: 0;
    opacity: 1;
  }

  100% {
    top: 15px;
    height: calc(100% - 15px);
    opacity: 0;
  }
}

@keyframes slideUp {
  0% {
    //top: 15px;
    height: 15px;
    opacity: 1;
  }

  100% {
    height: calc(100% - 15px);
    opacity: 0;
  }
}
