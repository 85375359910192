html {
  .toggle-calendar {
    &.active {
      .toggle-picker {
        max-height: 500px;
        padding: 30px 15px 60px;
        margin-top: 15px;
      }
    }
  }
  &.mobile, &.tablet {
    .form-control {
      height: 40px;
    }
    .glyphicon-remove {
      display: none;
    }
  }
  #tabs-with-dropdown {
    &.fixed {
      @media all and (max-width: 1170px) {
        width: 100%;
        left: 0;
        top: 0;
        .grid.row {
          margin: 0;
        }
      }

      @media all and (max-width: 768px) {
        top: 0;
      }
    }
  }
  @media all and (max-width: 767px) and (min-width: 450px){
    .attr_name {
      display: inline-block;
      margin-right: 15px;
      min-width: 50px;
    }
  }
  @media screen and (max-width: 767px) {
    .glyphicon-remove {
      display: none;
    }
    .datepicker_wrapper {
      &.toggle-picker {
        width: 320px;
        right: -6px;
        .range_wrapper {
          max-width: 60%;
        }
      }
    }
    .fr-header .fr-navbar {
      margin-bottom: 0;
    }
    .fr-header {
      padding-top: 0;
    }
    .fr-header-logo {
      height: 34px;
      width: 148px;
      -webkit-background-size:contain;
      background-size:contain;
      margin-top: 8px;
    }
    #tabs-with-dropdown.fixed ul.nav-tabs[role="tablist"] {
      margin: 0 -8px;
      padding: 0;
      width: calc(100% + 16px);
    }
    .tab-pane.active {
      overflow: visible;
      max-height: 80vh;
      margin-top: 2px;
    }
    .tab-pane {
      form {
        margin-bottom: 10px;
        padding: 0 15px;
      }
      > div {
        padding-top: 15px;
        input.dashboard-when {
          width: calc(100% - 30px);
          margin: 0 15px;
        }
      }
    }
    .nav-tabs {
      [role="presentation"] {
        > a {
          padding: 5px 30px 10px 45px;
        }
      }
    }
    [role="presentation"] {
      > a {
        padding-bottom: 10px;
      }
      .ui-datepicker-calendar {
        .ui-state-default {
          width: 30px;
          height: 30px;
          font-size: 14px;
          line-height: 25px;
          &.ui-state-highlight {
            line-height: 22px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .items-filter-title {
      font-size: 24px;
    }
  }
  @media (max-width: 500px) {
    .items-filter-title {
      font-size: 24px;
    }
  }
}
