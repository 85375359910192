@import "vars/_assets";

.fr-favorite {
  position: absolute;
  top: 7px;
  right: 7px;
  z-index: 10;
}

.fr-favorite-disabled {
  cursor: none;
  pointer-events: none;
}

.fr-favorite-large {
  top: 30px;
  right: 15px;
  z-index: 8050;
}

.fr-favorite button {
  display: block;
  height: 27px;
  width: 30px;
  border: none;
  outline: none;
}

.fr-favorite-marked {
  background: $fav-marked, center, no-repeat;
  background-size: 27px 30px;
  -webkit-background-size: 30px 27px;
  -moz-background-size: 30px 27px;
  -o-background-size: 30px 27px;
  padding: 0;
}

.fr-favorite-not-marked {
  background: $fav-not-marked, center, no-repeat;
  background-size: 30px 27px;
  -webkit-background-size: 30px 27px;
  -moz-background-size: 30px 27px;
  -o-background-size: 30px 27px;
  padding: 0;

}
