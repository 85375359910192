@import "./vars/colors";

.fr-clients__btn-add {
  vertical-align: text-bottom;
  margin-bottom: 0;
  margin-left: 15px;
  border: 2px solid $frz-lightest-gray-color ;
  &:hover, &:focus,&:active {
    border: 2px solid $frz-green-over-color;
  }
  @media only screen and (max-width: 769px) {
    float: right;
    //margin-top: 8px;
  }
}
.fr-clients-header {
  margin: 15px -15px 2px 0;
  background-color: $frz-light-gray-color;
  padding: 4px 2px;
  @media only screen and (max-width: 991px) {
    display: none;
  }
}
.fr-table-clients-content {
  background-color: $frz-white-color;
  line-height: 2;
  margin-left: 0;
}
.fr-table__border-bottom {
  border-bottom: 1px dashed $frz-light-gray-color;
}
.fr-table-clients__btn {
  margin: 5px 0 5px 0;
}
.fr-clients__filter {
  float: right;
  display: inline-block;
  padding: 5px 5px 0 5px;
  margin-top: 17px;
  opacity: 0.99;
  @media only screen and (max-width: 769px) {
    float: none !important;
    margin-top: 0;
  }
  span.glyphicon-search {
    margin-top: -3px;
    color: $frz-white-color;
  }
  .input-group-btn {
    z-index: 100;
    .fr-clients__reset {
      position: absolute;
      right: 37px;
      border-radius: 0 3px 3px 0 !important;
      padding: 3px 6px 2px 6px;
      z-index: 100;
      .glyphicon-remove {
        display: inline-block;
      }
      .glyphicon-remove {
        top: 2px;
      }
      @media only screen and (max-width: 769px) {
        padding: 3px 6px 2px;
      }
    }
  }
}

.fr-btn-search {
  margin-bottom: 10px;
  margin-left: 5px;
}