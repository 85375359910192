@import "../vars/colors";
@import "../vars/assets";

.fr-affiliate {
  position: relative;
  left: 0;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  width: 101vw;
}

.fr-affiliate-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-radius: 3px;
  padding-bottom: 5px;
  background: center;
  background-size: cover;
  &__item {
    flex: 1 1 calc(100% - 60px);
    margin: 0;
    box-sizing: border-box;
    padding: 5px 15px;
    font-size: 18px;
    align-self: center;
    color: $semi-dark-color;
    &-title {
      margin: 0 15px;
      font-size: 36px;
      font-weight: 200;
      color: $frz-semi-light-gray-color;
      @media screen and (max-width: 690px) {
        margin-top: 15px;
        font-size: 24px;
        font-weight: 200;
      }
    }
    &.shadow {
      background-color: $frz-black-color;
      color: $frz-white-color;
      align-self: center;
    }
    &.blue {
      background-color: $frz-blue-over-color;
      color: $frz-white-color;
      align-self: center;
      a {
        color: $frz-white-color;
      }
      .content-text {
        padding: 60px 0 80px;
        text-align: center;
        font-size: 32px;
        font-weight: 200;
        @media screen and (max-width: 690px) {
          padding: 30px 15px 40px;
        }
      }
    }
  }
  &__item--reasons {
    flex: 1 1 555px;
    margin: 0;
    box-sizing: border-box;
    min-width: 260px;
    padding-top: 15px;
    text-align: center;
    font-size: 18px;
    align-self: center;
    background-color: $frz-light-gray-color;
    &:nth-of-type(3) {
      padding-left: 75px;
    }
    &:nth-of-type(4) {
      padding-right: 75px;
    }
    @media screen and (max-width: 991px) {
      padding: 0 30px;
    }
  }
}

@media screen and (max-width: 991px) {
  .fr-affiliate-flex__item--reasons {
    &:nth-of-type(3) {
      padding: 0 30px;
    }
    &:nth-of-type(4) {
      padding: 0 30px;
    }
  }
}

.fr-item--shadow-title {
  margin: 15px;
  font-size: 56px;
  font-weight: 200;
  @media screen and (max-width: 690px) {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 32px;
    font-weight: 500;
  }
}

.mail-box {
  margin: 24px auto;
  width: 54%;
  border: 1px solid white;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 0 6px $frz-semi-light-gray-color;
  color: $semi-dark-color;
  @media screen and (max-width: 690px) {
    width: 90%;
  }
  b {
    font-size: 32px;
    font-weight: 200;
    color: $frz-black-color;
  }
  .signature {
    text-align: right;
    padding: 8px 15px 0;
  }
}

.content-box {
  margin: 0;
  padding: 15px;
  color: $basic-gray-color;
  text-align: center;
  @media screen and (max-width: 690px) {
    margin: 0 5px 0 5px;
    padding: 15px 10px;
  }
  .bold {
    font-weight: 500;
  }
  &--block {
    text-align: center;
    padding: 30px 0 70px;
  }
  &.left {
    width: 54%;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
    text-align: left;
    @media screen and (max-width: 690px) {
      width: 80%;
    }
  }
}

.reasons-box {
  max-width: 1070px;
  margin: 0 auto;
  padding: 0 5px;
  @media screen and (max-width: 690px) {
    margin: 15px 0 0 5px;
    padding: 0 0 15px 0;
  }
}

.reasons-list {
  text-align: left;
  list-style-type: none;
  &.list-group {
    margin-bottom: 0;
    padding-right: 15px;
  }
  &--center {
    text-align: center;
    @media screen and (max-width: 991px) {
      text-align: inherit;
      padding-left: 25px;
      padding-bottom: 15px;
    }
  }
}

.list-inline-block {
  display: inline-block;
  margin-left: -15px;
  b {
    font-weight: 200;
    font-size: 32px;
  }
  @media screen and (max-width: 690px) {
    margin-left: 10px;
    b {
      line-height: 1.1;
    }
  }
}

.list-group-badge {
  margin: 6px 5px 48px 0;
  font-size: 48px;
  background-color: $frz-blue-color;
  border: 1px solid $frz-white-color;
  @media screen and (max-width: 690px) {
    margin: 8px 5px 35px 0;
    font-size: 48px;
  }
  &.title {
    margin-bottom: 10px;
    font-size: 32px;
    @media screen and (max-width: 690px) {
      margin-bottom: 5px;
      font-size: 32px;
    }
  }
}

.avatar {
  float: left;
  width: 96px;
  height: 96px;
  overflow: hidden;
  margin: 0 15px 35px 0;
  border-radius: 50px;
}

.basic-form {
  margin-top: 25px;
  margin-bottom: 15px;
  &.fr-btn-more {
    margin-top: 25px;
    padding: 6px 45px;
    font-size: 36px;
  }
}

.affiliate-radio {
  display: block;
  margin-top: 0;
  margin-left: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 200;
  &.other {
    display: inline-block;
  }
}

.radio {
  &.affiliate {
    label {
      font-size: 16px;
      font-weight: 200;
    }
    input[type=radio] {
      margin-top: 4px;
      @media screen and (max-width: 690px) {
        margin-top: 2px;
      }
    }
    &:first-child {
      margin-top: 25px;
    }
  }
}

.affiliate-other {
  display: inline-block;
  width: 93%;
  @media screen and (max-width: 690px) {
    width: 92%
  }
  @media screen and (min-width: 1200px) {
    width: 92%
  }
}

