@import "vars/_assets";

.fr-certificate {
  position: absolute;
  top: 15px;
  left: 15px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  color: $frz-white-color;
  z-index: 99;
  a {
    display: inline-block;
    width: 100%;
    height: 100%;
    color: $frz-white-color;
  }
  &.stage-10 {
    background: url("img/airtouch-stage-10.svg"), center, no-repeat;
    background-size: 45px 45px;
  }
  &.stage-20 {
    background: url("img/airtouch-stage-20.svg"), center, no-repeat;
    background-size: 45px 45px;
  }
  &.stage-30 {
    background: url("img/airtouch-stage-30.svg");
    background-size: 45px 45px;
  }
  &.stage-40 {
    background: url("img/airtouch-stage-40.svg"), center, no-repeat;
    background-size: 45px 45px;
  }
  &.stage-99 {
    background: url("img/airtouch-stage-99.svg"), center, no-repeat;
    background-size: 45px 45px;
  }
}

.image-modal {
  .fr-certificate {
    top: 25px;
    z-index: 8050;
  }
}