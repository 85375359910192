@import './vars/colors';
@import './vars/mixins';

$mobile: "screen and (max-width: 992px)";

.pricing {
  &__banner {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    color: $frz-white-color;
    h1 {
      font-size: 32px;
      font-weight: 200;
      margin: 0;
      @media screen and (max-width: 1024px) {
        font-size: 40px;
      }
      @media screen and (max-width: 769px) {
        font-size: 24px;
      }
    }
  }
  &__title {
    margin-bottom: 15px;
    text-align: center;
    font-size: 50px;
    font-weight: 400;
    @media #{$mobile} {
      margin-top: 15px;
      font-size: 32px;
    }
  }
  &__period {
    width: 400px;
    margin: 15px auto;
    @media #{$mobile} {
      width: auto;
    }
  }
  &-plan {
    &-select {
      display: none;
      font-size: 18px;
      margin-top: 20px;
      padding: 0;
      background: inherit;
      position: relative;
      @media #{$mobile} {
        display: block;
        text-align: center;
      }
      &__item {
        display: none;
        cursor: pointer;
        &--selected {
          text-align: center;
          padding: 9px 15px 10px 15px;
          color: $frz-white-color;
          @media #{$mobile} {
            background-color: $frz-blue-color !important;
            border-color: $frz-blue-over-color;
            border-radius: 4px;
            @include bg-transition;
            &:hover, &:focus {
              background-color: $frz-blue-over-color !important;
              border-color: $frz-blue-over-color;
              color: $frz-white-color;
            }
          }
          @media screen and (max-width: 769px) {
            font-size: 16px;
          }
        }
        &--opened {
          display: block;
          background-color: $frz-light-gray-color;
          border-color: $frz-light-gray-color;
          border-radius: 4px;
          padding: 9px 15px 10px 15px;
          @include bg-transition;
          &:hover, &:focus {
            background-color: $frz-blue-color;
            border-color: $frz-blue-color;
            color: $frz-white-color;
          }
        }
      }
      &__trigger {
        width: 40px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        &:before {
          content: '';
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid $frz-white-color;
          top: 16px;
          left: 10px;
          position: absolute;
        }
      }
    }
    &-item {
      text-align: center;
      @media #{$mobile} {
        margin-top: 15px;
      }
      &--bottom {
        padding-top: 15px;
      }
      &__name {
        //font-weight: bold;
        color: $semi-dark-color;
        border-bottom: 1px solid $frz-semi-light-gray-color;
        padding-bottom: 5px;
      }
      &__quality {
        color: $semi-dark-color;
        padding: 0;
        &--padding-top {
          padding-top: 15px;
        }
        &--free {
          color: $frz-semi-light-gray-color;
        }
        &--note {
          margin-top: 7px;
          color: $semi-dark-color;
          font-size: 11px;
        }
        &--features {
          padding: 10px 0;
        }
        &.pricing {
          margin-bottom: 35px;
        }
      }
      &__start {
        text-transform: uppercase;
        color: $frz-white-color;
        padding: 8px 50px;
        border-radius: 3px;
        @include button-bg($frz-green-color, $frz-green-over-color);
      }
    }
    &__info {
      text-align: center;
      background: $frz-blue-color;
      color: $frz-white-color;
      padding: 10px;
      margin-top: 15px;
    }
  }
  &--btn {
    font-size: 16px;
    outline: none;
    @media screen and (max-width: 480px) {
      font-size: 12px;
    }
    &.wide {
      padding: 6px 43px;
      @media screen and (max-width: 480px) {
        padding: 6px 36px;
      }
    }
  }
}
