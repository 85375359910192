.wrapper-3x4{
  width: 100%;
  margin: 0 auto;
 }

.mount-3x4{
  width: 100%;
  position: relative;
  padding-bottom: 133.3333%;
}

.image-preview{
  border: 2px solid rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  .button-wrapper {
    margin: 15px auto;
    text-align: center;
    .btn {
      margin:auto;
    }
  }
}

 .mount-3x4 > .dropzone,
 .mount-3x4> .image-preview{
   position:absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   height: 100%;
   width:100%;
 }

 .height-100{
   height: 100%;
 }

 .width-100{
   width:100%;
 }

 .image-container{
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 100%;
   width:100%;
   margin: auto;
 }

 .image-wrapper{
   position: relative;
   height: 100%;
   width:100%;
   display:inline-flex;
   align-items: center;
   justify-content: center;

 }

.image-wrapper > img{
  margin: auto;
}

 .button-wrapper{
   margin: auto;
 }
