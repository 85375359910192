/**
 * Created by Tatjana Jankova
 * Name: Tatjana Jankova
 * Company: U6 SIA
 * Date: 20.07.2017
 * Time: 12:00
 */
/* cyrillic-ext */

// ##IMAGES
$fp-logo: url("img/frizo-logo.svg");
$fp-inverse-logo: url("img/logo.svg");
$social-icons: url("img/social_big.png");
$fb-icon: url("img/facebook.svg");
$fb-inactive-icon: url("img/facebook-inactive.svg");
$instagram-icon: url("img/instagram.svg");
$instagram-inactive-icon: url("img/instagram-inactive.svg");
$pinterest-icon: url("img/pinterest.svg");
$pinterest-inactive-icon: url("img/pinterest-inactive.svg");
$youtube-icon: url("img/youtube.svg");
$youtube-inactive-icon: url("img/youtube-inactive.svg");
$appstore: url("img/appstore-badge.svg");
$google-play-icon: url("img/google-play-badge.svg");

$flag-en: url("img/flag-us.svg");
$flag-es: url("img/flag-es.svg");

$scrollLeft: url("img/scroll_gallery_left.svg");
$scrollRight: url("img/scroll_gallery_right.svg");

$fav-over: url("img/favorite-mouse-over.svg");
$fav-marked: url("img/favorite-marked.svg");
$fav-not-marked: url("img/favorite-not-marked.svg");

$card-green: url("img/card-green.svg");
$card-red: url("img/card-red.svg");
$card-gray: url("img/card-gray.svg");
$bank-green: url("img/bank-green.svg");
$bank-gray: url("img/bank-gray.svg");

$badge-red: url("img/badge-red.svg");
$badge-orange: url("img/badge-orange.svg");

$badge-message-history: url("img/badge-message-history.svg");
$badge-message-sent: url("img/badge-message-sent.svg");
$badge-message-confirmed: url("img/badge-message-confirmed.svg");

$double-photo: url("img/double-photo.svg");
$double-photo-before: url("img/before.svg");
$double-photo-after: url("img/after.svg");

$spinner-gif: url("img/spinner.gif");

$pro-landing-1: url("img/pro-landing-1.jpg");
$pro-product-background:url("img/frizo-pro-products.jpg");

$mobile: "screen and (max-width: 768px)";
$desktop: "screen and (min-width: 768px)";

// ##FONTS
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url(https://fonts.gstatic.com/s/roboto/v15/0eC6fl06luXEYWpBSJvXCBJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url(https://fonts.gstatic.com/s/roboto/v15/Fl4y0QdOxyyTHEGMXX8kcRJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('fonts/roboto-condensed-light/RobotoCondensed-Light.woff') format('woff'),
  url('fonts/roboto-condensed-light/RobotoCondensed-Light.ttf') format('truetype'),
  url('fonts/roboto-condensed-light/RobotoCondensed-Light.svg#RobotoCondensed-Light') format('svg');
  src: url('fonts/roboto-condensed-light/roboto-condensed.light.eot');
  src: url('fonts/roboto-condensed-light/roboto-condensed.light.woff2') format('woff2'),
  url('fonts/roboto-condensed-light/roboto-condensed.light.eot?#iefix') format('embedded-opentype');

}

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('fonts/glyphicons-halflings-regular.woff') format('woff'),
  url('fonts/glyphicons-halflings-regular.ttf') format('truetype'),
  url('fonts/glyphicons-halflings-regular.svg#Glyphicons Halflings') format('svg');
  src: url('fonts/glyphicons-halflings-regular.eot');
  src: url('fonts/glyphicons-halflings-regular.woff2') format('woff2'),
  url('fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

$font-main: 'Roboto', Helvetica, sans-serif;
$font-tabs: 'Roboto Condensed', Cambria, Helvetica, sans-serif;
$main-font-size: 16px;
$font-glyphicon: 'Glyphicons Halflings';
