@import "../vars/_colors";
@import "../vars/_mixins";

.nav-tabs-dropdown {
  display: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-family: $font-tabs;
  font-size: 18px;
  font-weight: bold;
  padding-right: 35px;
  padding-left: 35px;

  &:before {
    content: "\e252";
    font-family: $font-glyphicon;
    position: absolute;
    right: 30px;
  }
}

.nav-tabs-dropdown.opened {
  &:before {
    content: "\e253";
  }
}

.glyphicon {
  &.glyphicon-triangle-bottom {
    right: 30px;
    position: absolute;
  }
}

.nav-tabs-horizontal {
  border-bottom: 1px solid $frz-semi-light-gray-color;
  padding-bottom: 0;
  @media only screen and (max-width: 768px) {
    padding-bottom: 0;
  }
}

.nav-tabs-horizontal > li > a {
  padding-top: 12px;
  padding-bottom: 10px;
  border: none;
  &:hover, &:focus {
    padding-bottom: 10px;
    outline: none;
    border-bottom: 4px solid $frz-blue-color;
    background-color: $frz-white-color;
    @media screen and (max-width: 769px) {
      background-color: $frz-blue-color;
      border: none;
    }
  }
}

.nav-tabs-horizontal > li.active > a,
.nav-tabs-horizontal > li.active > a:hover,
.nav-tabs-horizontal > li.active > a:focus {
  background-color: $frz-white-color;
  color: $frz-blue-color;
  border: none;
  border-bottom: 4px solid $frz-blue-color;
  padding-bottom: 10px;
  padding-top: 12px;
  > .fr-tab-complete {
    background-color: $frz-green-over-color;
  }
  > .fr-tab-fully-incomplete {
    background-color: $frz-red-over-color;
  }
  > .fr-tab-incomplete {
    background-color: $frz-orange-over-color;
  }
}

@media screen and (min-width: 769px) {

  #nav-tabs-wrapper {
    display: block !important;
    margin-top: 0;
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media screen and (max-width: 768px) {
  .fr-navbar-tabs {
    margin: 0 0 15px 0;
    border-bottom-width: 1px;
    border-color: $frz-semi-light-gray-color;
  }

  .nav-tabs-dropdown {
    display: block;
    width: 100%;
    border-radius: 0;
    font-weight: normal;
    font-family: $font-main;
    font-size: 16px;
    padding-left: 45px;
  }

  #nav-tabs-wrapper {
    //display: none;
    background-color: $frz-white-color;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    text-align: center;
  }

  .nav-tabs-horizontal {
    min-height: 20px;
    margin-bottom: 20px;
    background-color: $frz-light-gray-color;
    border-color: $frz-light-gray-color;
    border-radius: 4px;
    @include bg-transition;
    &:hover, &:focus, &:active {
      background-color: $frz-light-gray-color;
      color: $frz-white-color !important;
    }
  }

  .nav-tabs-horizontal > li {
    float: none;
  }
  .nav-tabs-horizontal > li,
  .nav-tabs-horizontal > li > a {
    background: $frz-light-gray-color;
    width: 100%;
    border-radius: 4px;
    border-color: $frz-light-gray-color;
    @include bg-transition;
    &:hover, &:focus, &:focus {
      color: $frz-white-color;
    }
  }
  .nav-tabs-horizontal > li.active > a,
  .nav-tabs-horizontal > li.active > a:hover,
  .nav-tabs-horizontal > li.active > a:focus {
    display: none;
  }
  .fr-mb__login {
    margin-bottom: 15px !important;
  }
}
